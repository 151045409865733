<template>
	<div class="addOrder">
		<div class="mainContent">
			<textarea
				placeholder="添加国内物流单号，多个单号用'/'隔开"
				v-model="orderSn"
				:class="errType == 1 ? 'errStyle' : ''"
			></textarea>

			<input
				type="text"
				placeholder="请输入联系人电话"
				v-model="contactPhone"
				:class="errType == 2 ? 'errStyle' : ''"
				class="contactPhone"
			/>
			<p class="oldPhone">
				<span
					@click="chooseOldPhone"
					v-if="oldPhone != '' && oldPhone != undefined"
					>{{ oldPhone }}</span
				>
			</p>
			<div class="btn">
				<span @click="saveInfo">保存</span>
			</div>
			<div class="attention">
				<p>注意事项：</p>
				<p>
					1. 可填写淘宝/天猫/京东等各大电商平台快递单号，
					也可以填写代购或国内亲戚朋友寄到仓库的单号。
				</p>
				<p>
					2. 单次最多可添加15个快递单号， 不同单号之间务必用 /
					隔开哦。
				</p>
				<p>
					3. 手机号码为包裹出现异常时集运仓联系您的重要方式，必填哦。
				</p>
			</div>
		</div>
		<messageDialog
			:content="messageText"
			v-show="messageDialog"
		></messageDialog>
	</div>
</template>

<script>
	import FootBar from "../components/footBar";
	import messageDialog from "@/components/messageDialog.vue";
	export default {
		name: "addOrder",
		data() {
			return {
				messageDialog: false,
				messageText: "",
				orderSn: "",
				footShow: true,
				contactPhone: "",
				errType: 0, //1:物流单号有误 2:联系人手机号有误
				expressName: "",
				expressCode: "",
				expressList: "",
				listDown: false,
				oldPhone: "",
				btnAble: true,
			};
		},
		components: {
			messageDialog,
		},
		mounted() {
			let that = this;
			let oriWinHeight = window.innerHeight;
			window.onresize = function () {
				that.isKeybordAvail = true;
				let newHeight = window.innerHeight;
				// 阀值大于140判断为键盘收起
				if (newHeight - oriWinHeight > 140) {
					that.footShow = true;
				} else {
					that.footShow = false;
				}
				oriWinHeight = newHeight;
			};
		},
		created() {
			this.oldPhone = this.getCookie("contactPhone");
			this.getExpressList();
		},
		methods: {
			getCookie(c_name) {
				//判断document.cookie对象里面是否存有cookie
				if (document.cookie.length > 0) {
					var c_start = document.cookie.indexOf(c_name + "=");
					//如果document.cookie对象里面有cookie则查找是否有指定的cookie，如果有则返回指定的cookie值，如果没有则返回空字符串
					if (c_start != -1) {
						c_start = c_start + c_name.length + 1;
						var c_end = document.cookie.indexOf(";", c_start);
						if (c_end == -1) c_end = document.cookie.length;
						return unescape(document.cookie.substring(c_start, c_end));
					}
				}
				return "";
			},
			chooseOldPhone() {
				this.contactPhone = this.oldPhone;
			},
			getExpressList() {
				var that = this;
				var url = "/api/h5/expressList";
				var data = {};
				this.$post(url, data).then((res) => {
					if (res.resultCode == 10000) {
						this.expressList = res.result.expressList;
						this.expressName = this.expressList[0].expressName;
						this.expressCode = this.expressList[0].expressCode;
					}
				});
			},
			openList() {
				if (this.listDown == true) {
					this.listDown = false;
				} else {
					this.listDown = true;
				}
			},
			expressChange(item) {
				this.expressCode = item.expressCode;
				this.expressName = item.expressName;
				this.listDown = false;
			},
			saveInfo() {
				var that = this;
				if (this.orderSn == "") {
					this.messageText = "请添写物流单号";
					this.errType = 1;
					this.messageDialog = true;
					setTimeout(function () {
						that.messageDialog = false;
						that.errType = 0;
					}, 1500);
					return false;
				}
				var snArr = this.orderSn.split("/");
				if (snArr.length > 15) {
					this.messageText = "物流单号不能超过15个";
					this.errType = 1;
					this.messageDialog = true;
					setTimeout(function () {
						that.messageDialog = false;
						that.errType = 0;
					}, 1500);
					return false;
				}
				if (this.contactPhone == "") {
					this.messageText = "联系人手机号不能为空";
					this.errType = 2;
					this.messageDialog = true;
					setTimeout(function () {
						that.messageDialog = false;
						that.errType = 0;
					}, 1500);
					return false;
				}
				if (this.btnAble == false) {
					return false;
				}
				this.orderSn = this.orderSn.replace(/ /g, "");
				this.btnAble = false;
				document.cookie = "contactPhone=" + this.contactPhone;
				var url = "/api/h5/addInternalOrder";
				var data = {
					logisticsNumbers: this.orderSn,
					contactPhone: this.contactPhone,
					expressCode: this.expressCode,
				};
				this.$post(url, data).then((res) => {
					this.btnAble = true;
					if (res.resultCode == 10000) {
						this.$router.push({
							name: "storage",
						});
					}
					if (res.resultCode == 30003) {
						this.messageText = res.reason;
						this.messageDialog = true;
						var that = this;
						setTimeout(function () {
							that.messageDialog = false;
						}, 1500);
					}
				});
			},
		},
	};
</script>

<style lang="scss" scoped>
	.addOrder {
		width: 100%;
		height: 100%;
		background: #fff;
		padding: 2rem 6%;
		display: flex;
		flex-direction: column;
		.footbar {
			position: fixed;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: 100;
		}
		textarea {
			width: 100%;
			height: 20rem;
			outline: none;
			padding: 1rem 2rem;
			border: 2px solid #dcdcdc;
			font-size: 2.5rem;
			color: #999999;
			border-radius: 5px;
		}
		.contactPhone {
			width: 100%;
			height: 5rem;
			outline: none;
			padding: 2rem;
			border: 2px solid #dcdcdc;
			font-size: 2.5rem;
			color: #999999;
			margin-top: 2rem;
			border-radius: 5px;
			height: 90px;
		}
		.oldPhone {
			font-size: 2.5rem;
			text-align: left;
			padding-top: 2rem;
			span {
				padding: 1.5rem 3rem;
				display: inline-block;
				border: 2px solid #dcdcdc;
				border-radius: 5px;
				color: #999999;
			}
		}
		.selectView {
			padding-top: 15px;
			position: relative;
			.valueView {
				border: 2px solid #dcdcdc;
				border-radius: 10px;
				line-height: 90px;
				height: 90px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 0 30px;
				span {
					color: #999;
					font-size: 2.5rem;
				}
			}
			.downList {
				position: absolute;
				left: 0;
				top: 105px;
				width: 100%;
				min-height: 200px;
				border: 2px solid #dcdcdc;
				border-radius: 10px;
				background: #fff;
				padding: 20px 0;
				max-height: 400px;
				overflow: auto;
				li {
					padding: 20px 30px;
					color: #999;
					font-size: 2.5rem;
					text-align: left;
				}
				li:hover {
					background: #f9f9f9;
				}
			}
		}
		.errStyle {
			border: 1px solid red !important;
		}
		.btn {
			display: -webkit-box;
			display: -moz-box;
			display: -ms-flexbox;
			display: -webkit-flex;
			display: flex;
			-webkit-justify-content: space-between;
			justify-content: space-between;
			-moz-box-pack: space-between;
			-webkit--moz-box-pack: space-between;
			box-pack: space-between;
			align-items: center;
			-webkit-align-items: center;
			box-align: center;
			-moz-box-align: center;
			-webkit-box-align: center;
			margin-top: 2rem;
			span:nth-child(1) {
				display: inline-block;
				width: 100%;
				background: #ef3c4d;
				color: #fff;
				padding: 10px 0;
				font-size: 3rem;
				border-radius: 10px;
			}
			span:nth-child(2) {
				display: inline-block;
				width: 48%;
				background: #0080cb;
				color: #fff;
				padding: 2rem 0;
				font-size: 3rem;
				border-radius: 1rem;
			}
		}
		.attention {
			padding-top: 2rem;
			text-align: left;
			line-height: 4rem;
			p {
				color: #999999;
				font-size: 2.1rem;
			}
		}
	}
</style>
