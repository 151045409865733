<template>
    <div class="packageCN">
        <emptyInfo v-show="empty"></emptyInfo>
        <div v-show="!empty">
            <p class="title" v-show="orderList.length > 0">异常包裹</p>
            <div class="list">
                <div class="item" v-for="(item,index) in orderList" :key="index">
                    <div class="head">
                        <p>
                            <span>订单号：</span>
                            <span class="orderNo" @click="seeChildOrder(item.orderSn)">{{item.orderSn}}</span>
                            <img src="https://jcex-file-service.oss-cn-hangzhou.aliyuncs.com/jc-wms/copy.png" @click="copyContent(item.orderSn)" class="copyImg">
                       </p>
                        <span style="float:right;" @click="getEventShowOrHidden($event)">隐藏</span>
                    </div>
                    <div>
                        <div class="head">
                            <p class="logisticsNumber">
                                <span>国际快递单号：{{item.wmsLogisticsNumbers == null?'暂无' : item.wmsLogisticsNumbers != null && item.wmsLogisticsNumbers.length > 20?item.wmsLogisticsNumbers.substring(0,21) + '...' : item.wmsLogisticsNumbers}}</span>
                                <img src="https://jcex-file-service.oss-cn-hangzhou.aliyuncs.com/jc-wms/copy.png" @click="copyContent(item.wmsLogisticsNumbers)" class="copyImg" v-show="item.wmsLogisticsNumbers != null">
                            </p>
                        </div>
                        <div class="head">
                            <p class="logisticsNumber">
                                <span>国内快递单号：{{item.logisticsNumbers == null?'暂无' : item.logisticsNumbers != null && item.logisticsNumbers.length > 20?item.logisticsNumbers.substring(0,21) + '...' : item.logisticsNumbers}}</span>
                                <img src="https://jcex-file-service.oss-cn-hangzhou.aliyuncs.com/jc-wms/copy.png" @click="copyContent(item.logisticsNumbers)" class="copyImg" v-show="item.logisticsNumbers != null">
                            </p>
                            <p>{{item.showOrderStatus}}</p>
                        </div>
                        <div class="line"></div>
                        <div class="content">
                            <p class="package">
                                <img src="https://jcex-file-service.oss-cn-hangzhou.aliyuncs.com/jc-wms/pic.jpg" v-show="item.parcelImg == null">
                                <img :src="item.parcelImg" v-show="item.parcelImg != null">
                            </p>
                            <!-- <p class="btn" @click="seeProgress(item.logisticsNumbers)">查看进度</p> -->
                        </div>
                        <div class="line"></div>
                        <div class="time">{{item.createTime}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="childOrder" v-show="childOrder" @click="closeModal">
            <div class="modalView">
                <p class="title">主订单号：{{orderSn}}</p>
                <ul>
                    <li v-for="(item,index) in childOrderList" :key="index">
                        <p>子订单号：{{item.orderSn}}</p>
                        <p>国内快递单号：{{item.logisticsNumbers}}</p>
                    </li>
                </ul>
            </div>
        </div>
        <messageDialog content = '复制成功！' v-show="promit"></messageDialog>
    </div>
</template>

<script>
import emptyInfo from '@/components/emptyInfo.vue';
import {handleClipboard} from '@/assets/js/clipboard';
import messageDialog from '@/components/messageDialog.vue';
export default {
    name: "packageCN",
    data(){
        return {
            empty:false,
            isEnd:true,
            childOrder:false,
            orderList:[],
            childOrderList:[],
            orderSn:'',
            pageNo:1,
            pageSize:900,
            totalPage:0,
            promit:false
        }
    },
    components:{
        messageDialog,
        emptyInfo
    },
    created(){
        this.getOrderList();
    },
    mounted(){
        window.addEventListener('scroll', this.onScroll);
    },
    methods:{
        getEventShowOrHidden(e){
            let v = e.currentTarget.innerHTML;
            if(v=="显示") {
                e.currentTarget.innerHTML = "隐藏";
                e.currentTarget.parentElement.nextElementSibling.style.display = "block"
            }
            if(v=="隐藏"){
                e.currentTarget.innerHTML = "显示";
                e.currentTarget.parentElement.nextElementSibling.style.display="none"
            }
        },
        onScroll() {
            //可滚动容器的高度
            let innerHeight = document.querySelector('#app').clientHeight;
            //屏幕尺寸高度
            let outerHeight = document.documentElement.clientHeight;
            //可滚动容器超出当前窗口显示范围的高度
            let scrollTop = document.documentElement.scrollTop || document.body.scrollTop || window.pageYOffset;
            //scrollTop在页面为滚动时为0，开始滚动后，慢慢增加，滚动到页面底部时，出现innerHeight < (outerHeight + scrollTop)的情况，严格来讲，是接近底部。
            if (innerHeight <= (outerHeight + scrollTop)) {
                //加载更多操作
                if(this.pageNo <= this.totalPage && this.isEnd == true){
                this.isEnd = false;
                this.getOrderList();
                }
            }
        },
        getOrderList(){
            var that = this;
            var url = '/api/h5/orderListByType';
            var data = {
                pageNo:this.pageNo,
                pageSize:this.pageSize,
                orderStatus:1
            }
            this.$post(url,data).then(res=>{
                that.isEnd = true;
                if(res.resultCode == 10000){
                    that.pageNo += 1;
                    that.totalPage = res.result.page.pageCount;
                    if(res.result.orderList != undefined &&　res.result.orderList.length > 0){
                        var orderList = res.result.orderList;
                        for(let i=0;i<orderList.length;i++){
                            if(orderList[i].createTime == ''){
                            orderList[i].createTime = '--';
                            }else{
                            orderList[i].createTime = this.$getDate(orderList[i].createTime);
                            }
                        }
                        this.orderList = this.orderList.concat(orderList);
                    }
                    if(this.orderList.length == 0){
                        this.empty = true;
                    }
                }else{
                    this.empty = true;
                }
            })
        },
        seeChildOrder(orderSn){
            this.orderSn = orderSn;
            var that = this;
            var url = '/api/h5/subOrderList';
            var data = {
                pageNo:1,
                pageSize:100,
                orderSn:orderSn
            }
            this.$post(url,data).then(res=>{
                that.isEnd = true;
                if(res.resultCode == 10000){
                    this.childOrderList = res.result.orderList;
                    if(this.childOrderList.length > 0){
                        this.childOrder = true;
                    }
                }
            })
        },
        closeModal(){
            this.childOrder = false;
        },
        seeProgress(logisticsNumbers){
            this.$router.push({
                path:'/process/' + logisticsNumbers
            })
        },
        copyContent(content){
            var that = this;
            handleClipboard(content, event, () => {
                that.promit=true
                setTimeout(function(){
                    that.promit = false;
                },1500)
            })
        }
    },
    destroyed(){
        window.removeEventListener('scroll', this.onScroll)
    }
}
</script>

<style lang="scss" scoped>
.packageCN{
    text-align: left;
    width:100%;
    min-height:100%;
    background:#F6F6F6;
    .title{
        background: #fff;
        padding:3rem 6%;
        color:#333333;
        font-size: 3rem;
    }
    .list{
        padding:3rem 4%;
        .item{
            background: #fff;
            border-radius: 1rem;
            margin-bottom:3rem;
            .head{
                display:-webkit-box;
                display: -moz-box;
                display: -ms-flexbox;
                display: -webkit-flex;
                display: flex;
                -webkit-justify-content: space-between;
                justify-content: space-between;
                -moz-box-pack: space-between;
                -webkit--moz-box-pack: space-between;
                box-pack: space-between;
                padding:2rem 3rem;
                color:#333333;
                font-size: 2.5rem;
                p{
                    display: flex;
                    align-items: center;
                }
                .logisticsNumber{
                    color:#999;
                    font-size:2.3rem;
                }
                .copyImg{
                    width:2.5rem;
                    display: inline-block;
                    margin-left:2rem;
                }
            }
            .orderNo{
                color:#0080CB;
            }
            .line{
                width:100%;
                height:0;
                border-bottom: 2px solid #EEEEEE;
            }
            .content{
                padding: 3rem;
                display:-webkit-box;
                display: -moz-box;
                display: -ms-flexbox;
                display: -webkit-flex;
                display: flex;
                -webkit-justify-content: space-between;
                justify-content: space-between;
                -moz-box-pack: space-between;
                -webkit--moz-box-pack: space-between;
                box-pack: space-between;
                align-items: flex-end;
                -webkit-align-items: flex-end;
                box-align:flex-end;
                -moz-box-align: flex-end;
                -webkit-box-align: flex-end;
                .package{
                    width:20rem;
                    height:18rem;
                    background: #eee;
                    border-radius: 1rem;
                    img{
                        width:100%;
                        height:100%;
                        border-radius: 1rem;
                    }
                }
                .btn{
                    padding:1.5rem 5rem;
                    background: #ef3c4d;
                    color:#fff;
                    font-size: 2.4rem;
                    border-radius: 1rem;
                }
            }
            .time{
                font-size:2.5rem;
                padding:2rem 3rem;
            }
        }
    }
    .childOrder{
        width:100%;
        height:100%;
        position:fixed;
        background:rgba(0,0,0,.8);
        top:0;
        left:0;
        .modalView{
            background:#fff;
            width:80%;
            max-height: 500px;
            overflow: auto;
            border-radius: 10px;
            position:absolute;
            top:50%;
            left:50%;
            transform: translate(-50%,-50%);
            .title{
                color:#333;
                font-size:2.5rem;
                text-align: center;
                height:120px;
                line-height: 120px;
                border-bottom:1px solid #EEEEEE;
                padding:0 3rem;
                text-align: left;
            }
            ul{
                padding:1rem 3rem;
                li{
                    padding:1rem 0;
                    p{
                        width:100%;
                        overflow: hidden;
                        font-size:2.2rem;
                        padding:.5rem 0;
                    }
                    border-bottom:1px solid #EEEEEE;
                }
            }
        }
    }
}
</style>
