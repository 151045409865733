<template>
    <div class="process">
        <div class="head">
            <span>运单编号</span>
            <span>{{logisticsSn}}</span>
            <span>
                <img src="https://jcex-file-service.oss-cn-hangzhou.aliyuncs.com/jc-wms/copy.png" alt="" @click="copySuccess">
            </span>
        </div>
        <emptyInfo v-show="empty" content="暂无物流信息"></emptyInfo>
        <ul class="list" v-show="!empty">
            <li v-for="(item,index) in logisticsList" :key="index">
                <div class="point">
                    <img src="https://jcex-file-service.oss-cn-hangzhou.aliyuncs.com/jc-wms/gl_now.png" v-if="index != 0">
                    <img src="https://jcex-file-service.oss-cn-hangzhou.aliyuncs.com/jc-wms/gl_conf.png" v-if="index == 0" class="activeImg">
                </div>
                <div class="info">
                    <p>{{item.nodeName}}</p>
                    <p>{{item.deleveryTime}}</p>
                </div>
            </li>
        </ul>
        <messageDialog :content = 'messageContent' v-show="promit"></messageDialog>
    </div>
</template>

<script>
import emptyInfo from '@/components/emptyInfo.vue';
import {handleClipboard} from '@/assets/js/clipboard';
import messageDialog from '@/components/messageDialog.vue';
    export default {
        name: "process",
        data(){
            return {
                logisticsList:[],
                isEnd:true,
                pageNo:1,
                pageSize:10,
                totalPage:0,
                logisticsSn:'',
                type:1,
                messageContent:'',
                promit:false,
                empty:false
            }
        },
        components:{
            emptyInfo,
            messageDialog
        },
        created(){
            this.type = this.$route.params.id;
            this.logisticsSn = this.$route.params.sn;
            this.getLogisticsList();
        },
        mounted(){
            // window.addEventListener('scroll', this.onScroll);
        },
        methods:{
            onScroll() {
                //可滚动容器的高度
                let innerHeight = document.querySelector('#app').clientHeight;
                //屏幕尺寸高度
                let outerHeight = document.documentElement.clientHeight;
                //可滚动容器超出当前窗口显示范围的高度
                let scrollTop = document.documentElement.scrollTop || document.body.scrollTop || window.pageYOffset;
                //scrollTop在页面为滚动时为0，开始滚动后，慢慢增加，滚动到页面底部时，出现innerHeight < (outerHeight + scrollTop)的情况，严格来讲，是接近底部。
                if (innerHeight <= (outerHeight + scrollTop + 5)) {
                    //加载更多操作
                    if(this.pageNo <= this.totalPage && this.isEnd == true){
                        this.isEnd = false;
                        this.getLogisticsList();
                    }
                }
            },
            getLogisticsList(){
                var that = this;
                var url = '/api/wms/logisticsDetail';
                var data = {
                    logisticsNumbers:this.logisticsSn,
                    type:this.type
                }
                this.$post(url,data).then(res=>{
                    if(res.resultCode == 10000){
                        this.logisticsSn = res.result.logisticsNumbers;
                        this.logisticsList = res.result.detailList;
                        if(this.logisticsList.length == 0){
                            this.empty = true;
                        }
                    }else{
                        this.empty = true;
                    }
                })
            },
            copySuccess(){
                handleClipboard(this.logisticsSn, event, () => {
                    this.messageContent = '复制成功！'
                    this.promit = true;
                    var that = this;
                    setTimeout(function(){
                        that.promit = false;
                    },1500)
                })
            }
        },
        destroyed(){
            window.removeEventListener('scroll', this.onScroll)
        }
    }
</script>

<style lang="scss" scoped>
.process{
    background: #fff;
    width:100%;
    min-height:100%;
    padding-left:6%;
    overflow: auto;
    .head{
        text-align: left;
        padding:3rem 0;
        border-bottom:2px solid #eee;
        display: flex;
        align-items: center;
        span:nth-child(1){
            color:#333333;
            font-size: 3rem;
            font-weight: 500;
        }
        span:nth-child(2){
            color:#F6A04E;
            font-size: 2.8rem;
            padding:0 2rem 0 4rem
        }
        span:nth-child(3){
            display: inline-block;
            width:2.5rem;
            img{
                width:100%;
                display: block;
            }
        }
    }
    .list{
        text-align: left;
        li{
            margin:2.5rem 0;
            display: flex;
            position: relative;
            .first{
                width:5rem;
                img{
                    width:100%;
                }
            }
            .point{
                position:relative;
                img{
                    width:2rem;
                }
            }
            .point:after{
                position:absolute;
                content:'';
                height:calc(100% + 0.5rem);
                top:2rem;
                left:50%;
                transform: translate(-50%,0);
                width:2px;
                background:#EEEEEE;
            }
            .line {
                width: 0;
                height: 100%;
                border-left: 5px solid #eee;
                margin-left: 1.6rem;
                margin-top:-.1rem;
            }
            .info{
                padding:0 6% 2rem 1rem;
                margin-left:1rem;
                border-bottom:2px solid #eee;
                flex-grow: 1;
                p:nth-child(1){
                    color:#333333;
                    font-size: 2.4rem;
                    line-height: 4rem;
                    .tel{
                        color:#199BFF;
                        font-size:2.3rem ;
                        text-decoration: underline;
                    }
                }
                p:nth-child(2){
                    color:#666666;
                    font-size: 2.3rem;
                    padding:2rem 0;
                }
            }
        }
        li:nth-child(1){
            .point{
                width:2rem;
                .activeImg{
                    position: absolute;
                    width:3.5rem;
                    top:0;
                    left:50%;
                    transform: translate(-50%,0);
                }
            }
            .point:after{
                top:3.5rem;
                height:calc(100% - 1rem);
            }
        }
        li:last-child{
            .info{
                border-bottom: none;
            }
        }
    }
}
</style>
