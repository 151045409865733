<template>
    <div class="configOrder">
        <div class="adress">
            <p>订单详情</p>
        </div>
        <ul class="orderInfo">
            <li>
                <span>订单号：</span>
                <span :class="orderSn == ''?'noInfo' : ''">{{orderSn == null || orderSn == ''?'--' : orderSn}}</span>
            </li>
            <li>
                <span>总计费重量(kg)：</span>
                <span :class="orderDetail.billWeight == ''?'noInfo' : ''">{{orderDetail.billWeight == null || orderDetail.billWeight == ''?'--' : orderDetail.billWeight/1000}}</span>
            </li>
            <li>
                <span>收件人：</span>
                <span :class="orderDetail.recipients == ''?'noInfo' : ''">{{orderDetail.recipients == null || orderDetail.recipients ==''?'--' : orderDetail.recipients.length > 20?recipients.substring(0,20) + '...' : orderDetail.recipients}}</span>
            </li>
            <li>
                <span>电话：</span>
                <span :class="orderDetail.telphone == ''?'noInfo' : ''">{{orderDetail.telphone == null || orderDetail.telphone == ''?'--' : orderDetail.telphone}}</span>
            </li>
            <li>
                <span>收货地址：</span>
                <span :class="adress == ''?'noInfo' : ''">{{adress == null || adress == ''?'--' : adress}}</span>
            </li>

            <li>
                <span>包裹运费(￥)：</span>
                <span :class="orderDetail.orderFixedPrice == ''?'noInfo' : ''">{{orderDetail.orderFixedPrice == null || orderDetail.orderFixedPrice == ''?'--' : orderDetail.orderFixedPrice/100}}</span>

            </li>
            <li>
                <span>仓储费(￥)：</span>
                <span :class="(orderDetail.storeOneMoney == '' ||  orderDetail.storeTwoMoney == '')?'noInfo' : ''">{{(orderDetail.storeOneMoney+orderDetail.storeTwoMoney)/100}}</span>
            </li>
            <li>
                <span>增值服务费(￥)：</span>
                <span :class="orderDetail.serviceMoney == ''?'noInfo' : ''">{{orderDetail.serviceMoney/100}}</span>
            </li>
            <li>
                <span>保险费(￥)：</span>
                <span :class="orderDetail.insuredPremium == ''?'noInfo' : ''">{{orderDetail.insuredPremium == null || orderDetail.insuredPremium == ''?'--' : orderDetail.insuredPremium/100}}</span>
            </li>

            <li>
                <span>订单总金额(￥)：</span>
                <span :class="orderDetail.orderFixedPrice == ''?'noInfo' : ''">{{orderDetail.orderFixedPrice == null || orderDetail.orderFixedPrice == ''?'--' : orderDetail.totoleMoney/100}}</span>
            </li>

            <li>
                <span>支付方式：</span>
                <span :class="orderDetail.payWay == ''?'noInfo' : ''">{{orderDetail.payWay == 1?'微信' : orderDetail.payWay == 2?'淘宝' : '--'}}</span>
            </li>
            <li>
                <span>支付时间：</span>
                <span :class="orderDetail.payTime == ''?'noInfo' : ''">{{orderDetail.payTime == null || orderDetail.payTime == ''?'--' : orderDetail.payTime}}</span>
            </li>


        </ul>
    </div>
</template>

<script>
export default {
    name: "question",
    data(){
        return {
            orderSn:'',
            orderDetail:'',
            adress:''
        }
    },
    created(){
        this.orderSn = this.$route.params.id;
        this.getOrderDetail();
    },
    methods:{
        getOrderDetail(){
            this.modal = true;
            var that = this;
            var url = '/api/h5/orderDetail';
            var data = {
                orderSn:this.orderSn
            }
            this.$post(url,data).then(res=>{
              console.log("8887777")
              console.log(res)
              console.log("8887777")
                if(res.resultCode == 10000){
                    this.orderDetail = res.result.orderDetail;
                    this.adress = res.result.orderDetail.address + ' ' + res.result.orderDetail.city + ' ' + res.result.orderDetail.province + ' ' + res.result.orderDetail.country;
                    this.orderDetail.createTime = this.$getDate(this.orderDetail.createTime);
                    this.orderDetail.payTime = this.$getDate(this.orderDetail.payTime);
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.configOrder{
    width:100%;
    min-height:100%;
    background:#F6F6F6;
    padding-bottom:180px;
    .adress{
        padding-left:3rem;
        background:#fff;
        p{
            padding:2.5rem 0;
            text-align: left;
        }
        p:nth-child(1){
            border-bottom:2px solid #eee;
            color:#333;
            font-size:3rem;
        }
        p:nth-child(2){
            color:#999;
            font-size:2.5rem;
            position:relative;
            padding-right:6rem;
            .arrow{
                position:absolute;
                top:50%;
                left:calc(100% - 4.5rem);
                transform: translate(0,-50%);
                width:1.5rem;
            }
        }
    }
    .orderInfo{
        background:#fff;
        margin-top:2rem;
        padding-left:3rem;
        li{
            text-align: left;
            padding:2.5rem 3rem 2.5rem 0;
            font-size:2.5rem;
            display: flex;
            justify-content: space-between;
            border-bottom:2px solid #EEEEEE;
            color:#333;
            .noInfo{
                color:#999;
            }
            span:nth-child(1){
                flex-shrink: 0;
            }
            span:nth-child(2){
                word-break: break-all;
            }
        }
        li:nth-child(5){
            span:nth-child(2){
                max-width: 600px;
            }
        }
    }
}
</style>
