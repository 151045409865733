<template>
	<div class="countResult">
		<emptyInfo v-show="empty"></emptyInfo>
		<div class="context">
			<div
				class="item"
				v-for="(item, index) in infoList"
				:key="index"
				@click="detail(item)"
			>
				<div class="main">
					<div class="title">
						<img src="../assets/images/prod_ico.png" />
						<span>{{ item.productName }}</span>
					</div>
					<div class="sub">
						<span>预估时效:{{ item.limitation }}</span>
					</div>
				</div>
				<div class="minor">
					<span class="text">预估运费</span>
					<div class="price">
						<span class="symbol">¥</span>
						<span class="value">{{
							item.sumPrice ? item.sumPrice : 0
						}}</span>
					</div>
				</div>
				<div class="go">
					<span>&gt;</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { Toast } from "mint-ui";
	import emptyInfo from "@/components/emptyInfo.vue";
	export default {
		name: "countResult",
		data() {
			return {
				empty: false,
				countryId: "",
				country: "",
				weight: "",
				length: "",
				width: "",
				height: "",
				infoList: [],
			};
		},
		components: {
			emptyInfo,
		},
		created() {
			this.countryId = this.$route.query.countryId;
			this.weight = this.$route.query.weight;
			this.length = this.$route.query.length;
			this.width = this.$route.query.width;
			this.height = this.$route.query.height;
			this.country = this.$route.query.country;
			this.getCountResult();
		},
		methods: {
			getCountResult() {
				var that = this;
				var url = "/api/h5/estimation";
				var data = {
					countryId: this.countryId,
					weight: this.weight * 1000,
					length: this.length,
					width: this.width,
					height: this.height,
				};
				this.$post(url, data).then((res) => {
					if (res.resultCode == 10000) {
						this.infoList = res.result.estimationList;
					} else {
						let instance = Toast(res.error);
						setTimeout(() => {
							instance.close();
							this.$router.push({
								name: "carriage",
							});
						}, 2000);
					}
				});
			},

			detail(item) {
				this.$router.push({
					name: "countDetail",
					params: item,
				});
			},
		},
	};
</script>

<style lang="scss" scoped>
	.context {
		padding: 10px;
		background-color: rgb(218, 218, 218);
		width: 100%;
	}

	.item {
		background-color: white;
		border-radius: 3px;
		display: flex;
		padding: 10px;

		.main {
			text-align: left;
			flex-grow: 2;
			.title {
				color: black;
				font-size: 16px;
				font-weight: bold;
				margin-bottom: 10px;

				> * {
					vertical-align: middle;
				}

				span {
					margin-left: 5px;
				}
			}

			img {
				width: 20px;
			}

			.sub {
				font-size: 14px;
			}
		}

		.minor {
			flex-grow: 1;
			font-size: 12px;

			.text {
				display: block;
				margin-bottom: 8px;
			}

			.price {
				font-size: 16px;
				color: #0a7dde;
			}
		}

		+ .item {
			margin-top: 10px;
		}
	}

	.go {
		display: flex;
		justify-content: center;
		flex-direction: column;

		span {
			font-size: 14px;
			font-family: monospace;
			font-weight: 700;
		}
	}
</style>
