<template>
  <div class="transport">
    <emptyInfo v-show="empty"></emptyInfo>
    <div v-show="!empty">
      <p class="title" v-show="orderList.length > 0">已上架</p>
      <selectAddressShow @selectAddressId="getSelectAddressId(arguments)" :addressId="addressId"></selectAddressShow>
      <div class="list">
        <div class="item" v-for="(item,index) of orderList" :key="index">
          <div class="left">
            <span @click="payChose(item)">
              <img src="@/assets/images/pay-chose.png" alt="" v-show="item.select">
            </span>
          </div>
          <div class="right">
            <div class="head">
              <p class="logisticsNumber">
                <span>国内快递：{{item.logisticsNumbers == null?'暂无' : item.logisticsNumbers != null && item.logisticsNumbers.length > 18?item.logisticsNumbers.substring(0,18) + '...' : item.logisticsNumbers}}</span>
                <img src="https://jcex-file-service.oss-cn-hangzhou.aliyuncs.com/jc-wms/copy.png" @click="copyContent(item.logisticsNumbers)" class="copyImg"
                  v-show="item.logisticsNumbers != null">
              </p>
              <p style="font-size:2.0rem">{{item.entryTimeStr}}</p>
            </div>
            <div class="line"></div>
            <div class="content">
              <!-- <p class="package">
								<img src="https://jcex-file-service.oss-cn-hangzhou.aliyuncs.com/jc-wms/pic.jpg" v-show="item.parcelImgList == null">
								<img v-for="(itemImg,indexImg) in item.parcelImgList" :src="itemImg" v-show="item.parcelImgList != null" :key="indexImg">
							</p> -->


              <p class="package">
                <img src="https://jcex-file-service.oss-cn-hangzhou.aliyuncs.com/jc-wms/pic.jpg" v-show="item.parcelImgList == null">
                <template v-show="item.parcelImgList != null">
                  <el-carousel height="150px" :autoplay="false" arrow="always" indicator-position="outside">
                    <el-carousel-item v-for="(itemImg,indexImg) in item.parcelImgList" :key="indexImg">
                      <div class="demo-image__preview">
                        <el-image style="text-align: center"
                          :src="itemImg"
                          :preview-src-list="item.parcelImgList">
                        </el-image>
                      </div>
                    </el-carousel-item>
                  </el-carousel>
                </template>
              </p>
            </div>
            <div style="height: 5.3rem;">
              <div class="viceDiv_1" style="width: 45%;padding-top: 1rem;font-size: 2.3rem;">
                体积重量：{{item.volumeWeight/1000}}(kg)
              </div>
              <div class="viceDiv_2" style="width:45%;float: right;padding-top: 1rem;font-size: 2.3rem;">
                实际重量：{{item.weight/1000}}(kg)
              </div>
            </div>

            <div class="packageWeight">
              <div class="viceDiv_3" style="width:45%;float: right;">
                入库计费重量：{{item.billWeight/1000}}(kg)
              </div>
              <div v-show="item.remark" class="viceDiv_4" style="float: left;width: 49%;">
                {{item.remark}}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="foot">
        <div class="chose">
          <span @click="ChoseAll">
            <img src="@/assets/images/pay-chose.png" alt="" v-if="type">
          </span>
          <p>全选</p>
          <p>总重量：{{packageWeight/1000}}(kg)</p>
        </div>
        <div v-show="channel == 0">
          <div class="btn" v-show="btnType == 2" @click="openModal">合并打包</div>
          <div class="btn" v-show="btnType == 1" @click="saveInfo">去支付</div>
        </div>
        <div v-show="channel == 1">
          <div class="btn" @click="openModal" style="background:#0A7DDE ;border-radius: 4rem;">确认合包</div>
        </div>
      </div>
      <div class="modal" v-show="modal">
        <div class="content">
          <p @click="closeModal">
            <img src="@/assets/images/close_gray.png" class="closeIcon">
          </p>
          <p>您确定要合并订单吗？</p>
          <p>
            <span @click="closeModal">取消</span>
            <span @click="saveInfo">确定</span>
          </p>
        </div>
        <p class="lawyer"></p>
      </div>
    </div>
    <messageDialog :content='messageContent' v-show="messageDialog"></messageDialog>
  </div>
</template>

<script>
  import emptyInfo from '@/components/emptyInfo.vue'
  import {
    handleClipboard
  } from '@/assets/js/clipboard'
  import messageDialog from '@/components/messageDialog.vue'
  import selectAddressShow from '@/pages/selectAddress.vue'
  export default {
    name: 'transport',
    data() {
      return {
        empty: false,
        isEnd: true,
        orderList: [],
        previewSrcList: [],
        type: false,
        modal: false,
        pageNo: 1,
        pageSize: 900,
        totalPage: 0,
        btnType: 1, //1:去支付 2:合并
        addressId: '', //收货地址id
        messageDialog: false,
        messageContent: '',
        packageWeight: 0,
        channel: 0,
        limitWeight: 0,
      }
    },
    components: {
      emptyInfo,
      messageDialog,
      selectAddressShow,
    },
    created() {
      let self = this;
      var data = sessionStorage.getItem("transport-data");
      let orderSns = "";
      if (data) {
        sessionStorage.removeItem("transport-data");
        let query = JSON.parse(data);
        this.addressId = query.addressId;
        this.contury = query.contury;
        orderSns = query.orderSns;
      }
      this.getOrderList(function() {
        for (var i = 0; i < self.orderList.length; i++) {
          if (orderSns.indexOf(self.orderList[i].orderSn) >= 0) {
            self.orderList[i].select = false;
          }
        }
      });
      this.channel = this.$route.query.channel
      // console.log('-----------'+this.channel)
    },
    mounted() {
      window.addEventListener('scroll', this.onScroll);


    },
    methods: {
      change(){
        console.log("888999999")
      },
      getSelectAddressId: function(param) {
        // childValue就是子组件传过来的值
        // console.log('-----addressId-----'+param)
        this.addressId = param[0]
        this.contury = param[1]
        this.limitWeight = param[2]
        // console.log(this.limitWeight+'-------limitWeight------'+this.addressId+'-----addressId-----'+this.contury)
      },
      onScroll() {
        //可滚动容器的高度
        let innerHeight = document.querySelector('#app').clientHeight
        //屏幕尺寸高度
        let outerHeight = document.documentElement.clientHeight
        //可滚动容器超出当前窗口显示范围的高度
        let scrollTop =
          document.documentElement.scrollTop ||
          document.body.scrollTop ||
          window.pageYOffset
        //scrollTop在页面为滚动时为0，开始滚动后，慢慢增加，滚动到页面底部时，出现innerHeight < (outerHeight + scrollTop)的情况，严格来讲，是接近底部。
        if (innerHeight <= outerHeight + scrollTop) {
          //加载更多操作
          if (this.pageNo <= this.totalPage && this.isEnd == true) {
            this.isEnd = false
            this.getOrderList()
          }
        }
      },

      getOrderList(callback) {
        var that = this
        var url = '/api/h5/orderListByType'
        var data = {
          pageNo: this.pageNo,
          pageSize: this.pageSize,
          orderStatus: 10,
        }
        this.$post(url, data).then((res) => {
          that.isEnd = true
          if (res.resultCode == 10000) {
            that.pageNo += 1
            that.totalPage = res.result.page.pageCount
            var orderList = res.result.orderList
            for (let i = 0; i < orderList.length; i++) {
              orderList[i].select = false
              let url = orderList[i].parcelImg
              if (url != null) {
                this.previewSrcList.push(url)
              }
            }
            if (orderList != undefined && orderList.length > 0) {
              this.orderList = this.orderList.concat(orderList)
            }
            if (this.orderList.length == 0) {
              this.empty = true
            }

            callback();
          } else {
            this.empty = true
          }
        })
      },
      saveInfo() {

        if (this.addressId == '') {
          this.messageContent = '请先选择收货地址'
          this.messageDialog = true
          var that = this
          setTimeout(function() {
            that.messageDialog = false
          }, 1500)
          return false
        }

        this.modal = false
        var orderSnList = []
        var orderStr = ''

        for (let i = 0; i < this.orderList.length; i++) {
          if (this.orderList[i].select == true) {
            orderSnList.push(this.orderList[i].orderSn)
          }
        }
        /* if (this.packageWeight / 1000 > this.limitWeight / 1000) {
        	this.messageContent =
        		'包裹总重量不能超过' + this.limitWeight / 1000 + 'kg'
        	this.messageDialog = true
        	var that1 = this
        	setTimeout(function () {
        		that1.messageDialog = false
        	}, 1500)
        	return false
        } */
        orderStr = orderSnList.join(',')

        if (orderStr == '') {
          this.messageContent = '请先选择要合并的包裹'
          this.messageDialog = true
          var that2 = this
          setTimeout(function() {
            that2.messageDialog = false
          }, 1500)
          return false
        }
        let query = {
          orderSns: orderStr,
          addressId: this.addressId,
          channel: this.channel,
          contury: this.contury,
          packageWeight: this.packageWeight,
        }

        this.$router.push({
          name: 'addressEditOrder',
          query: query,
        });
        sessionStorage.setItem("transport-data", JSON.stringify(query));
      },
      payChose(item) {
        if (item.select == false) {
          item.select = true
          this.packageWeight = this.packageWeight + item.billWeight
        } else {
          item.select = false
          this.packageWeight = this.packageWeight - item.billWeight
        }
        var num = 0
        for (var i = 0; i < this.orderList.length; i++) {
          if (this.orderList[i].select == true) {
            num += 1
          }
        }
        if (num > 1) {
          this.btnType = 2
        } else {
          this.btnType = 1
        }
        if (num == this.orderList.length) {
          this.type = true
        } else {
          this.type = false
        }
      },
      ChoseAll() {
        this.type = !this.type
        if (this.type == true) {
          this.packageWeight = 0
          for (var i = 0; i < this.orderList.length; i++) {
            this.packageWeight = this.packageWeight + this.orderList[i].billWeight
            this.orderList[i].select = this.type
          }
        } else {
          this.packageWeight = 0
          for (var i1 = 0; i1 < this.orderList.length; i1++) {
            this.orderList[i1].select = this.type
          }
        }
      },
      copyContent(content) {
        var that = this
        handleClipboard(content, event, () => {
          that.messageContent = '复制成功'
          that.messageDialog = true
          setTimeout(function() {
            that.messageDialog = false
          }, 1500)
        })
      },
      closeModal() {
        this.modal = false
      },
      openModal() {
        this.modal = true
      },
    },
  }
</script>

<style lang="scss">
  .transport {
    text-align: left;
    width: 100%;
    min-height: 100%;
    background: #f6f6f6;

    .title {
      background: #fff;
      padding: 3rem 6%;
      color: #333333;
      font-size: 3rem;
    }

    .tips {
      padding: 2rem 4% 0 4%;
      text-align: left;
      color: #999;
      font-size: 1.9rem;
      border-radius: 10px;
      width: 100%;
    }

    .list {
      padding: 1rem 2%;
      padding-bottom: 140px;

      .item {
        background: #fff;
        border-radius: 1rem;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        margin-bottom: 2rem;

        .left {
          width: 10%;

          span {
            width: 3rem;
            height: 3rem;
            border: 1px solid #b5b5b5;
            border-radius: 50%;
            display: inline-block;
            margin: 2rem;

            img {
              width: 100%;
            }
          }
        }

        .right {
          flex: 1;

          .head {
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -webkit-justify-content: space-between;
            justify-content: space-between;
            -moz-box-pack: space-between;
            -webkit--moz-box-pack: space-between;
            box-pack: space-between;
            padding: 2rem 1rem;
            color: #333333;
            font-size: 12px;

            p {
              display: flex;
              align-items: center;
            }

            .logisticsNumber {
              color: #999;
            }

            .copyImg {
              width: 2.5rem;
              display: inline-block;
              margin-left: 2rem;
            }
          }

          .line {
            width: 100%;
            height: 0;
            border-bottom: 2px solid #eeeeee;
          }

          .content {
            padding: 1rem;
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -webkit-justify-content: space-between;
            justify-content: space-between;
            -moz-box-pack: space-between;
            -webkit--moz-box-pack: space-between;
            box-pack: space-between;
            align-items: flex-end;
            -webkit-align-items: flex-end;
            box-align: flex-end;
            -moz-box-align: flex-end;
            -webkit-box-align: flex-end;
            .package {
              overflow: hidden;
              width: 100%;
              height: 150px;
              /* width: 20rem;
              height: 18rem; */
              background: #eee;
              border-radius: 1rem;
              img {
                width: 150px;
                height: 131px;
                border-radius: 1rem;
                padding: 9px 0 0 0;
              }
              .el-carousel__arrow{
                z-index: 3;
              }
            }
          }

          .packageWeight {
            padding-top: 1rem;
            font-size: 2.3rem;
            border-top: 2px dashed #d0d0d0;
            text-align: left;
          }

          .viceDiv_1,
          .viceDiv_2{
            float: left;
            margin-bottom: 1rem;
          }
          .viceDiv_3{
            float: left;
            margin-bottom: 1rem;
          }
          .viceDiv_4{
            margin-bottom: 1rem;
            word-break:break-all;
          }
        }
      }
    }

    .foot {
      background: #fff;
      padding: 2rem 3rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 3;

      .chose {
        color: #333;
        font-size: 0px !important;
        display: flex;
        justify-content: space-between;
        align-items: center;

        span {
          width: 3rem;
          height: 3rem;
          border: 1px solid #b5b5b5;
          border-radius: 50%;
          display: inline-block;

          img {
            width: 100%;
          }
        }

        p {
          font-size: 2.2rem;
          padding-left: 1rem;
          padding-right: 1rem;
        }
      }

      .btn {
        background: #ef3c4d;
        padding: 1rem 2rem;
        color: #fff;
        border-radius: 5px;
        font-size: 2.8rem;
      }
    }


    .modal {
      position: fixed;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.5);
      z-index: 30;

      .content {
        position: absolute;
        top: 35%;
        left: 15%;
        z-index: 10;
        background: #fff;
        border-radius: 2rem;
        width: 70%;

        p:nth-child(1) {
          text-align: right;
          padding: 2rem 6% 1rem 0;
          color: #bbbbbb;
          font-size: 3rem;
        }

        p:nth-child(2) {
          text-align: center;
          color: #333;
          font-size: 2.8rem;
          padding-bottom: 4rem;
          border-bottom: 1px solid #eee;
        }

        .closeIcon {
          width: 15px;
        }

        p:nth-child(3) {
          span {
            padding: 3rem 0;
            display: inline-block;
            font-size: 2.8rem;
            text-align: center;
            width: 50%;
          }

          span:nth-child(1) {
            color: #999999;
            border-right: 2px solid #eee;
          }

          span:nth-child(2) {
            color: #1ca1fe;
          }
        }
      }
    }
  }
</style>
