<template>
    <div class="question">
        <emptyInfo v-show="empty"></emptyInfo>
        <div v-show="!empty">
            <p class="title">有奖问答</p>
            <div class="item" v-for="(item,index) in list" :key="index">
                <p class="ques">Q：{{item.qaTitile}} </p>
                <p class="ans">A：{{item.qaValue}}</p>
            </div>
        </div>
    </div>
</template>

<script>
import emptyInfo from '@/components/emptyInfo.vue';
export default {
    name: "question",
    data(){
        return {
            empty:false,
            isEnd:true,
            pageNo:1,
            pageSize:10,
            totalPage:0,
            list:[]
        }
    },
    components:{
        emptyInfo
    },
    created(){
        this.getQuestionList();
    },
    mounted(){
        window.addEventListener('scroll', this.onScroll);
    },
    methods:{
        onScroll() {
            //可滚动容器的高度
            let innerHeight = document.querySelector('#app').clientHeight;
            //屏幕尺寸高度
            let outerHeight = document.documentElement.clientHeight;
            //可滚动容器超出当前窗口显示范围的高度
            let scrollTop = document.documentElement.scrollTop || document.body.scrollTop || window.pageYOffset;
            //scrollTop在页面为滚动时为0，开始滚动后，慢慢增加，滚动到页面底部时，出现innerHeight < (outerHeight + scrollTop)的情况，严格来讲，是接近底部。
            if (innerHeight <= (outerHeight + scrollTop)) {
                //加载更多操作
                if(this.pageNo <= this.totalPage && this.isEnd == true){
                this.isEnd = false;
                this.getQuestionList();
                }
            }
        },
        getQuestionList(){
            var that = this;
            var url = '/api/h5/qaList';
            var data = {
                pageNo:this.pageNo,
                pageSize:this.pageSize,
            }
            this.$post(url,data).then(res=>{
                that.isEnd = true;
                if(res.resultCode == 10000){
                    that.pageNo += 1;
                    that.totalPage = res.result.page.pageCount;
                    if(res.result.qaList != undefined &&　res.result.qaList.length > 0){
                        this.list = this.list.concat(res.result.qaList);
                    }
                    if(this.list.length == 0){
                        this.empty = true;
                    }
                }else{
                    this.empty = true;
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.question{
    background: #fff;
    min-height:100%;
    width:100%;
    text-align: left;
    padding-left:6%;
    .title{
        color:#333333;
        font-size:3rem ;
        padding:3rem 0;
        border-bottom:2px solid #E5E5E5;
        font-weight: 500;
    }
    .item{
        padding-right:6%;
        margin:2rem 0;
        .ques{
            color:#333333;
            font-size:3rem ;
            padding:1rem 0;
            font-weight: 500;
        }
        .ans{
            color:#999999;
            font-size:3rem ;
            padding:1rem 0;
            line-height: 4rem;
        }
    }
}
</style>