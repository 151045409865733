<template>
  <div class="share">
       <van-overlay :show="visibel.show" @click="visibel.show = false">
           <div class="share-box" @click.stop>
                <div class="share-list" v-for="(item,i) in options" :key="i">
                    <div class="item" @click="goShare(item, i)">
                        <img :src="item.icon" alt="">
                        <p>{{item.name}}</p>
                    </div>
                </div>
           </div>
       </van-overlay>
       <!-- 面对面 -->
       <van-overlay :show="showFaceToFace">
           <div class="face">
                <div class="contents">
                    <div class="close-icon">
                        <img src="../../assets/images/share/invit-tan-x.png" alt="" @click.stop="closeFace">
                    </div>
                    <div class="img-box">
                        <img class="bg" src="../../assets/images/share/invit-pic-mbg.png" alt="">
                        <div class="ks">
                              <div class="code-box">
                                  <div class="code-img" ref="shareCodes" v-show="false">

                                  </div>
                                  <img :src="codeUrl" alt="" class="img-code"/>
                                  <img src="../../assets/images/share/logo.png" alt="" class="logos">
                              </div>
                              <div class="title-msg">
                                  <van-icon name="scan" />面对面扫一扫
                              </div>
                              <div class="warn-msg">
                                  邀请好友使用微信扫一扫
                                  和好友一起领运费抵现金
                              </div>
                        </div>
                    </div>
                </div>
           </div>
       </van-overlay>
       <!-- 图片 -->
       <van-overlay :show="showImageShare">
           <div class="images">
                <div class="contents">
                    <div class="close-icon">
                        <img src="../../assets/images/share/invit-tan-x.png" alt="" @click.stop="closeImages">
                    </div>
                    <div class="img-box" ref="savePage">
                        <template v-if="!imgUrl">
                          <img class="bg" src="../../assets/images/share/share-img-bg.png" alt="">
                          <div class="ks">
                                  <div class="user-info">
                                      <img class="avatar" src="../../assets/images/share/play.png" alt="">
                                      <p>欧轩物流国际</p>
                                  </div>
                                  <div class="code-box">
                                    <div class="code-img" ref="shareCodes">

                                    </div>

                                      <img src="../../assets/images/share/logo.png" alt="" class="logos">
                                  </div>
                          </div>
                        </template>
                        <template v-else>
                            <img :src="imgUrl" alt="" class="bg">
                        </template>
                    </div>
                    <div class="save-box">
                        <span class="save-btn" @click.stop="saveImg">{{!imgUrl ? '保存图片' : '长按图片进行保存' }}</span>
                    </div>
                </div>
           </div>
       </van-overlay>
       <van-overlay :show="baiseShare" @click="baiseShare = false">
          <div class="share-conts">
              <img :src="jiantou">
              <span>分享{{shareData[cIndex]}}</span>
          </div>
      </van-overlay>
  </div>
</template>
<script>
import qrcode from 'qrcodejs2'
export default {
  name: 'share',
  props: {
      visibel: {
          type:Object,
          default () {
              return {
                  show: false
              }
          }
      },
      qcode: {
          type:String,
          default () {
              return ''
          }
      }
  },
  data () {
      return {
          jiantou: require('../../assets/images/share/share-jiantou.png'),
          baiseShare: false,
          showFaceToFace: false,
          showImageShare: false,
          imgUrl: '',
          shareData:['微信', '微博', '朋友圈', 'QQ'],
          cIndex: 0,
          options:
              [
                  // { name: '微信', icon: 'https://img01.yzcdn.cn/vant/share-sheet-wechat.png' },
                  // { name: '微博', icon: 'https://img01.yzcdn.cn/vant/share-sheet-weibo.png' },
                  // { name: '朋友圈', icon: 'https://img01.yzcdn.cn/vant/share-sheet-wechat-moments.png' },
                  // { name: 'QQ', icon: 'https://img01.yzcdn.cn/vant/share-sheet-qq.png' },
                  { name: '图片邀请', icon: require('../../assets/images/share/invit-tan-5.png') },
                  { name: '面对面邀请', icon: require('../../assets/images/share/invit-tan-6.png') },
              ],
          codeUrl: ''
      }
  },
  methods: {
      createShareCode (type, i) {
          if (i == 1) {
              document.getElementsByClassName('code-img')[0].innerHTML = ''
          }
          if(i ==0) {
              document.getElementsByClassName('code-imgs')[0].innerHTML = ''
          }
          this.$nextTick(()=> {
              new qrcode(this.$refs[type], {
                  text: this.qcode || location.origin,
                  width: 200,
                  height: 200,
                  colorDark: "#333333", //二维码颜色
                  colorLight: "#ffffff", //二维码背景色
                  correctLevel: qrcode.CorrectLevel.L//容错率，L/M/H
              })
              let canvas = document.getElementsByTagName('canvas')[0]
              let url = canvas.toDataURL('image/png')
              if (i == 1) {
                  setTimeout(() => {
                      this.codeUrl = url
                  }, 200)
              }
          })
      },
      goShare (item, i) {
          if (i == 1) {
              this.createShareCode('shareCodes', i)
              this.visibel.show = false
              this.showFaceToFace = true
          } else if (i == 0) {
              if (!this.imgUrl) {
                   this.createShareCode('shareCode', i)
              }
              this.visibel.show = false
              this.showImageShare = true
          }
          // else {
          //     this.cIndex = i
          //     this.visibel.show = false
          //     this.baiseShare = true
          // }
      },
      closeFace () {
          this.showFaceToFace = false
      },
      closeImages () {
          this.showImageShare = false
      },
      saveImg () {
          console.log('0000')
          if(this.imgUrl) {
              return
          }
          window.html2canvas(this.$refs.savePage,{
          useCORS: true,
          allowTaint: false,
          logging: false,
          sacle: 4,
          onrendered:canvas => {
              let dataURL = canvas.toDataURL("image/png");
              this.imgUrl = dataURL;
              var a = document.createElement("a");
              a.setAttribute("href", this.imgUrl);
              a.setAttribute("download", '一起赚现金');
              a.setAttribute("target", "_blank");
              a.setAttribute("style", "display:blok");
              let clickEvent = document.createEvent("MouseEvents");
              clickEvent.initEvent("click", true, true);
              a.dispatchEvent(clickEvent);
          }

          // letterRendering: true}).then(canvas => {
          //     console.log(3333)
          //     let dataURL = canvas.toDataURL("image/png");
          //     this.imgUrl = dataURL;
          //      var a = document.createElement("a");
          //     a.setAttribute("href", this.imgUrl);
          //     a.setAttribute("download", '一起赚现金');
          //     a.setAttribute("target", "_blank");
          //     a.setAttribute("style", "display:blok");
          //     let clickEvent = document.createEvent("MouseEvents");
          //     clickEvent.initEvent("click", true, true);
          //     a.dispatchEvent(clickEvent);
          // })
      })
      }
  }
}
</script>
<style lang="scss" scoped>
.share {
  position: relative;
  z-index: 100;
  .share-box {
      background: #fff;
      height: 40rem;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      .share-list {
          width: 25%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 2rem;
          img {
              width: 8rem;
          }
      }
  }
  .face {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 6rem;
      .contents {
          width: 100%;
          .close-icon {
              text-align: right;
              padding-bottom: 3rem;
              img {
                  width: 4rem;
                  cursor: pointer;
              }
          }
          .img-box {
              width: 100%;
              position: relative;
              overflow:hidden;
              font-size: 0;
              .bg {
                  display: block;
                  width: 100%;
              }
              .ks {
                  width: 100%;
                  height: 100%;
                  position: absolute;
                  left: 0;
                  top: 0;
                  display: flex;
                  justify-content: center;
                  flex-direction: column;
                  align-items: center;
                  .code-box {
                      width: 30rem;
                      height: 30rem;
                      position: relative;

                      .img-code {
                          display: block;
                          width: 30rem;
                          height: 30rem;
                      }
                      .logos {
                          width: 6rem;
                          height: 6rem;
                          position: absolute;
                          top: 50%;
                          left: 50%;
                          margin-left: -3rem;
                          margin-top: -3rem;
                          z-index: 10;
                      }
                  }
                  .title-msg {
                      margin-top: 2rem;
                      color: #fff;
                      font-size: 3rem;
                      font-weight: 500;
                      display: flex;
                      align-items: center;
                      i{
                          font-size: 4rem;
                      }
                  }
                  .warn-msg {
                      background: rgba(0,0,0, .2);
                      width: 50rem;
                      font-size: 2.5rem;
                      color: #fff;
                      padding: 2rem 10rem;
                      font-weight: 400;
                      border-radius: 2rem;
                      margin-top: 6rem;
                  }
              }
          }
      }
  }
  .images {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 10rem;
      .contents {
          width: 100%;
          .close-icon {
              text-align: right;
              padding-bottom: 3rem;
              img {
                  width: 4rem;
                  cursor: pointer;
              }
          }
          .img-box {
              width: 100%;
              position: relative;
              overflow:hidden;
              font-size: 0;
              .bg {
                  display: block;
                  width: 100%;
              }
              .ks {
                  width: 100%;
                  height: 100%;
                  position: absolute;
                  left: 0;
                  top: 0;
                  display: flex;
                  justify-content: center;
                  flex-direction: column;
                  align-items: center;
                  .code-box {
                      width: 16rem;
                      height: 16rem;
                      position: relative;
                      top: 11rem;
                      .code-imgs {
                          width: 100%;

                          ::v-deep img {
                              width: 100%;
                             }

                      }
                      .logos {
                          width: 3rem;
                          height: 3rem;
                          position: absolute;
                          top: 50%;
                          left: 50%;
                          margin-left: -1.5rem;
                          margin-top: -1.5rem;
                          z-index: 10;
                      }

                  }
                  .user-info {
                      width: 16rem;
                      color: #fff;
                      .avatar {
                          display: block;
                          width: 100%;
                          height: 16rem;
                          border-radius: 50%;
                          margin-bottom: 2rem;
                      }
                      p {
                          font-size: 3rem;
                      }
                  }
              }
          }
          .save-box {
              padding-top: 3rem;
              .save-btn {
                  display: inline-block;
                  width: 30rem;
                  height: 6rem;
                  border-radius: 3rem;
                  font-size: 3rem;
                  background: #fff;
                  line-height: 6rem;
                  font-weight: 600;
                  cursor: pointer;
                  color: #0a7dde;
              }
          }
      }

  }
  .share-conts {
      font-size: 16px;
      color: #fff;
      text-align: right;
      padding: 6px 11.5px;
      img {
          display: inline-block;
          height: 15rem;
      }
      span {
          display: block;
          line-height: 5;
          margin: -3rem 15.5rem;
          font-size: 3rem;
      }
  }
}
</style>
