<style>
.paybtn {
  background: #ef3c4d;
  padding: 1rem 2rem;
  color: #fff;
  border-radius: 5px;
  font-size: 2.8rem;
  width: 72px;
}
</style>

<template>
  <div class="configOrder">
    <ul class="orderInfo">
      <li>
        <span>订单号：</span>
        <span :class="orderSn == ''?'noInfo' : ''">{{orderSn == null || orderSn == ''?'--' : orderSn}}</span>
      </li>
      <li>
        <span>收件人：</span>
        <span :class="recipients == ''?'noInfo' : ''">{{recipients == null || recipients ==''?'--' : recipients.length > 20?recipients.substring(0,20) + '...' : recipients}}</span>
      </li>
      <li>
        <span>联系电话：</span>
        <span :class="telphone == ''?'noInfo' : ''">{{telphone == null || telphone == ''?'--' : telphone}}</span>
      </li>
      <li>
        <span>邮编：</span>
        <span :class="postCode == ''?'noInfo' : ''">{{postCode == null || postCode == ''?'--' : postCode}}</span>
      </li>
      <li>
        <span>国家、省、市：</span>
        <span>{{country}}、{{province}}、{{city}}</span>
      </li>
      <li>
        <span>详细地址：</span>
        <span>{{addressStr}}</span>
      </li>
      <li>
        <successInfo v-show="promitConfirm" content="提交申请成功！" @close-modal="closePromit"></successInfo>
        <failInfo v-show="promitFailConfirm" content="提交申请失败！" @close-modal="closeFailPromit"></failInfo>
        <span>增值服务：</span>
        <span>
					<el-radio-group v-model="addService" @change="changeAddService">
						<el-radio label="1">需要</el-radio>
						<el-radio label="2">不需要</el-radio>
					</el-radio-group>
				</span>
      </li>
      <li v-if="addService=='1'?true:false">
        <el-checkbox-group v-model="vaService" @change="toChangeVaServiceList">
          <el-checkbox v-for="item in vaServiceList" :label="item.vaServiceId" :key="item.vaServiceId">{{item.vaServiceContent}}</el-checkbox>
        </el-checkbox-group>
      </li>
      <!-- <li>
        <span>保险费：</span>
        <span>
          <el-radio-group v-model="safeCost" @change="changeSafeCost">
            <el-radio label="1">需要</el-radio>
            <el-radio label="2">不需要</el-radio>
          </el-radio-group>
        </span>
      </li>
      <li v-if="safeCost=='1'?true:false" class="insured">
        <span class="insuredSpan">
          <span>保额(元)：</span>
          <el-input v-model="insuredAmount" type="Number" placeholder="请输入内容" @blur="getInsuredPremium($event)"></el-input>
        </span>
        <span style="display: inline-block;width: 100%;line-height: 4.5rem;">保费(元)：&nbsp;{{(insuredPremium/100).toFixed(2)}}</span>
      </li> -->
      <div v-show="ShowChannelOne">
        <li style="list-style:none">
          <selectChennalShow :orderSn="orderSn" :country="country" :minWeight="weight" @selectAddressId="getSelectAddressId(arguments)"></selectChennalShow>
        </li>
        <li>
          <span>总计费重量(kg)：</span>
          <span>{{(sumHeavy/1000).toFixed(2)}}</span>
        </li>
        <li>
          <span>计算公式：</span>
          <span>{{formula}}</span>
        </li>
        <li style="display: inline-block;width:100%;">
          <div class="detaileTxt">费用清单：</div>
          <div class="detaileBox">
            <div class="detaileList">
              <span>包裹运费</span>
              <span>{{(sumPrice/100).toFixed(2)}}元</span>
            </div>
            <div class="detaileList">
              <span>仓储费</span>
              <span>{{(storeOneMoney/100).toFixed(2)}}元</span>
            </div>
            <div class="detaileList">
              <span>增值服务费</span>
              <span>{{(serviceMoney/100).toFixed(2)}}元</span>
            </div>
            <div class="detaileList">
              <span>保险费</span>
              <span>{{(insuredPremium/100).toFixed(2)}}元</span>
            </div>
          </div>
        </li>
      </div>
      <!-- <li>
        <div class="protoal" v-if="adressInfo != ''">
          <span @click="protoalStatus">
            <img src="@/assets/images/pay-chose.png" alt="" v-show="iconSelect">
          </span>
          <p @click="openProtoalModal">欧轩物流集运转运契约条款及免责声明</p>
        </div>
      </li> -->
    </ul>

    <div class="protoalModal" v-show="protoalModal" @click="closeModal">
      <div class="modalView">
        <span v-html="agreementDesc">{{agreementDesc}}</span>
        <!-- <p style="text-align:center;color:#333;font-size:2.5rem">欧轩物流集运转运契约条款及免责声明</p>
                <p>欧轩物流集运是欧轩物流国际物流股份有限公司旗下转运服务平台。用户在此平台上的所享受的服务皆由欧轩物流国际物流股份有限公司提供（以下简称“欧轩物流集运”）。</p>
                <p>用户使用本平台即表示您已经熟悉并了解本平台的所有功能以及所承担的义务。如不清楚请务必仔细阅读本条款。用户勾选同意以下条款，都将被视作已无条件接受本协议所涉全部内容，若用户对本声明的任何条款有异议，请停止使用欧轩物流集运所提供的全部服务。</p>
                <p style="padding:2rem 0">一、本协议适用范围</p>
                <p>本协议是用户与欧轩物流集运关于货物国内仓储，国际货物转运所订立的协议。</p>
                <p style="padding:2rem 0">二、本协议包含范围</p>
                <p style="color:#333">本协议包括两个部分：《用户提交条款》和《欧轩物流集运免责声明》</p>
                <p>条款一《用户提交条款》</p>
                <p>当您使用欧轩物流集运提交运单，将货物交付欧轩物流集运仓库，就表示您或者您所代表的货物利益人，接受欧轩物流集运的运输条款及条件。使用欧轩物流集运服务或提交运单，代表您同意并遵守以下条款，并承担违反如下条款需承担的经济和法律责任：</p>
                <p>1.您保证委托给欧轩物流集运仓储和运输的物品属于可接受的运输品；不存在任何中国法律或目的地国家或地区法律规定的禁止、限制寄递的物品；不存在任何侵犯第三方合法权益（假冒商品等）的商品。如果出现以下情况，将被视为航空公司或者海关不可接受的：国际航空运输协会（IATA）、国际民用航空组织（ICAO）、任何所适用法律国家的政府部门或其他相关组织划定为危险材料、危险物品、禁运或限运物品；所适用的海关规定要求申报而未申报；或者欧轩物流集运认为无法安全、合法地承运的物品。（这些物品包括但不限于：仿牌，液体，粉末，食品，药品，电池，原木，光盘，动物、金条、货币、不记名的可流通票据、贵金属和宝石、军火或武器弹药及零配件、人类尸体、色情材料、打火机和非法麻醉剂/毒品等）。您同意欧轩物流集运或者任何政府机关可以随时打开并查验您的货物。一旦在运输过程中发现此条款中的物品，欧轩物流集运将货物扣留或移送相关部门，并不再提供后续服务，您需要承担因此产生的一切直接或间接的经济和法律责任。</p>
                <p>2.您所提交货物的申报信息完全属实。如因您故意申报不实，有瞒报、谎报、虚报的行为（包括但不限于瞒报、谎报、虚报品名，如品名与实物不符、谎报货物数量、瞒报品名等；谎报价值，谎报虚假收件人和收件地址信息等），您将承担由此产生的海关惩罚性延迟清关，或高额逾期清关、退运、仓储费用，以及没收、退运、追究相关法律责任等后果，并承担因此产生的一切直接或间接的经济和法律责任。您清楚一旦出现上述情况欧轩物流集运将不再提供后续服务，并有权随时采取停止您的会员资格，或关停您的账户，拒绝为您再次提供服务等措施。欧轩物流集运对由于您错误、虚假申报造成的损失，不承担任何赔偿责任。</p>
                <p>3.您保证了解国际物流运输的风险性，并提供正确的收件信息或货物信息。您保证所提交运单单票重量符合物流公司为保证运输安全对包装所设定的限制，并承担因超出运输安全限重或合理限量造成货物查验、征税、退运期间产生的破损、丢失、罚没等后果，并承担因此产生的一切直接或间接的经济和法律责任。您保证提供准确的收件人地址，一旦提交运单后，将无法修改收件人地址，如地址错误，将承担因此产生的一切风险和费用。您保证提供给欧轩物流集运的单号或货物信息无误和无遗漏，并自愿承担单号不正确或者不全诰成的损失并承担因此产生的一切直接或间接的经济和法律责任。</p>
                <p>4.您了解并同意欧轩物流集运的免责和有限责任。您承诺已了解所描述的国际运输风险，并已充分了解，欧轩物流集运将不对非欧轩物流集运责任情况造成的遗失、损毁、延迟送达、误送、未送达、提供错误资料或未能提供资料负任何责任。</p>
                <p style="padding:2rem 0">以上内容，已阅读并自愿接受此条款。</p>
                <p style="padding:2rem 0">条款二《欧轩物流集运服务免责声明》</p>
                <p>第一条:关于货物安全</p>
                <p>欧轩物流集运为国内外广大用户提供国内仓储，国际货物转运服务，用户自行购买产品寄送至欧轩物流集运仓库，我们的工作人员会在视频监控下对货物进行入库，上架，验货，打包。欧轩物流集运只负责将货品包装后运送到国外。欧轩物流集运承诺绝不出现任何形式的换货调货的事件。</p>
                <p>第二条:关于货物打包</p>
                <p>在提交订单的时候，如无特殊打包要求，欧轩物流集运默认客户选择欧轩物流集运安全省体积的专业打包方式，我司打包作业人员会采用专业水准的打包操作（多件货物合并整合、使用轻质牢固包材、拆除赘余包装等），在可保证货物运途安全（防水防撞防丢处理）的情况下，尽量帮助客户压缩货物体积减轻货物重量，帮助客户达到经济运输的目的。如您签收后发现包裹重量不符，请在24小时内拍照反馈给客服核实，确认多收运费，将协助退回。</p>
                <p>第三条:关于货物送达地址与货物签收</p>
                <p>用户在使用我们的服务时需自行核对货物寄送地址，货物发出后出现因地址错误无法签收，导致的货物丢失或者退回，所产生的一切费用及后果皆由用户自行承担.在签收的过程当中，发现商品外包装破损或者商品不齐全，请48小时内准备相关的证明材料（照片、单据等）及问题情况描述反馈给欧轩物流集运客服处理。</p>
                <p>第四条:关于海关报税</p>
                <p>货物出入境都要经过所在国海关，如果货物价值超过目的国免税范围，则可能被征收关税，具体可网上查询全球各国关税起征点或咨询欧轩物流集运客服。在产生关税的情况下，收货人需自行及时缴纳税款，如因清关问题导致货物退回或销毁，需客户自行承担后果。</p>
                <p>申报方式：</p>
                <p>一.自行申报</p>
                <p>用户在选用自行申报后，需如实填写货物的价值、类别。因用户自行申报导致货物退回或销毁，客户需自行承担后果。</p>
                <p>二.欧轩物流集运代理申报</p>
                <p>我公司工作人员可根据用户的产品代为填写货物申报，有效合理的为您避免或降低关税。但不能保证100%不被海关扣查，如被通知需要缴纳税费，由此产生的税费及相关后果需客户自行承担。</p>
                <p>第五条:关于包裹延误</p>
                <p>欧轩物流集运提供的国际运输线路，时效性皆由各国际运输公司提供的参考时间，不做为实际货物到达时间。官方给出的时间皆是各国的工作时间，国际快递受外界的因素影响会产生一定的延误如（货物抵达国假日、恶劣天气、或者罢工战争等一切不可抗因素）。如果长时间未收到货物，请及时与我们联系。</p>
                <p>第六条：关于国际运费及服务费</p>
                <p>1.用户寄送包裹到欧轩物流集运仓库，我们免费提供60天的仓储服务，超过按照每个包裹每天1RMB标准收取相关仓储费用。用户特此同意，若包裹因用户个人原因在集运仓超期滞留超过180个自然日，即视为用户作为包裹所有人授权欧轩物流集运将包裹销毁。自包裹入库之日起第181个自然日起，欧轩物流集运有权就上述包裹进行销毁且无需再通知用户或得到用户确认，用户同时放弃要求欧轩物流集运提供销毁证明的权利。</p>
                <p>2.国际运费均按照欧轩物流集运的运费价格表制定，因国际运输成本浮动，欧轩物流集运有权调整各国运费价格。如因国际运费价格出入有小范围出入，属正常价格调整。</p>
                <p>第七条:关于赔偿</p>
                <p>1.用户寄送货物到达欧轩物流集运，如确定是欧轩物流集运原因造成的库内遗失，用户可凭网上购买商品记录、或者产品票据证明货物价值，我们提供对应的金额赔偿。（仅限实际货物价值RMB2000以内，购买超出不予赔付）</p>
                <p>2.包裹寄送到欧轩物流集运仓库后，我们会储存在用户专属仓库内只限180天以内（以包裹到达欧轩物流集运仓库的时间为准），超出时间未办理寄送服务的， 欧轩物流集运不再对包裹承担任何责任。</p>
                <p>3.在签收前先检查货物外包装是否完好，箱子封口是否完好，重量是否有明显差别，如果外包装有异常，请及时拍照作为后期索赔凭证。</p>
                <p>4.发现货物破损、丢失等情况请准备好货物实际价值截图（仅限实际货物价值RMB2000以内，超出不予赔付），实物照片等信息，48小时内联系欧轩物流集运客服人员处理， 逾期欧轩物流集运有权拒绝提供后续赔偿。</p>
                <p>5. 货物的正常签收标志着转运全程的终结，正常签收48小时后客户反馈货物出现的任何情况，欧轩物流集运将不承担后续其他责任。</p>
                <p>6. 其他赔偿规范：</p>
                <ul>
                    <li>6.1：易碎品：无论选择加固与否，只保丢不保损，都只参与遗失索赔，不参与破损索赔。</li>
                    <li>6.2：危险品及违禁品不参加索赔。（详见禁止集运目录）</li>
                    <li>6.3：货物的自然损耗、本质缺陷、特性以及市价上升跌落等所引起的损失或费用。</li>
                    <li>6.4：客户货物交货至我司前，货物已存在的品质不良或数量短差。</li>
                    <li>6.5：货物在运输途中由于恶劣气候、雷电、海啸、地震、洪水自然灾害等自然灾害所造成的全部或部分损失。</li>
                </ul>
                <p>7. 欧轩物流集运转运契约条款及免责声明的内容，我公司有最终解释权，如发生纠纷，双方可以协商解决，协商不成的，
                任何一方可以向<span style="color:#333">市江干区人民法院提起诉讼，并同意适用中华人民共和国大陆地区的法律。</span></p> -->
      </div>
    </div>
    <messageDialog :content='messageContent' v-show="messageDialog"></messageDialog>

    <div class="footPart" v-show="!ShowChannelOne">
      <p>
        <span></span><br>
        <span></span>
      </p>
      <!-- <p @click="applayMergePack" :style="adressInfo == '' || iconSelect == false?'background:#ddd' : ''">修改信息</p>
      <p @click="applayMergePack" :style="adressInfo == '' || iconSelect == false?'background:#ddd' : ''">确认合包</p> -->
      <div class="btns">
        <mt-button size="small" @click="modifyInfo">修改信息</mt-button>
        <mt-button size="small" :disabled="adressInfo == '' " @click="applayMergePack">确认合包</mt-button>
      </div>
    </div>

    <div v-show="ShowChannelOne">
      <div class="footPart" v-if="adressInfo != ''">
        <p>
          <span>合计(￥)：</span>
          <span>{{orderCount != ''?(orderCount/100).toFixed(2) : ''}}</span>
        </p>
        <input @click="goPay" v-bind:disabled="disabled" :style="adressInfo == ''  ||goPayStatus?'background:#ddd' : ''" value="去支付" class="paybtn" />

      </div>

    </div>
  </div>
</template>

<script>
import messageDialog from '@/components/messageDialog.vue';
import successInfo from "@/components/successInfo";
import failInfo from "@/components/failInfo";
import selectChennalShow from '@/pages/selectChennal.vue';
export default {
  name: "question",
  data () {
    return {
      orderSn: '',
      // orderSnNew:'',
      weight: '',
      recipients: '',
      telphone: '',
      postCode: '',
      detailInfo: '',
      orderCount: '',
      modal: false,
      ShowChannelOne: true,
      pageNo: 1,
      pageSize: 10,
      totalPage: 0,
      addressList: [],
      addressId: '',
      orderSns: '',
      adressInfo: '',
      messageDialog: false,
      messageContent: '',
      iconSelect: false,
      country: '',
      province: '',
      city: '',
      channel: 0,
      addressStr: '',
      protoalModal: false,
      agreementDesc: '',
      addService: '1',
      vaServiceList: [''],//增值服务多选
      vaService: [],//增值服务
      insuredAmount: '',//保额
      insuredPremium: 0,//保费
      expressList: [],
      // expressCodeShow:'',
      expressCode: '',
      expressName: '',
      value: '',
      safeCost: '1',//保险费
      formula: '',//计算公式
      sumHeavy: 0,//重量
      serviceIds: '',
      sumPrice: 0,
      storeOneMoney: 0,
      serviceMoney: 0,
      promitConfirm: false,
      promitFailConfirm: false,
      goPayStatus: false,
      xuorderSN:""



    }
  },
  components: {
    successInfo,
    failInfo,
    messageDialog,
    selectChennalShow
  },
  created () {
    this.orderSns = this.$route.query.orderSns;
    this.addressId = this.$route.query.addressId;
    this.channel = this.$route.query.channel;
    this.contury = this.$route.query.contury;
    this.configSelect();
    if (this.channel == 1) {
      this.ShowChannelOne = false;
    }
    console.log('------channel-------' + this.channel)
  },
  mounted () {
    window.addEventListener('scroll', this.onScroll);
  },
  methods: {
    getSelectAddressId: function (param) {
      // childValue就是子组件传过来的值
      this.expressCode = param[0];
      this.expressName = param[1];
      if (this.expressCode == '') {
        return
      }
      this.applayMergePackAndselectExpress();
      //this.selectExpressToCalculation(param[0]);
    },
    onScroll () {
      //可滚动容器的高度
      let innerHeight = document.querySelector('#app').clientHeight;
      //屏幕尺寸高度
      let outerHeight = document.documentElement.clientHeight;
      //可滚动容器超出当前窗口显示范围的高度
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop || window.pageYOffset;
      //scrollTop在页面为滚动时为0，开始滚动后，慢慢增加，滚动到页面底部时，出现innerHeight < (outerHeight + scrollTop)的情况，严格来讲，是接近底部。
      if (innerHeight <= (outerHeight + scrollTop)) {
        //加载更多操作
        if (this.pageNo <= this.totalPage && this.isEnd == true) {
          this.isEnd = false;
          this.getAddressList();
        }
      }
    },
    changeAddService: function (value) {
      if (value == 2) {
        this.serviceIds = '';//收费服务ids
      }
    },
    changeSafeCost (value) {
      if (value == 2) {
        this.insuredAmount = '';//保额
        this.insuredPremium = '';//保费
      }
    },
    protoalStatus () {
      if (this.iconSelect == false) {
        this.iconSelect = true;
      } else {
        this.iconSelect = false;
      }
    },
    getInsuredPremium (event) {
      this.insuredPremium = (this.premiumRate * event.target.value);
      this.expressCode = '';
      //this.expressCodeShow = '';
    },
    openProtoalModal () {
      this.protoalModal = true;//点击选中免责声明时
    },
    selectAddress (addressId) {
      this.addressId = addressId;
    },
    configSelect () {
      if (this.addressId != '') {
        var that = this;
        var url = '/api/h5/virtualPackOrderNew';//创建虚拟订单
        var data = {
          channel: 0,
          orderSns: this.orderSns,
          orderSn: '',
          addressId: this.addressId
        }
        this.$post(url, data).then(res => {
          console.log(res)
          if (res.resultCode == 10000) {
            let model = res.result.model;
            this.modal = false;
            this.xuorderSN = model.orderSn
            this.adressInfo = model.address;
            this.orderSn = model.orderSn;
            this.weight = model.weight;
            this.recipients = model.recipients;
            this.telphone = model.telphone;
            this.postCode = model.postCode;
            this.country = model.country;
            this.province = model.province;
            this.city = model.city;
            this.addressStr = model.address;
            if (this.addressStr.length > 40) {
              this.addressStr = this.addressStr.substring(0, 40) + '...'
            }
            this.toVaServiceList();
            this.selectAllowExpressList(model.weight);
            this.selectSystemParams();
            //this.selectAllowExpressList(model.weight);
            //this.selectSystemParams();
          }
          if (res.resultCode == 70009) {
            this.messageContent = '订单已结算';
            this.messageDialog = true;
            var that = this;
            setTimeout(function () {
              that.messageDialog = false;
            }, 1500)
          }
          if (res.resultCode == 70020) {
            this.messageContent = res.reason;
            this.messageDialog = true;
            var that1 = this;
            setTimeout(function () {
              that1.messageDialog = false;
              that1.$router.go(-1);
            }, 1500)
          }
        })
      }
    },
    applayMergePack () {
      /* if (this.iconSelect == false) {
        this.messageContent = '请仔细阅读条款和声明';
        this.messageDialog = true;
        var that = this;
        setTimeout(function () {
          that.messageDialog = false;
        }, 1500)
        return false;
      } *///提示阅读协议

      var url = '/api/h5/applayMergePack';//合并订单
      if (this.serviceIds instanceof Array) {
        this.serviceIds = this.serviceIds.join(',');
      }

      if (this.insuredAmount == '') {
        this.insuredAmount = 0;
        this.insuredPremium = 0;
      }

      var data = {
        channel: this.channel,
        orderSns: this.orderSns,
        orderSn: '',
        addressId: this.addressId,
        insuredAmount: (this.insuredAmount * 100).toFixed(0),
        insuredPremium: (this.insuredPremium).toFixed(0),
        serviceIds: this.serviceIds
      }
      this.$post(url, data).then(res => {
        console.log(res.resultCode)
        console.log(res.resultCode == 10000)
        if (res.resultCode == 10000) {
          this.promitConfirm = true;
        } else {
          this.promitFailConfirm = true;
        }
      })
    },
    goAddAdress () {
      this.$router.push({
        path: '/addressedit/0'
      })
    },
    goEditAdress (addressId) {
      this.$router.push({
        path: '/addressedit/' + addressId
      })
    },
    closeModal () {
      if (this.modal == true) {
        this.modal = false;
        this.addressId = '';
      }
      if (this.protoalModal == true) {
        this.protoalModal = false;
      }
    },
    messageContentShow (msg) {
      this.messageContent = msg;
      this.messageDialog = true;
      var that = this;
      setTimeout(function () {
        that.messageDialog = false;
      }, 1500)
    },
    goPay () {
      if (this.expressCode == '') {
        this.messageContentShow('请选择出货渠道');
        return false;
      }
      if (this.iconSelect == false) {
        this.messageContentShow('请仔细阅读条款和声明');
        return false;
      }
      var that = this;
      var url = '/api/h5/packOrder';

      if (this.serviceIds instanceof Array) {
        this.serviceIds = this.serviceIds.join(',');
      }

      var data = {
        // orderSn:this.orderSnNew,
        addressId: this.addressId,
        orderSns: this.orderSns,
        orderSn: '',
        expressCode: this.expressCode,
        expressName: this.expressName,
        insuredAmount: (this.insuredAmount * 100).toFixed(0),
        insuredPremium: (this.insuredPremium).toFixed(0),
        serviceIds: this.serviceIds,
        serviceMoney: this.serviceMoney,
        orderFixedPrice: this.sumPrice
      }
      this.goPayStatus = true;
      if (this.goPayStatus == true) {
        this.disabled = true
        console.log("调用打包");
        this.$post(url, data).then(res => {
          if (res.resultCode == 10000) {
            this.$router.push({
              name: 'waitpayment'
            })
          }
        })
      }

    },
    toVaServiceList () {//查询增值服务列表
      var url = '/api/h5/vaServiceList';
      var data = {
        pageNo: 1,
        pageSize: 1000
      }
      this.$post(url, data).then(res => {
        this.vaServiceList = res.result.vaServiceList;
      })
    },
    selectAllowExpressList (minWeight) {//根据国家和限重获取可使用的渠道列表
      var url = '/api/platExpress/selectAllowExpressList';
      var data = {
        country: this.contury,
        minWeight: minWeight,
        orderSn: this.orderSn
      }
      let that = this;
      this.$post(url, data).then(res => {
        that.expressList = res.result.expressList;
      })
    },

    selectSystemParams () {//查询查询参数
      var url = '/api/h5/selectSystemParams';
      var data = {}
      this.$post(url, data).then(res => {
        console.log(res.result.systemParams)
        this.agreementDesc = res.result.systemParams.agreementDesc;
        this.premiumRate = res.result.systemParams.premiumRate;

      })
    },

    applayMergePackAndselectExpress () {//选择出货渠道后返回计算结果
      var url = '/api/h5/applayMergePackAndselectExpress';
      if (this.serviceIds instanceof Array) {
        this.serviceIds = this.serviceIds.join(',');
      }

      if (this.insuredAmount == '') {
        this.insuredAmount = 0;
        this.insuredPremium = 0;
      }

      var data = {
        orderSns: this.orderSns,
        // orderSn:this.orderSnNew,
        expressCode: this.expressCode,
        addressId: this.addressId,
        channel: this.channel,
        insuredAmount: (this.insuredAmount * 100).toFixed(0),
        insuredPremium: (this.insuredPremium).toFixed(0),
        serviceIds: this.serviceIds
      }
      //                let that = this;
      this.$post(url, data).then(res => {
        this.formula = res.result.formula;
        this.sumHeavy = res.result.sumHeavy;
        this.sumPrice = res.result.sumPrice;
        this.storeOneMoney = res.result.storeOneMoney;
        this.serviceMoney = res.result.serviceMoney;
        this.orderCount = this.sumPrice + this.storeOneMoney + this.serviceMoney + this.insuredPremium;
        this.expressCode = this.expressCode;
        this.expressName = this.expressName;

      })
    },
    toChangeVaServiceList (e) {
      this.serviceIds = e;
      this.expressCode = '';
      //this.expressCodeShow = '';
    },
    closePromit () {
      this.$router.push({
        name: 'waitpacked'
      })
    },
    closeFailPromit () {
      this.promitFailConfirm = false;
    },
    //点击修改信息时在此处修改信息
    modifyInfo () {
      //this.$router.back(-1)
      this.$router.push({
        path: "/addressEdit/" + this.addressId
      })


      let query = {


        adressInfo:this.adressInfo,
        xuorderSN:this.xuorderSN,
        weight:this.weight,
        recipients:this.recipients,
        telphone:this.telphone,
        postCode:this.postCode,
        country:this.country,
        province:this.province,
        city:this.city,
        addressStr:this.addressStr,
        channel:this.channel,

      }

      this.$router.push({
        name: 'addressEditOrder',
        query: query,
      });

    }
  }
}
</script>

<style lang="scss" scoped>
.configOrder {
  width: 100%;
  min-height: 100%;
  background: #f6f6f6;
  padding-bottom: 10rem;
  .adress {
    padding-left: 3rem;
    background: #fff;
    p {
      padding: 2.5rem 0;
      text-align: left;
    }
    p:nth-child(1) {
      border-bottom: 2px solid #eee;
      color: #333;
      font-size: 3rem;
    }
    p:nth-child(2) {
      color: #999;
      font-size: 2.5rem;
      position: relative;
      padding-right: 6rem;
      .arrow {
        position: absolute;
        top: 50%;
        left: calc(100% - 4.5rem);
        transform: translate(0, -50%);
        width: 1.5rem;
      }
    }
  }
  .orderInfo {
    background: #fff;
    margin-top: 1rem;
    // padding-left:3rem;
    margin-left: 1rem;
    // margin-right: 1rem;
    li {
      text-align: left;
      // padding:2.5rem 3rem 2.5rem 0;
      padding: 1rem 1rem 1rem 0;
      font-size: 2.5rem;
      //display: flow-root!important;
      display: flex;
      justify-content: space-between;
      border-bottom: 2px solid #eeeeee;
      color: #333;
      .noInfo {
        color: #999;
      }
    }
  }
  .protoal {
    display: flex;
    align-items: center;
    padding: 3rem;
    span {
      width: 3rem;
      height: 3rem;
      border: 1px solid #b5b5b5;
      border-radius: 50%;
      display: inline-block;
      margin-right: 2rem;
      img {
        width: 100%;
      }
    }
    p {
      font-size: 2.5rem;
      color: #057eed;
    }
  }
  .footPart {
    font-size: 3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0rem 3rem;
    background: #fff;
    position: fixed;
    bottom: 0;
    width: 100%;
    p:nth-child(1) {
      padding: 3rem 0;
      span:nth-child(1) {
        color: #333;
      }
      span:nth-child(2) {
        color: #ef3c4d;
      }
    }
    p:nth-child(2) {
      padding: 1rem 2rem;
      background: #ef3c4d;
      line-height: 1;
      color: #fff;
      border-radius: 10px;
    }

    .btns {
      button + button {
        margin-left: 10px;
      }

      button:not([disabled]) {
        background-color: #ef3c4d;
        color: white;
      }
    }
  }
  .modal {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    top: 0;
    left: 0;
    .modalView {
      background: #fff;
      height: 60%;
      width: 100%;
      position: fixed;
      bottom: 0;
      overflow: auto;
      padding-bottom: 120px;
      padding-top: 145px;
      .title {
        position: relative;
        padding: 3rem 0;
        border-bottom: 2px solid #eee;
        position: fixed;
        width: 100%;
        top: 40%;
        left: 0;
        background: #fff;
        z-index: 100;
        span:nth-child(1) {
          color: #333;
          font-size: 3rem;
        }
        span:nth-child(2) {
          color: #ef3c4d;
          font-size: 3rem;
          position: absolute;
          top: 50%;
          left: 1rem;
          transform: translate(0, -50%);
          padding: 2rem;
        }
        span:nth-child(3) {
          color: #ef3c4d;
          font-size: 3rem;
          position: absolute;
          top: 50%;
          left: calc(100% - 10rem);
          transform: translate(0, -50%);
          padding: 2rem;
        }
      }
      ul {
        padding-left: 8rem;
        li {
          padding: 2rem 0;
          text-align: left;
          font-size: 3rem;
          color: #333;
          border-bottom: 2px solid #eee;
          position: relative;
          p {
            height: 3.5rem;
            line-height: 3.5rem;
          }
          p:nth-child(2) {
            margin-top: 2rem;
          }
          .selectImg {
            position: absolute;
            width: 4rem;
            height: 4rem;
            top: 50%;
            left: -6rem;
            transform: translate(0, -50%);
          }
          .startBorder {
            position: absolute;
            width: 4rem;
            height: 4rem;
            top: 50%;
            left: -6rem;
            transform: translate(0, -50%);
            border: 2px solid #eee;
            border-radius: 50%;
          }
          .editImg {
            position: absolute;
            width: 4rem;
            height: 4rem;
            top: 50%;
            left: calc(100% - 8rem);
            transform: translate(0, -50%);
          }
        }
      }
      .footBtn {
        position: relative;
        padding: 2rem 0;
        border-top: 2px solid #eee;
        color: #333;
        font-size: 3rem;
        position: fixed;
        bottom: 0;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #fff;
        img {
          width: 3rem;
          height: 3rem;
          margin-right: 1rem;
        }
      }
    }
  }
  .protoalModal {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    top: 0;
    left: 0;
    .modalView {
      background: #fff;
      height: 80%;
      width: 90%;
      overflow: auto;
      position: absolute;
      top: 10%;
      left: 50%;
      transform: translate(-50%, 0);
      border-radius: 10px;
      text-align: left;
      padding: 3rem;
      font-size: 2rem;
    }
  }
  .insured {
    display: inline-block !important;
    width: 100%;
    .insuredSpan {
      display: flex;
      width: 100%;
      span {
        width: 20rem !important;
        line-height: 4.5rem;
      }
    }
  }
  .detaileTxt {
    text-align: left;
    padding: 0 3rem 1rem 0;
  }
  .detaileBox {
    border: 2px solid #eeeeee;
    padding: 1rem 1.5rem 1rem 1.5rem;
  }
  .detaileList {
    line-height: 4.5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
</style>

