<template>
    <div class="customer">
        <emptyInfo v-show="empty"></emptyInfo>
        <div v-show="!empty && userCustomer != ''">
            <p class="title">客服资料</p>
            <div class="item">
                <p>客服：{{userCustomer.customerName}}</p>
                <p>电话：{{userCustomer.customerPhone}}</p>
                <p>固话：{{userCustomer.customerFixPhone}}</p>
                <p>微信：{{userCustomer.customerWx}}</p>
                <p>邮箱：{{userCustomer.customerEmail}}</p>
                <p>办公时间：{{userCustomer.officeTime}}</p>
            </div>
            <div class="qrcode">
                <p>
                    <img :src="userCustomer.qrCode">
                </p>
                <p>长按识别二维码联系专属客服</p>
            </div>
        </div>
    </div>
</template>

<script>
import emptyInfo from '@/components/emptyInfo.vue';
import QRCode from 'qrcodejs2';
export default {
    name: "customer",
    data(){
        return{
            empty:false,
            userCustomer:''
        }
    },
    components:{
        emptyInfo
    },
    created(){
        this.getServeInfo();
    },
    methods:{
        getServeInfo(){
            var that = this;
            var url = '/api/h5/userCustomer';
            var data = {}
            this.$post(url,data).then(res=>{
                if(res.resultCode == 10000){
                    this.userCustomer = res.result.userCustomer;
                    this.qrcode();
                }else{
                    this.empty = true;
                }
            })
        },
        qrcode(){  
            let qrcode = new QRCode('qrcode', {  
            width: 350,  
            height: 350,
            text: this.userCustomer.customerWx // 二维码内容  
            // render: 'canvas' // 设置渲染方式（有两种方式 table和canvas，默认是canvas）  
            // background: '#f0f'  
            // foreground: '#ff0'  
            })   
        }
    }
}
</script>

<style lang="scss" scoped>
.customer{
    background: #fff;
    height:100%;
    width:100%;
    padding-left:6%;
    .title{
        text-align: left;
        color:#333333;
        font-size:3rem ;
        padding:3rem 0;
        border-bottom:1px solid #E5E5E5;
        font-weight: 500;
    }
    .item{
        text-align: left;
        color:#333333;
        font-size: 2.5rem;
        line-height: 5rem;
        margin:3rem 0 ;
        font-weight: 500;
    }
    .qrcode{
        margin:15em 0;
        display:-webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
        justify-content: center;
        -moz-box-pack: space-between;
        -webkit--moz-box-pack: center;
        box-pack: center;
        align-items: center;
        -webkit-align-items: center;
        box-align: center;
        -moz-box-align: center;
        -webkit-box-align: center;
        -webkit-flex-flow: column wrap;
        -webkit-box-orient: horizontal;
        -webkit-box-lines: multiple;
        -moz-flex-flow: column wrap;
        box-orient: horizontal;
        box-lines: multiple;
        flex-flow: column wrap;
        padding-right:6%;
        p:nth-child(1){
            width:350px;
            height:350px;
            background: #eee;
            img{
                width:100%;
                height:100%;
            }
        }
        p:nth-child(2){
            color:#999999;
            font-size: 2.2rem;
            padding:2rem 0;
        }
    }
}
</style>