<template>
    <div class="messageDialog">
        {{content}}
    </div>
</template>

<script>
export default {
    name: "messageDialog",
    props:['content']
}
</script>

<style lang="scss" scoped>
.messageDialog{
    text-align: center;
    position:fixed;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    color:#fff;
    background:rgba(127,127,127,.9);
    font-size:3.2rem;
    width:65%;
    padding:3rem 6rem;
    border-radius: 90px;
    z-index: 10000;
}
</style>