<template>
    <div class="footbar">
        <ul>
            <li v-for="(item ,index) of footList" :key="index" @click="footBarClick(index,item.route)">
               <img :src="index==select?item.iconSelect:item.icon">
                <p>{{item.name}}</p>
            </li>
        </ul>
    </div>
</template>

<script>
    export default {
        name: "footBar",
        data(){
            return{
                select:0,
                footList:[
                    {
                        name:'首页',
                        icon:require("@/assets/images/home.png"),
                        iconSelect:require("@/assets/images/home_selected.png"),
                        route:'index'
                    },
                    {
                        name:'查询',
                        icon:require("@/assets/images/find.png"),
                        iconSelect:require("@/assets/images/find_selected.png"),
                        route:'mailSearch'
                    },
                    {
                        name:'评论',
                        icon:require("@/assets/images/evaluate.png"),
                        iconSelect:require("@/assets/images/evaluate_selected.png"),
                        route:'commentlist'
                    },
                    {
                        name:'我的',
                        icon:require("@/assets/images/mine.png"),
                        iconSelect:require("@/assets/images/mine_selected.png"),
                        route:'center'
                    },

                ]
            }
        },
        props:{
            pageIndex:Number
        },
        created(){
            this.select = this.pageIndex;
        },
        methods:{
            footBarClick( idx,router){
                this.select=idx
                this.$router.push({ 
                    name:router
                })
            }
        }

    }
</script>

<style lang="scss">
.footbar{
    background: #fff;
  ul{
      padding:1rem 6%;
      display:-webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: space-between;
      justify-content: space-between;
      -moz-box-pack: space-between;
      -webkit--moz-box-pack: space-between;
      box-pack: space-between;
      align-items: center;
      -webkit-align-items: center;
      box-align: center;
      -moz-box-align: center;
      -webkit-box-align: center;
      img{
          width:4rem;
      }
      p{
          color:#333;
          font-size: 2rem;
      }
  }
}
</style>