<template>
    <div class="context">
        <div class="item">
            <div class="main">
                <div class="title">
                    <img src="../assets/images/prod_ico.png" />
                    <span>{{ data.productName }}</span>
                </div>
                <div class="sub">
                    <span>预估时效:{{ data.limitation }}</span>
                </div>
            </div>
            <div class="minor">
                <span class="text">预估运费</span>
                <div class="price">
                    <span class="symbol">¥</span>
                    <span class="value">{{ data.sumPrice }}</span>
                </div>
            </div>
            <div class="go">
                <span>&gt;</span>
            </div>
        </div>
        <div class="main">
            <div class="specs">
                <div class="text">
                    <span class="name">国家/地区：</span>
                    <span class="value">{{ data.country }}</span>
                </div>
                <div class="text">
                    <span class="name">限重：</span>
                    <span class="value">{{ data.limitWeight }}</span>
                </div>
                <div class="text">
                    <span class="name">重量：</span>
                    <span class="value">{{ data.transWeight }} (Kg)</span>
                </div>
                <div class="text">
                    <span class="name">尺寸：</span>
                    <span class="value"
                        >{{
                            `${data.length} * ${data.width} * ${data.height}`
                        }}(cm)</span
                    >
                </div>
            </div>
            <div class="table">
                <table>
                    <tr class="head">
                        <td>计算方式</td>
                        <td>重量 <br />(kg)</td>
                        <td>首重费用 <br />(元)</td>
                        <td>续重费用 <br />(元)</td>
                        <td>每kg计算<br />(元)</td>
                    </tr>
                    <tr class="data">
                        <td>
                            {{
                                data.weightFlag === 1 ? "实际重量" : "体积重量"
                            }}
                        </td>
                        <td>{{ data.transWeight }}</td>
                        <td>{{ data.firstHeavyPrice }}</td>
                        <td>{{ data.continuedHeavyPrice }}</td>
                        <td>{{ data.perKiloPrice }}</td>
                    </tr>
                </table>
            </div>
            <div class="recommend">渠道说明:{{ data.remark }}</div>
        </div>

        <div class="info">
            <span class="title">说明:</span>
            <ul>
                <li>
                    快递包裹体积重量计算方法:长度(cm)*宽度(cm)*高度(cm)/体积系数=体积重kg
                </li>
                <li>
                    运费估算仅供参考,价格实时变动,如有疑问请联系在线客服咨询
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                data: {},
            };
        },
        created(a, b) {
            this.data = this.$route.params;
        },
    };
</script>

<style lang="scss" scoped>
    .context {
        background-color: rgb(218, 218, 218);
        width: 100%;
        font-size: 14px;
    }

    .item {
        background-color: white;
        border-radius: 3px;
        display: flex;
        padding: 10px;

        .main {
            text-align: left;
            flex-grow: 2;
            .title {
                color: black;
                font-size: 16px;
                font-weight: bold;
                margin-bottom: 10px;

                > * {
                    vertical-align: middle;
                }

                span {
                    margin-left: 5px;
                }
            }

            img {
                width: 20px;
            }

            .sub {
                font-size: 14px;
            }
        }

        .minor {
            flex-grow: 1;
            font-size: 12px;

            .text {
                display: block;
                margin-bottom: 8px;
            }

            .price {
                font-size: 16px;
                color: #0a7dde;
            }
        }

        + .item {
            margin-top: 10px;
        }
    }

    .go {
        display: flex;
        justify-content: center;
        flex-direction: column;

        span {
            font-size: 14px;
            font-family: monospace;
            font-weight: 700;
        }
    }

    .context > .main {
        text-align: left;
        background-color: white;
        margin-top: 10px;
        padding: 10px;

        .specs {
            font-size: 14px;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            > div {
                width: 50%;
                box-sizing: border-box;
                margin: 5px 0;
            }
        }
    }

    .table {
        text-align: center;
        margin: 15px auto;
        table {
            border-collapse: collapse;
            width: 100%;
            font-size: 12px;
            font-weight: 100;
        }
        .head {
            td {
                background-color: #0a7dde;
                color: white;
            }
        }

        th,
        td {
            border: 1px solid gray;
        }

        .data {
            td {
                padding: 8px 0;
            }
        }
    }

    .recommend {
        font-size: 14px;
        color: #0a7dde;
    }

    .info {
        text-align: left;
        margin-top: 10px;
        padding: 10px;
        background-color: white;

        li {
            list-style: decimal;
            margin-left: 15px;
            line-height: 25px;
        }
    }
</style>
