<template>
    <div class="qrcode">
        <p>
            <img src="@/assets/images/logo_big.png">
        </p>
        <p>
            <img src="@/assets/images/qrcode.png">
            <span>长按识别二维码</span>
        </p>
        <div>
            <img src="@/assets/images/qrcode_bg.png">
            <div class="content">
                <p>欧轩物流集运</p>
                <p>客户放心、交付放心的跨境物流服务放心平台 </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "qrcode",
    data(){
        return{

        }
    },
    methods:{
    }
}
</script>

<style lang="scss" scoped>
.qrcode{
    width:100%;
    height:100%;
    overflow: hidden;
    p{
        text-align: center;
    }
    p:nth-child(1){
        margin-top:20%;
        img{
            width:70%;
        }
    }
    p:nth-child(2){
        margin-top:8%;
        img{
            width:60%;
        }
        span{
            display: block;
            font-size:3rem;
        }
    }
    div:nth-child(3){
        position:relative;
        margin-top:-15%;
        img{
            width:100%;
        }
        .content{
            position:fixed;
            bottom:150px;
            left:50%;
            transform: translate(-50%,0);
            width:100%;
            p:nth-child(1){
                font-size:4rem;
                color:#333;
                margin-top:0;
                font-weight: 500;
            }
            p:nth-child(2){
                font-size:2.5rem;
                color:#333;
                margin-top:20px;
            }
        }
    }
}
</style>
