<template>
	<div class="mailSearch">
		<emptyInfo v-show="empty"></emptyInfo>
		<div class="mainContent">
			<div class="pageContent">
				<ul class="typeList">
					<li
						:class="type == index ? 'active' : ''"
						v-for="(item, index) in typeList"
						:key="index"
						@click="typeChoose(index)"
					>
						<span>{{ item.type }}</span>
					</li>
				</ul>
				<div class="inputView">
					<input
						type="text"
						v-model="orderSn"
						placeholder="添加物流单号"
						@focus="openKeyBoard"
						@blur="closeKeyBoard"
					/>
					<img
						src="@/assets/images/search_orange.png"
						class="search"
						@click="search"
					/>
				</div>
			</div>
		</div>
		<foot-bar :pageIndex="1" v-show="footShow"></foot-bar>
		<messageDialog content="请填写物流单号" v-show="promit"></messageDialog>
	</div>
</template>

<script>
	import emptyInfo from "@/components/emptyInfo.vue";
	import FootBar from "../components/footBar";
	import messageDialog from "@/components/messageDialog.vue";
	export default {
		name: "mailSearch",
		data() {
			return {
				empty: false,
				footShow: true,
				type: 0, //查询类别
				orderSn: "", //快递单号
				typeList: [{ type: "入库包裹查询" }, { type: "出库包裹查询" }],
				promit: false,
			};
		},
		components: {
			messageDialog,
			emptyInfo,
			FootBar,
		},
		mounted() {
			let that = this;
			let oriWinHeight = window.innerHeight;
			window.onresize = function () {
				that.isKeybordAvail = true;
				let newHeight = window.innerHeight;
				// 阀值大于140判断为键盘收起
				if (newHeight - oriWinHeight > 140) {
					that.footShow = true;
				} else {
					that.footShow = false;
				}
				oriWinHeight = newHeight;
			};
		},
		methods: {
			typeChoose(index) {
				this.type = index;
			},
			search() {
				if (this.orderSn == "") {
					var that = this;
					that.promit = true;
					setTimeout(function () {
						that.promit = false;
					}, 1500);
					return false;
				}
				var type = this.type + 1;
				this.$router.push({
					path: "/process/" + type + "/" + this.orderSn,
				});
			},
			openKeyBoard() {
				this.footShow = false;
			},
			closeKeyBoard() {
				var that = this;
				setTimeout(function () {
					that.footShow = true;
				}, 500);
			},
		},
	};
</script>

<style lang="scss">
	.mailSearch {
		width: 100%;
		height: 100%;
		background: #fff;
		.footbar {
			position: fixed;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: 100;
		}
		.mainContent {
			width: 100%;
			.title {
				font-size: 3rem;
				padding: 2rem 0;
			}
			.typeList {
				display: flex;
				justify-content: space-between;
				padding: 10px 0;
				border-bottom: 2px solid #eeeeee;
				li {
					position: relative;
					width: 50%;
					span {
						color: #333;
						font-size: 2.6rem;
					}
					.line {
						position: absolute;
						left: 50%;
						top: calc(100% + 3rem);
						transform: translate(-50%, 0);
						width: 100%;
						height: 4px;
					}
				}
				.active {
					position: relative;
					span {
						color: #ef3c4d !important;
					}

					&::before {
						content: "";
						display: block;
						position: absolute;
						width: 100%;
						border-top: 2px solid #ef3c4d;
						bottom: -13px;
					}
				}
				li:nth-child(1):after {
					content: "";
					width: 1px;
					height: 20px;
					position: absolute;
					left: 100%;
					top: 50%;
					transform: translate(0, -50%);
					background: #999;
				}
			}
			.inputView {
				padding: 5rem 3rem;
				position: relative;
				input {
					background: #f9f9f9;
					border: 2px solid #dcdcdc;
					width: 100%;
					border-radius: 1.2rem;
					height: 140px;
					// padding-left: 3rem;
					padding: 20px !important;
				}
				input[placeholder] {
					font-size: 2.8rem;
					color: #999;
				}
				.search {
					width: 4rem;
					height: 4rem;
					position: absolute;
					top: 50%;
					left: calc(100% - 10rem);
					transform: translate(0, -50%);
				}
			}
		}
	}
</style>
