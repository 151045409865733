<template>
    <div class="wrap">
        <headerTitle title='我的优惠券'> <span class="loseEfficacy" @click="clearBtn">清除</span> </headerTitle>
        <div class="couponListBox">
            <template v-if="baseList.length">
            <div class="couponList" v-for="(item,index) of baseList" :key="index">
                <div class="couponItem">
                    <div class="f3 colW">
                        <span class="f4">{{item.couamount}}</span>{{item.type == '现金券' ? '元' : '折'}}
                        <span>{{item.userlimit}}优惠券</span>
                    </div>
                    <div class="f3 colW">有效期至：{{(new Date(item.periodTime)).Format("yyyy-MM-dd")}}</div>
                </div>
                <div class="useBtn" >{{item.status == '1' ? '已使用' : '已过期'}}</div>
            </div>
            </template>
            <emptyInfo class="emptyInfodiv" v-else content='暂无优惠券'></emptyInfo>
        </div>
        <div>
            <exchangeCom></exchangeCom>
        </div>
    </div>
</template>

<script>
    import  headerTitle  from '../components/headerTitle'
    import exchangeCom from './exchangeCom/index'
    import emptyInfo from '@/components/emptyInfo.vue';

    export default {
        name: "couponList",
        components:{
            headerTitle,
            exchangeCom,
            emptyInfo
        },
        data(){
            return {
                baseList: [],
                userId:localStorage.getItem('userId'),
            }
        },
        mounted() {
            this.baseFun()
        },
        methods:{
            clearBtn() {
                this.$post('/api/h5/deleteUsreCoupons', { userId: this.userId }).then(res=>{
                    if(res.resultCode == 10000){
                        alert('清除成功！');
                        this.baseFun()
                    }
                })
            },
            baseFun(){
                 this.$post('/api/h5/querryPersonsCouponsInfo', { userId: this.userId }).then(res=>{
                    if(res.resultCode == 10000){
                        this.baseList = res.result.filter(item=>{
                            return item.status == '2' || item.status == '1' && item
                        })
                    }
                })
            },
            goTransport(path){
                this.$router.push({
                    path:path,
                    query:{
                        channel:0
                    }
                })
            },
        }
    }
</script>

<style scoped lang="scss">

    .wrap{
        background: #F1EFF2;
        height: 100%;
    }
    .couponListBox{
        margin: 3rem 2rem;
        .couponList{
            background: url("../assets/images/quan-bg-3.png");
            width: 100%;
            background-repeat: no-repeat;
            background-size: 100% 100%;
            /*height: 15rem;*/
            display: flex;
            justify-content: space-between;
            align-items: center;
            box-sizing: border-box;
            padding: 3rem;
            margin-bottom: 2rem;
        }
    }
    .useBtn{
        font-size: 2.5rem;
        background: #fff;
        width: 14rem;
        height: 4rem;
        line-height: 4rem;
        border-radius: 2rem;
        color: #B0AEB0;
    }
    .activeCol{
        color: #FF8F21;
    }
    .colW{
        color: #fff;
    }
    .f2{
        font-size: 2rem;
    }
    .f3{
        font-size: 3rem;
    }
    .f4{
        font-size: 4rem;
    }
    .loseEfficacy{
        color: #fff;
        font-size: 2rem;
    }
    .emptyInfodiv{
      height: 40rem;
      position: relative;
    }


</style>
