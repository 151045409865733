
<template>
	<div id="app">
		<router-view></router-view>
	</div>

</template>

<script>
	export default {
		name: "app",
		data () {
			return {};
		},
		components: {},
	};
</script>

<style>
	#app {
		font-family: 'PingFang-SC-Medium';
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-align: center;
		color: #666;
		/*height: 100%;*/
	}
	/*单选*/
	.el-radio__inner {
		width: 2rem !important;
		height: 2rem !important;
	}
	.el-radio__label {
		font-size: 2.5rem !important;
	}
	.el-radio__input {
		vertical-align: baseline !important;
	}
	.el-radio__inner::after {
		width: 0.7rem !important;
		height: 0.7rem !important;
	}
	/*多选*/
	.el-checkbox-group {
		display: flex;
		flex-direction: column;
		font-size: 2.5rem;
	}
	.el-checkbox__inner {
		width: 2.5rem !important;
		height: 2.5rem !important;
	}
	.el-checkbox__label {
		font-size: 2.5rem !important;
		line-height: 16px !important;
	}
	.el-checkbox {
		display: flex !important;
		margin-top: 2rem;
	}
	.el-checkbox__input.is-checked .el-checkbox__inner::after {
		-webkit-box-sizing: content-box;
		box-sizing: content-box;
		content: '';
		border: 3px solid #fff;
		border-left: 0;
		border-top: 0;
		height: 1rem;
		left: 0.8rem;
		position: absolute;
		top: 0.3rem;
		-webkit-transform: rotate(45deg) scaleY(1);
		transform: rotate(45deg) scaleY(1);
		width: 0.5rem;
		-webkit-transition: -webkit-transform 0.15s ease-in 0.05s;
		transition: -webkit-transform 0.15s ease-in 0.05s;
		transition: transform 0.15s ease-in 0.05s;
		transition: transform 0.15s ease-in 0.05s,
			-webkit-transform 0.15s ease-in 0.05s;
		transition: transform 0.15s ease-in 0.05s,
			-webkit-transform 0.15s ease-in 0.05s;
		-webkit-transform-origin: center;
		transform-origin: center;
	}
	/*input*/
	.el-input__inner {
		border: 2px solid #dcdfe6 !important;
		font-size: 2.5rem !important;
		height: 5rem !important;
		line-height: 5rem !important;
	}
	.el-select .el-input .el-select__caret {
		font-size: 2.5rem !important;
	}
	.el-select {
		width: 100% !important;
	}
	/*下拉*/
	.el-input__icon {
		width: 3rem !important;
	}
	.el-select-dropdown__item {
		font-size: 2.5rem !important;
		height: 4rem !important;
		line-height: 4rem !important;
	}
</style>
