<!--
 * @Author: kangfei
 * @Date: 2021-02-05 09:57:17
 * @LastEditTime: 2021-02-05 13:37:31
 * @FilePath: \src\components\headerTitle.vue
 * @Description:
-->
<template>
  <div>
     <div class="headerBox">
        <div class="arrow" @click="goBack">
          <i class="el-icon-arrow-left"></i>
        </div>
        <div class="title">{{title}}</div>
        <div class="rightBox">
          <slot></slot>
        </div>
     </div>
  </div>
</template>

<script type="text/javascript">
export default {
  name: "app",
  props:{
    title:{
      type:String,
      default(){
        return ''
      }
    }
  },
  data() {
    return {

    }
  },
    methods:{
        goBack(){
            this.$router.go(-1)
        }
    }
}
</script>

<style scoped>
.headerBox{
  display: flex;
  justify-content: space-between;
  background: #ef3c4d;
  height: 7rem;
  line-height: 7rem;
  color: #fff;
}
.el-icon-arrow-left{
  font-size: 3rem;
  margin-left: 2rem;
}
.rightBox{
  margin-right: 2rem;
}
    .title{
        font-size: 3rem;
    }
    .arrow{
        display: flex;
        align-items: center;
    }
</style>
