<template>
    <div class="successInfo">
        <p class="lawyer"></p>
        <div class="content">
            <p @click="isKnow">
                <img src="@/assets/images/close_gray.png" class="closeIcon">
            </p>
            <img src="https://jcex-file-service.oss-cn-hangzhou.aliyuncs.com/jc-wms/confirmico.png" style="width: 8rem;height: 8rem;" class="pic">
            <p>{{content}}</p>
            <p @click="isKnow">我知道了</p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "successInfo",
        props:['content'],
        methods:{
            isKnow(){
                this.$emit('close-modal');
            }
        }
    }
</script>

<style lang="scss" scoped>
    .successInfo{
        .lawyer{
            width:100%;
            height:100%;
            position: fixed;
            top:0;
            left:0;
            right:0;
            bottom:0;
            // background:#4a5a631a;
            background: rgba(0,0,0,0.5);
            z-index:9;
        }
        .content{
            width:70%;
            position: absolute;
            top:50%;
            left:50%;
            transform: translate(-50%,-50%);
            z-index:10;
            background: #fff;
            border-radius: 2rem;
            text-align: center;
            p:nth-child(1){
                text-align: right;
                padding:2rem 6% 0 0;
                color:#BBBBBB;
                font-size: 3rem;
            }
            .closeIcon{
                width:32px;
                height:35px;
            }
            .pic{
                text-align: center;
                width:25rem;
                padding:1rem 0;
            }
            p:nth-child(3){
                color:#333;
                font-size: 2.8rem;
                text-align: center;
                padding:3rem;
            }
            p:nth-child(4){
                color:#fff;
                font-size: 2.5rem;
                padding:2rem 3rem;
                border-radius: 4rem;
                background:#1CA1FE ;
                margin:0 auto 4rem auto;
                width:20rem;

            }
        }
    }
</style>
