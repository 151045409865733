import Vue from "vue";
import Router from "vue-router";
import axios from 'axios';
Vue.use(Router);
import index from '@/pages/index';
import payWay from '@/pages/payway';
import question from '@/pages/question';
import addService from '@/pages/addService';
import customer from '@/pages/customer';
import addressList from '@/pages/addressList';
import addressEdit from '@/pages/addressEdit';
import addressEditOrder from '@/pages/addressEditOrder';
import center from '@/pages/center';
import mailSearch from '@/pages/mailSearch';
import packageCheck from '@/pages/packageCheck';
import carriage from '@/pages/carriage';
import countResult from '@/pages/countResult';
import countDetail from '@/pages/countDetail';
import contraband from '@/pages/contraband';
import packageCN from '@/pages/packageCN';
import storage from '@/pages/storage';
import transport from '@/pages/transport';
import waitPayment from '@/pages/waitPayment';
import waitPacked from '@/pages/waitPacked';
import outStock from '@/pages/outStock';
import waitSigned from '@/pages/waitSigned';
import evaluate from '@/pages/evaluate';
import orderGuide from '@/pages/orderGuide';
import addOrder from '@/pages/addOrder';
import directMail from '@/pages/directMail'; // 单包直邮
import lostProperty from '@/pages/lostProperty'; // 无主认领
import process from '@/pages/process';
import myOrder from '@/pages/myOrder';
import addPhone from '@/pages/addPhone';
import configOrder from '@/pages/configOrder';
import commentList from '@/pages/commentList';
import qrCode from '@/pages/qrCode';
import orderDetail from '@/pages/orderDetail';
import myIntegral from '@/pages/myIntegral';
import withdrawDeposit from '@/pages/withdrawDeposit';
import withdrawDetail from '@/pages/withdrawDetail';
import integralDetail from '@/pages/integralDetail';
import integralRule from '@/pages/integralRule';
import inviteFriends from '@/pages/inviteFriends';
import couponList from '@/pages/couponList';
import loseEfficacyCoupon from '@/pages/loseEfficacyCoupon';
import paywayNew from '@/pages/paywayNew';
import paywayMy from '@/pages/paywayMy';
const routes = [
  {
    path: '*',
    redirect: '/'
  },
  {
    path: '/',
    redirect: '/index'
  },
  {
    path: '/myIntegral',
    name: 'myIntegral',
    component: myIntegral,
    meta: {
      title: '我的积分'
    }
  },
  {
    path: '/withdrawDeposit',
    name: 'withdrawDeposit',
    component: withdrawDeposit,
    meta: {
      title: '提现'
    }
  }, {
    path: '/loseEfficacyCoupon',
    name: 'loseEfficacyCoupon',
    component: loseEfficacyCoupon,
    meta: {
      title: '失效券'
    }
  },
  {
    path: '/withdrawDetail',
    name: 'withdrawDetail',
    component: withdrawDetail,
    meta: {
      title: '提现详情'
    }
  },
  {
    path: '/integralDetail',
    name: 'integralDetail',
    component: integralDetail,
    meta: {
      title: '积分明细'
    }
  }, {
    path: '/integralRule',
    name: 'integralRule',
    component: integralRule,
    meta: {
      title: '积分规则'
    }
  }, {
    path: '/inviteFriends',
    name: 'inviteFriends',
    component: inviteFriends,
    meta: {
      title: '邀请好友'
    }
  },
  {
    path: '/index',
    name: 'index',
    component: index,
    meta: {
      title: '首页'
    }
  },
  {
    path: '/payway/:id',
    name: 'payway',
    component: payWay,
    meta: {
      title: '收银台'
    }
  },{
    path: '/paywayNew/',
    name: 'paywayNew',
    component: paywayNew,
    meta: {
      title: '收银台'
    }
  },{
    path: '/paywayMy/',
    name: 'paywayMy',
    component: paywayMy,
    meta: {
      title: '收银台'
    }
  },
  {
    path: '/question',
    name: 'question',
    component: question,
    meta: {
      title: '有奖问答'
    }
  },
  {
    path: '/addservice',
    name: 'addservice',
    component: addService,
    meta: {
      title: '增值服务'
    }
  },
  {
    path: '/customer',
    name: 'customer',
    component: customer,
    meta: {
      title: '专属客服'
    }
  },
  {
    path: '/addresslist',
    name: 'addresslist',
    component: addressList,
    meta: {
      title: '收货地址'
    }
  },
  {
    path: '/addressedit/:id',
    name: 'addressedit',
    component: addressEdit,
    meta: {
      title: '收货地址'
    }
  },{
    path: '/addressEditOrder/:id',
    name: 'addressEditOrder',
    component: addressEditOrder,
    meta: {
      title: '订单地址'
    }
  },
  {
    path: '/center',
    name: 'center',
    component: center,
    meta: {
      title: '我的'
    }
  },
  {
    path: '/mailSearch',
    name: 'mailSearch',
    component: mailSearch,
    meta: {
      title: '快递追踪'
    }
  },{
    path: '/packageCheck',
    name: 'packageCheck',
    component: packageCheck,
    meta: {
      title: '包裹核对'
    }
  },
  {
    path: '/carriage',
    name: 'carriage',
    component: carriage,
    meta: {
      title: '运费估算'
    }
  },
  {
    path: '/countResult',
    name: 'countResult',
    component: countResult,
    meta: {
      title: '估算结果'
    }
  },
  {
    path: '/countDetail',
    name: 'countDetail',
    component: countDetail,
    meta: {
      title: '运费估算结果-详情'
    }
  },
  {
    path: '/contraband',
    name: 'contraband',
    component: contraband,
    meta: {
      title: '禁运物品'
    }
  },
  {
    path: '/packagecn',
    name: 'packagecn',
    component: packageCN,
    meta: {
      title: '国内包裹'
    }
  },
  {
    path: '/storage',
    name: 'storage',
    component: storage,
    meta: {
      title: '待入库'
    }
  },
  {
    path: '/transport',
    name: 'transport',
    component: transport,
    meta: {
      title: '我要转运'
    }
  }, {
    path: '/couponList',
    name: 'couponList',
    component: couponList,
    meta: {
      title: '优惠券'
    }
  },
  {
    path: '/waitpayment',
    name: 'waitpayment',
    component: waitPayment,
    meta: {
      title: '合包信息'
    }
  },
  {
    path: '/waitpacked',
    name: 'waitpacked',
    component: waitPacked,
    meta: {
      title: '合包信息'
    }
  },
  {
    path: '/outstock',
    name: 'outstock',
    component: outStock,
    meta: {
      title: '待签收'
    }
  },
  {
    path: '/waitsigned',
    name: 'waitsigned',
    component: waitSigned,
    meta: {
      title: '待评价'
    }
  },
  {
    path: '/evaluate/:id',
    name: 'evaluate',
    component: evaluate,
    meta: {
      title: '发表评价'
    }
  },
  {
    path: '/orderguide',
    name: 'orderguide',
    component: orderGuide,
    meta: {
      title: '下单指南'
    }
  },
  {
    path: '/addorder',
    name: 'addorder',
    component: addOrder,
    meta: {
      title: '提交打包'
    }
  },
  {
    path: '/directMail',
    name: 'directMail',
    component: directMail,
    meta: {
      title: '单包直邮'
    }
  },
  {
    path: '/lostProperty',
    name: 'lostProperty',
    component: lostProperty,
    meta: {
      title: '无主认领'
    }
  },
  {
    path: '/process/:id/:sn',
    name: 'process',
    component: process,
    meta: {
      title: '进度详情'
    }
  },
  {
    path: '/myorder',
    name: 'myorder',
    component: myOrder,
  },
  {
    path: '/addphone',
    name: 'addphone',
    component: addPhone,
  },
  {
    path: '/configorder',
    name: 'configorder',
    component: configOrder,
  },
  {
    path: '/commentlist',
    name: 'commentlist',
    component: commentList,
  },
  {
    path: '/qrcode',
    name: 'qrcode',
    component: qrCode,
  },
  {
    path: '/orderdetail/:id',
    name: 'orderdetail',
    component: orderDetail,
  },
  {
    path: '/test',
    name: 'test',
    component: () => import('@/pages/test/index'),
  },
  {
    path: '/friendShare',
    name: 'friendShare',
    component: () => import('@/pages/friendShare'),
  },
  {
    path: '/prize',
    name: 'prize',
    component: () => import('@/pages/prize'),
    meta: {
      title: '奖品页'
    }
  },
  {
    path: '/friendList',
    name: 'friendList',
    component: () => import('@/pages/friendList'),
    meta: {
      title: '好友列表'
    }
  },
];
const router = new Router({
  routes
})
router.beforeEach((to, from, next) => {
  if (to.meta) {
    document.title = to.meta.title || ''
  }
  if (to.path == '/index') {
    if (to.query.accessToken) {
      document.cookie = 'authorization=' + to.query.accessToken;
      axios.defaults.headers.common['authorization'] = to.query.accessToken;
    }
    if (to.query.accessToken && localStorage.getItem('invideUserId')) {
      next(`/friendShare?accessToken=${to.query.accessToken}`)
    } else {
      next()
    }
  }
  next()
})
export default router
