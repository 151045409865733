var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"orderGuide"},[_c('emptyInfo',{directives:[{name:"show",rawName:"v-show",value:(_vm.empty),expression:"empty"}]}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.empty && _vm.guideInfo != ''),expression:"!empty && guideInfo != ''"}]},[_c('div',{staticClass:"step"},[_vm._m(0),_c('div',{staticClass:"between item"},[_c('p',[_vm._v("收件人："+_vm._s(_vm.guideInfo.recipient))]),_c('img',{attrs:{"src":"https://jcex-file-service.oss-cn-hangzhou.aliyuncs.com/jc-wms/copy.png"},on:{"click":function($event){return _vm.copyContent(_vm.guideInfo.recipient)}}})]),_c('div',{staticClass:"between item"},[_c('p',[_vm._v("收件地址："+_vm._s(_vm.guideInfo.address))]),_c('img',{attrs:{"src":"https://jcex-file-service.oss-cn-hangzhou.aliyuncs.com/jc-wms/copy.png"},on:{"click":function($event){return _vm.copyContent(_vm.guideInfo.address)}}})]),_c('div',{staticClass:"between item"},[_c('p',[_vm._v("联系电话："+_vm._s(_vm.guideInfo.phone))]),_c('img',{attrs:{"src":"https://jcex-file-service.oss-cn-hangzhou.aliyuncs.com/jc-wms/copy.png"},on:{"click":function($event){return _vm.copyContent(_vm.guideInfo.phone)}}})]),_c('div',{staticClass:"between item"},[_c('p',[_vm._v("邮编："+_vm._s(_vm.guideInfo.postCode))]),_c('img',{attrs:{"src":"https://jcex-file-service.oss-cn-hangzhou.aliyuncs.com/jc-wms/copy.png"},on:{"click":function($event){return _vm.copyContent(_vm.guideInfo.postCode)}}})]),_c('p',{staticClass:"sign"},[_vm._v(" 粘贴以上收货地址粘贴到淘宝、天猫、京东、代购等 ")]),_c('div',{staticClass:"btn"},[_c('span',{on:{"click":function($event){return _vm.copyContent(
							_vm.guideInfo.recipient +
								' ' +
								_vm.guideInfo.address +
								' ' +
								_vm.guideInfo.phone +
								' ' +
								_vm.guideInfo.postCode
						)}}},[_vm._v("复制全部")])])]),_c('div',{staticClass:"step"},[_c('div',{staticClass:"step-head between"},[_vm._m(1),_c('p',{on:{"click":_vm.submitPackage}},[_c('span',[_vm._v("提交打包")]),_vm._m(2)])]),_c('div',{staticClass:"item"},[_c('p',[_vm._v(" "+_vm._s(_vm.guideInfo.packRemark)+" ")])])]),_c('div',{staticClass:"step"},[_c('div',{staticClass:"step-head between"},[_vm._m(3),_c('p',{on:{"click":_vm.goPay}},[_c('span',[_vm._v("支付运费")]),_vm._m(4)])]),_c('div',{staticClass:"item"},[_c('p',[_vm._v(" "+_vm._s(_vm.guideInfo.payRemark)+" ")])])])]),_c('messageDialog',{directives:[{name:"show",rawName:"v-show",value:(_vm.promit),expression:"promit"}],attrs:{"content":"复制成功！"}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"step-head between"},[_c('p',[_c('span',[_vm._v("第一步")]),_c('span',[_vm._v("step1")])]),_c('p',[_vm._v("寄货到欧轩物流")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("第二步")]),_c('span',[_vm._v("step2")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('img',{attrs:{"src":require("@/assets/images/arrow-left.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("第三步")]),_c('span',[_vm._v("step3")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('img',{attrs:{"src":require("@/assets/images/arrow-left.png"),"alt":""}})])
}]

export { render, staticRenderFns }