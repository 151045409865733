<template>
  <div class="index">
    <emptyInfo v-show="empty"></emptyInfo>
    <div class="mainContent">
      <p class="banner">
        <img :src="logoBanner" />
      </p>
      <ul class="serveList">
        <li v-for="(item, index) in list" :key="index" @click="routeChange(item.route)">
          <div v-show="(item.title === '合包转运' || item.title === '异常包裹') ? true : false" class="orderStatusDiv">
            <div v-if="item.title === '合包转运' && hebaonum>0">{{ hebaonum }}</div>
            <div v-if="item.title === '异常包裹' && yichangnum>0">{{ yichangnum }}</div>
          </div>
          <img :src="item.imgUrl" />
          <p>{{ item.title }}</p>
        </li>
      </ul>
      <p class="slider-banner banner">
        <swiper :options="swiperOption" v-if="swiperList.length > 0">
          <swiper-slide v-for="(item, index) in swiperList" :key="index">
            <img :src="item.adResUrl" height="200" />
          </swiper-slide>
          <div class="swiper-pagination paginationBanner" slot="pagination"></div>
        </swiper>
      </p>
    </div>
    <foot-bar :pageIndex="0"></foot-bar>
  </div>
</template>

<script>
import axios from "axios";
import emptyInfo from "@/components/emptyInfo.vue";
import FootBar from "../components/footBar";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
let vm = null;
export default {
  name: "index",
  data() {
    return {
      hebaonum: 0,
      yichangnum: 0,
      userInfo: "",
      empty: false,
      isEnd: true,
      packValue: 10,
      pageNo: 1,
      pageSize: 5,
      totalPage: 0,
      list: [
        {
          imgUrl: require("@/assets/images/hico1.png"),
          title: "单包直邮",
          route: "directMail",
        },
        {
          imgUrl: require("@/assets/images/hico2.png"),
          title: "包裹预报",
          route: "addorder",
        },
        {
          imgUrl: require("@/assets/images/hico3.png"),
          title: "包裹核对",
          route: "packageCheck",
        },
        {
          imgUrl: require("@/assets/images/hico4.png"),
          title: "合包转运",
          route: "transport",
        },
        {
          imgUrl: require("@/assets/images/hico5.png"),
          title: "支付运费",
          route: "waitpacked",
        },
        {
          imgUrl: require("@/assets/images/hico6.png"),
          title: "下单指南",
          route: "orderguide",
        },
        {
          // orderStatus: userOrderStatus.orderStatus1,
          imgUrl: require("@/assets/images/hico7.png"),
          title: "异常包裹",
          route: "packagecn",
        },
        {
          imgUrl: require("@/assets/images/hico8.png"),
          title: "运费估算",
          route: "carriage",
        },
        {
          imgUrl: require("@/assets/images/hico9.png"),
          title: "禁运物品",
          route: "contraband",
        },
        {
          imgUrl: require("@/assets/images/hico10.png"),
          title: "专属客服",
          route: "customer",
        },
      ],
      commentList: [],
      swiperList: [],

      swiperOption: {
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        loop: true,
        speed: 1000,
        observer: true,
        observeParents: true,

        on: {
          tap: function () {
            const realIndex = this.realIndex;
            vm.bannderLinkUrl(realIndex);
          },
        },
      },
      swiperRoll: {
        direction: "vertical",
        autoplay: {
          disableOnInteraction: false,
          delay: 2000,
          autoplay: true,
        },
        loop: true,
        speed: 1000,
        observer: true,
        observeParents: true,
      },
      swiperScroll: {
        freeMode: true,
        freeModeMomentum: false,
        slidesPerView: "auto",
        observer: true,
        observeParents: true,
      },
      logoBanner: require("@/assets/images/banner2.png")
    };
  },
  computed: {},
  components: {
    FootBar,
    emptyInfo,
    swiper,
    swiperSlide,
  },
  created() {
    this.getOrderList();//调用合包接口
    this.getUserInfo(); // 获取异常包裹数量
    var accessToken = this.$route.query.accessToken;
    let userId = this.$route.query.userId;
    if (userId) {
      localStorage.setItem("invideUserId", userId);
    }
    //this.getIndexComment();
    if (accessToken != "" && accessToken != undefined) {
      document.cookie = "authorization=" + accessToken;
      axios.defaults.headers.common["authorization"] = accessToken;
      // if (localStorage.getItem('invideUserId')) {// 绑定分享关系
      // 	this.$post('/api/h5/inviteNewClient', {accessToken,userId:localStorage.getItem('invideUserId')}).then(res => {
      // 		if (res.resultCode == 10000) {
      // 			localStorage.removeItem('invideUserId')
      // 			this.$router.push('/friendShare')
      // 		}
      // 	})
      // }
    }
    this.selectSystemParams();
    this.getbannderImg();
    vm = this;
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll);
  },
  methods: {
    /*  获取合包数量*/
    getOrderList() {
      var that = this
      var url = '/api/h5/orderListByType'
      var data = {
        pageNo: 1,
        pageSize: 900,
        orderStatus: 10,
      }
      this.$post(url, data).then((res) => {
        // console.log(res.result.orderList.length)
        if (res.result != null) {
          this.hebaonum = res.result.orderList.length
        }
      })
    },
    getUserInfo() {
      var url = "/api/h5/userInfo";
      var data = {};
      this.$post(url, data).then((res) => {
        this.yichangnum = res.result.userOrderStatus.orderStatus1 // 异常包裹数量
        if (res.resultCode == 10000) {
          this.userInfo = res.result.userModel;
          localStorage.setItem("uid", res.result.userModel.uid);
          localStorage.setItem("userId", res.result.userModel.userId);
        }
      });
    },


    onScroll() {
      //可滚动容器的高度
      let innerHeight = document.querySelector("#app").clientHeight;
      //屏幕尺寸高度
      let outerHeight = document.documentElement.clientHeight;
      //可滚动容器超出当前窗口显示范围的高度
      let scrollTop =
        document.documentElement.scrollTop ||
        document.body.scrollTop ||
        window.pageYOffset;
      //scrollTop在页面为滚动时为0，开始滚动后，慢慢增加，滚动到页面底部时，出现innerHeight < (outerHeight + scrollTop)的情况，严格来讲，是接近底部。
      if (innerHeight <= outerHeight + scrollTop + 5) {
        //加载更多操作
        if (this.pageNo <= this.totalPage && this.isEnd == true) {
          this.isEnd = false;
          //this.getIndexComment();
        }
      }
    },
    getIndexComment() {
      var that = this;
      var url = "/api/h5/index";
      var data = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
      };
      this.$post(url, data).then((res) => {
        that.isEnd = true;

        if (res.resultCode == 10000) {
          that.pageNo += 1;
          that.totalPage = res.result.page.pageCount;
          if (
            res.result.evaluateList != undefined &&
            res.result.evaluateList.length > 0
          ) {
            var evaluateList = res.result.evaluateList;
            for (let i = 0; i < evaluateList.length; i++) {
              if (evaluateList[i].picUrl.length == 0) {
                evaluateList[i].picUrl = [];
              } else {
                evaluateList[i].picUrl = evaluateList[
                  i
                ].picUrl.split(",");
              }
              if (evaluateList[i].createTime == "") {
                evaluateList[i].createTime = "--";
              } else {
                evaluateList[i].createTime = this.$getDate(
                  evaluateList[i].createTime
                );
              }
            }
            this.commentList = this.commentList.concat(
              evaluateList
            );
          }
        }
      });
    },

    selectSystemParams() {
      //查询查询参数
      var url = "/api/h5/selectSystemParams";
      url = "/api/platform/selectSystemParams";
      var data = {};
      this.$post(url, data).then((res) => {
        this.packValue = res.result.systemParams.packValue;
        console.log(this.packValue);
      });
    },

    routeChange(route) {
      console.log(route);
      console.log(this.packValue);
      if (route == "transport") {
        if (this.packValue == 10) {
          this.$router.push({
            name: "transport",
            query: { channel: 1 },
          });
        } else {
          this.$router.push({
            name: "transport",
            query: { channel: this.packValue },
          });
        }
      } else {
        this.$router.push({
          name: route,
        });
      }
    },

    getbannderImg: function () {
      this.$get("/api/h5/ad").then((res) => {
        console.log(res);
        if (res.resultCode == 10000) {
          this.swiperList = res.result.details;
        }
      });
    },

    // 轮播图跳转
    bannderLinkUrl(index) {
      let url = this.swiperList[index].navigationUrl;
      let newStr = url.indexOf("http") === 0;
      if (newStr) {
        window.location.href = url;
      } else {
        this.$router.push({
          path: url,
        });
      }
    },
  },
  destroyed() {
    window.removeEventListener("scroll", this.onScroll);
  },
};
</script>

<style lang="scss">
.index {
  font-size: 16px;
}

.el-image-viewer__btn .el-image-viewer__next .el-icon-arrow-right .el-icon-arrow-left {
  width: 60px;
  height: 60px;
}

.el-icon-circle-close {
  color: white;
  font-size: 60px;
}

.imglist {
  img {
    margin-top: 30px;
    margin-right: 30px;
  }
}

.index {
  width: 100%;
  min-height: 100%;
  background: #f6f6f6;
  overflow: scroll;
  padding-bottom: 140px;
  position: relative;

  .popUpBox {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 100;
    background: rgba(0, 0, 0, 0.6);
    position: absolute;
  }

  .packType {
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    z-index: 101;
    background-color: #ffffff;
    width: 50rem;
    height: 18rem;
    border: #888888 2px solid;
    position: fixed;
    left: 50%;
    top: 50%;
    margin-top: -25rem;
    margin-left: -25rem;

    .packType_title {
      // font-size: 3rem;
      color: #303133;
      height: 9rem;
      line-height: 9rem;
      text-align: center;
      border-bottom: 1px solid black;
    }

    .packType_div {
      flex-direction: row;
      font-size: 2.5rem;
      color: #606266;
      height: 9rem;
      line-height: 9rem;

      // .btn{
      // 	ba
      // }
      span {
        height: 70%;
        line-height: 6.3rem;
        display: inline-block;
        text-align: center;
        cursor: pointer;
        //background;
        color: #409eff;
        width: 50%;
      }
    }
  }

  .footbar {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
  }

  .mainContent {

    // position: fixed;
    // top: 0;
    // left: 0;
    // bottom: 130px;
    // overflow-y: scroll;
    // width: 100%;
    // height: auto;
    // -webkit-overflow-scrolling: touch;
    .banner {
      background: #fff;
      width: 100%;

      img {
        width: 100%;
      }
    }

    .serveList {
      padding: 0 2rem;
      background: #fff;
      // padding:0 5rem;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      li {
        flex-shrink: 0;
        width: 20%;
        padding: 1.5rem 0;

        img {
          width: 9rem;
          height: 9rem;
        }

        p {
          padding-top: 1rem;
          font-size: 2.5rem;
        }

        .orderStatusDiv {
          div {
            position: absolute;
            top: -5px;
            right: 3.9rem;
            // float: right;
            width: 3.5rem;
            height: 3.5rem;
            line-height: 3.4rem;
            background: #fe9712;
            z-index: -1;
            text-align: center;
            border-radius: 50%;
          }

          position: relative;
          width: 100px;
          float: initial;
          text-align: right;
          font-size: 0.8em;
          z-index: 100;
          margin-right: 150px;
          color: white;
          z-index: 100;
        }
      }
    }

    .commentList {
      padding: 0 1.5rem;

      li {
        margin-top: 1.5rem;
        background: #fff;
        border-radius: 1rem;
        padding: 2rem;
        text-align: left;

        .commentContent {
          color: #333;
          font-size: 12px;
          -webkit-text-size-adjust: none;
          max-height: 200px;
          margin-top: 1.5rem;
        }

        .userInfo {
          // padding-top: 3rem;
          display: flex;
          justify-content: space-between;
          align-items: center;

          p:nth-child(1) {
            display: flex;
            align-items: center;

            .userImg {
              width: 4rem;
              height: 4rem;
              border-radius: 50%;
              margin-right: 2rem;
            }

            .logo {
              width: 4rem;
              height: 4rem;
              margin-right: 2rem;
            }

            span {
              font-size: 2rem;
            }
          }

          p:nth-child(2) {
            color: #999;
            font-size: 2rem;
          }
        }
      }
    }

    .promit {
      color: #999;
      font-size: 1.8rem;
      padding: 2.5rem 0;
      position: relative;
    }

    .promit:before {
      content: "";
      background: #e5e5e5;
      height: 2px;
      width: 150px;
      position: absolute;
      top: 50%;
      left: calc(50% - 200px);
      transform: translate(-50%, -50%);
    }

    .promit:after {
      content: "";
      background: #e5e5e5;
      height: 2px;
      width: 150px;
      position: absolute;
      top: 50%;
      left: calc(50% + 200px);
      transform: translate(-50%, -50%);
    }
  }
}

.index::-webkit-scrollbar {
  width: 0;
  height: 0;
  background-color: transparent;
}

.transport .foot .chose[data-v-d7b4ce56] {
  color: #333;
  font-size: 10px !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
</style>
