<template>
  <div class="wrap">
    <headerTitle title="我的积分"> </headerTitle>
    <div class="integralBack">
      <div class="totalBox">
        <span>总积分</span>
        <img src="../assets/images/invit-t2-4.png" alt="" />
      </div>
      <div class="integralNum">
        <div class="numBox">{{ money }}</div>
        <div @click="goIntegralDetail('integralDetail')" class="integralDetail">
          积分明细
        </div>
      </div>
    </div>
    <div class="rulelistBox">
      <div class="ruleList">
        <div class="ruleTitle">积分规则</div>
        <div class="rule">
          <div class="ruleItem">
            <div>1、积分范围说明</div>
            您在参与欧轩物流国际集运发起的活动（以下简称“活动”）以及发起订单并成交时可获得各类积分奖励（以下简称“积分”），您可点击公众号内【我的】页面-用户头像下方积分总额，进入【我的积分】页面查看积分等信息并进行相关操作。
          </div>
          <div class="ruleItem">
            <div>2、积分说明</div
            >（1）总积分：指您通过活动和交易成功领取的所有有效积分总和。有效积分即为未消耗、未提现、未过期清除积分，具有以【我的积分】页面展示积分为准。
            （2）积分获取：
            ①用户完成订单交易后，会返还积分，每1元人民币交易对应返还1个积分；
            ②老用户邀请新用户，新用户下单后，该老用户获得积分返还，即新用户每成交1元，该老用户获得2个积分；
            ③其他由欧轩物流国际主办的活动。
          </div>

          <div class="ruleItem">
            <div>3、积分使用</div>
            （1）积分可提现：积分可用于提现，以现有有效积分作为基准，每100积分对应提现1元，提现金额每满100元可操作。不支持提现操作的积分，包括但不限于因财务审核、用户预留信息不符、积分账户异常、可提现积分不足额等事由而无法进行提现操作的积分额度。
            （2）积分可抵现：积分可用于交易订单支付时抵现，以现有有效积分作为基准，每100积分对应抵现1.2元，积分抵现操作无门槛。不支持抵现操作的积分，包括但不限于用户订单错误、积分账户异常、可抵现积分不足额等事由而无法进行提现操作的积分额度。
            （3）其他由欧轩物流国际主办的活动及商品兑换形式。
          </div>

          <div class="ruleItem">
            <div>4、提现规则说明</div> （1）提现时间：每天可提现一次。
            （2）到账时间，具体以第三方支付渠道为准，通常在您提交申请后72小时内到账，请耐心等待。
            （3）提现渠道：提现渠道微信线下转账，需用户在提现界面提供微信实名认证手机号很真实姓名，并在微信支付管理中打开【允许通过手机号向我转账】按钮。
            （4）到账金额：因政策变更不稳定性，微信提现可能产生少量税费和手续费，提现金额和实际到账金额或有差额，仅以实际到账金额为准，欧轩物流国际不主动承担税费和手续费。
            （5）提现条件：您应满足积分提现的前置条件，并根据页面提示的流程、规范进行操作。若因你自身原因导致提现失败（包括但不限于未按提现要求操作、未预留收款账号信息、预留的收款账号及信息错误、微信支付拒绝手机号码接收转账等），欧轩物流国际无需承担相关责任。
          </div>

          <div class="ruleItem">
            <div>5、承诺与保证</div>
            您理解并同意，如您存在违反法律法规以及其他欧轩物流国际协议规范、活动规则的行为，或通过任何不正当、违反诚实信用原则的方式（包括但不限于反复注册账号、一人多号、利用机器人软件/蜘蛛软件等技术手段）参加活动，欧轩物流国际有权单方面中止/终止您参与活动、获取红包资格，并有权拒绝您的提现申请或要求您返还已提现红包金额。
          </div>

          <div class="ruleItem">
            <div>6、申明</div>
            此积分仅在欧轩物流国际快递集运系统内使用，不得转借和私下交易，不得恶意炒作。本规则最终会解释权归浙江欧轩物流通跨境供应链管理有限公司所有。
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import headerTitle from "../components/headerTitle";
export default {
  name: "myIntegral",
  components: {
    headerTitle
  },
  data() {
    return {
      userId: localStorage.getItem("userId") || "",
      money: ""
    };
  },
  mounted() {
    this.getMyPoint();
  },
  methods: {
    getMyPoint() {
      this.$get("/api/h5/user/points/home", {
        uid: this.userId
      }).then(res => {
        this.money = res.result.totalPoints || 0;
      });
    },
    goIntegralDetail(path) {
      this.$router.push({
        path: path
      });
    }
  }
};
</script>

<style scoped lang="scss">
.wrap {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: #f1eff2;
}
.integralBack {
  background: url("../assets/images/integ-bg.png");
  width: 100%;
  height: 26rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  box-sizing: border-box;
  padding: 0 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.totalBox {
  font-size: 4rem;
  color: white;
  display: flex;
  align-items: center;
  img {
    width: 3rem;
    margin-left: 5px;
  }
}
.integralNum {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
  .numBox {
    color: #fff;
    font-size: 5rem;
  }
  .integralDetail {
    color: #fff;
    font-size: 2rem;
    box-sizing: border-box;
    padding: 0.8rem 1.5rem;
    border-radius: 8px;
    border: 1px solid #fff;
  }
}
.rulelistBox {
  margin: 3rem 0;
}
.ruleList {
  margin: 0 3rem;
  background: #fff;
  border-radius: 2rem;
  .ruleTitle {
    height: 6rem;
    line-height: 6rem;
    font-size: 3rem;
    color: #fff;
    background: #ef3c4d;
    border-radius: 2rem 2rem 0 0;
  }
  .rule {
    box-sizing: border-box;
    padding: 3rem 2rem;
  }
  .ruleItem {
      text-align: left;
    font-size: 2rem;
    margin-bottom: 1em;
    div{
        font-weight: bold;
    }
  }
}
</style>
