<template>
	<div class="orderGuide">
		<emptyInfo v-show="empty"></emptyInfo>
		<div v-show="!empty && guideInfo != ''">
			<div class="step">
				<div class="step-head between">
					<p>
						<span>第一步</span>
						<span>step1</span>
					</p>
					<p>寄货到欧轩物流</p>
				</div>
				<div class="between item">
					<p>收件人：{{ guideInfo.recipient }}</p>
					<img
						src="https://jcex-file-service.oss-cn-hangzhou.aliyuncs.com/jc-wms/copy.png"
						@click="copyContent(guideInfo.recipient)"
					/>
				</div>
				<div class="between item">
					<p>收件地址：{{ guideInfo.address }}</p>
					<img
						src="https://jcex-file-service.oss-cn-hangzhou.aliyuncs.com/jc-wms/copy.png"
						@click="copyContent(guideInfo.address)"
					/>
				</div>
				<div class="between item">
					<p>联系电话：{{ guideInfo.phone }}</p>
					<img
						src="https://jcex-file-service.oss-cn-hangzhou.aliyuncs.com/jc-wms/copy.png"
						@click="copyContent(guideInfo.phone)"
					/>
				</div>
				<div class="between item">
					<p>邮编：{{ guideInfo.postCode }}</p>
					<img
						src="https://jcex-file-service.oss-cn-hangzhou.aliyuncs.com/jc-wms/copy.png"
						@click="copyContent(guideInfo.postCode)"
					/>
				</div>
				<p class="sign">
					粘贴以上收货地址粘贴到淘宝、天猫、京东、代购等
				</p>
				<div class="btn">
					<span
						@click="
							copyContent(
								guideInfo.recipient +
									' ' +
									guideInfo.address +
									' ' +
									guideInfo.phone +
									' ' +
									guideInfo.postCode
							)
						"
						>复制全部</span
					>
				</div>
			</div>
			<div class="step">
				<div class="step-head between">
					<p>
						<span>第二步</span>
						<span>step2</span>
					</p>
					<p @click="submitPackage">
						<span>提交打包</span>
						<span>
							<img src="@/assets/images/arrow-left.png" alt="" />
						</span>
					</p>
				</div>
				<div class="item">
					<p>
						{{ guideInfo.packRemark }}
					</p>
				</div>
			</div>
			<div class="step">
				<div class="step-head between">
					<p>
						<span>第三步</span>
						<span>step3</span>
					</p>
					<p @click="goPay">
						<span>支付运费</span>
						<span>
							<img src="@/assets/images/arrow-left.png" alt="" />
						</span>
					</p>
				</div>
				<div class="item">
					<p>
						{{ guideInfo.payRemark }}
					</p>
				</div>
			</div>
		</div>
		<messageDialog content="复制成功！" v-show="promit"></messageDialog>
	</div>
</template>

<script>
	import emptyInfo from "@/components/emptyInfo.vue";
	import { handleClipboard } from "@/assets/js/clipboard";
	import messageDialog from "@/components/messageDialog.vue";
	export default {
		name: "orderGuide",
		data() {
			return {
				promit: false,
				guideInfo: "",
				empty: false,
			};
		},
		components: {
			messageDialog,
			emptyInfo,
		},
		created() {
			this.getGuideInfo();
		},
		methods: {
			getGuideInfo() {
				var url = "/api/h5/getGuide";
				var data = {};
				this.$post(url, data).then((res) => {
					if (res.resultCode == 10000) {
						this.guideInfo = res.result.guideModel;
					} else {
						this.empty = true;
					}
				});
			},
			submitPackage() {
				this.$router.push({
					name: "transport",
					query: { channel: 1 },
				});
			},
			goPay() {
				this.$router.push({
					name: "waitpayment",
				});
			},
			copyContent(content) {
				var that = this;
				handleClipboard(content, event, () => {
					that.promit = true;
					setTimeout(function () {
						that.promit = false;
					}, 1500);
				});
			},
		},
	};
</script>

<style lang="scss" scoped>
	.orderGuide {
		background: #f6f6f6;
		width: 100%;
		min-height: 100%;
		.step {
			background: #fff;
			padding-left: 6%;
			padding-bottom: 2rem;
			margin-bottom: 2rem;
			.between {
				display: -webkit-box;
				display: -moz-box;
				display: -ms-flexbox;
				display: -webkit-flex;
				display: flex;
				-webkit-justify-content: space-between;
				justify-content: space-between;
				-moz-box-pack: space-between;
				-webkit--moz-box-pack: space-between;
				box-pack: space-between;
				align-items: center;
				-webkit-align-items: center;
				box-align: center;
				-moz-box-align: center;
				-webkit-box-align: center;
			}
			.step-head {
				border-bottom: 2px solid #eee;
				padding: 3rem 0;
				p:nth-child(1) {
					span:nth-child(1) {
						font-size: 16px;
						color: #333333;
						font-weight: 500;
					}
					span:nth-child(2) {
						font-size: 2.2rem;
						color: #999999;
						padding-left: 2rem;
					}
				}
				p:nth-child(2) {
					flex-shrink: 0;
					font-size: 16px;
					color: #333333;
					padding-right: 6%;
					font-weight: 500;
					span:nth-child(1) {
						color: #ef3c4d;
					}
					span:nth-child(2) {
						display: inline-block;
						width: 1rem;
						margin-left: 1rem;
						img {
							width: 100%;
						}
					}
				}
			}
			.item {
				padding: 2rem 0;
				p {
					color: #333333;
					font-size: 2.6rem;
					text-align: left;
					line-height: 4rem;
					font-weight: 500;
					padding-right: 6%;
				}
				img {
					width: 2.5rem;
					padding-right: 6%;
					flex-shrink: 0;
				}
			}
			.sign {
				text-align: left;
				padding: 2rem 0;
				color: #999999;
				font-size: 2.2rem;
				border-top: 2px solid #eee;
			}
			.btn {
				margin: 3rem 6% 3rem 0;
				color: #fff;
				font-size: 3.2rem;
				span {
					display: inline-block;
					background: #ef3c4d;
					border-radius: 1rem;
					padding: 10px;
					width: 40%;
				}
			}
		}
	}
</style>
