<template>
	<div class="carriage">
		<emptyInfo v-show="empty"></emptyInfo>
		<div class="context">
			<div class="my-panel no-padding field">
				<mt-field
					label="集运目的地"
					ref="scanTextbox"
					placeholder="选择国家/地区"
					@focus.native.capture="showPop"
					v-model="country"
					class="required"
          readonly
				></mt-field>
				<mt-field
					label="包裹重量"
					placeholder="如：3.0kg，则输入3"
					type="number"
					v-model="weight"
					class="required"
				></mt-field>
			</div>

			<div class="my-panel">
				<div class="title">
					<label>包裹尺寸</label>
				</div>
				<div class="sub-title">
					<span>包裹打包后的实际长宽高，适用于某些体积重线路计算</span>
				</div>
				<div class="my-flex item-size">
					<div>
						<label for="">长（cm）</label>
						<div>
							<input type="number" v-model="length" />
						</div>
					</div>
					<div>
						<label for="">宽（cm）</label>
						<div>
							<input type="number" v-model="width" />
						</div>
					</div>
					<div>
						<label for="">高（cm）</label>
						<div>
							<input type="number" v-model="height" />
						</div>
					</div>
				</div>
			</div>
			<div class="my-panel reminder">
				<div class="title">温馨小提示：</div>
				<div class="sub-title">
					<ul>
						<li>
							在国际运费中，包裹占用货舱的空间即“体积重量”和“实际重量”同样的重要，体积较大的包裹，即使他们实际重量很轻，也会在运输过程中占用更多的空间。包裹计费重量为包裹实际称重重量与体积重量取较大者。
						</li>
						<li>
							以上运费估算仅以供参考，具体以仓库收到包裹提供计费重量为准。
						</li>
					</ul>
				</div>
			</div>

			<div class="my-panel flex-btn">
				<mt-button
					type="primary"
					width="100%"
					class="radius-btn"
					@click="goCalc"
				>
					立即查询</mt-button
				>
			</div>
		</div>
		<mt-popup
			v-model="popupVisible"
			position="bottom"
			class="my-full-width my-popup"
		>
			<div class="title">
				<span>选择国家/地区</span>
				<span class="btn cancel" @click="selectCity(false)">取消</span>
				<span class="btn ok" @click="selectCity(true)">确定</span>
			</div>
			<div class="line"></div>
			<!-- <div class="my-search">
				<img src="../assets/images/contr_search.png" />
				<input
					type="text"
					placeholder="请输入国家/地区名称"
					v-model="search"
					@input="searchChange"
				/>
			</div> -->
			<mt-picker
				ref="picker"
				:slots="slots"
				:visible-item-count="5"
				:item-height="45"
				@change="onValuesChange"
			></mt-picker>
		</mt-popup>
		<messageDialog
			:content="messageContent"
			v-show="promit"
		></messageDialog>
	</div>
</template>

<script>
	import emptyInfo from "@/components/emptyInfo.vue";
	import messageDialog from "@/components/messageDialog.vue";

	let _self;
	export default {
		name: "carriage",
		data() {
			return {
				footShow: true,
				empty: false,
				promit: false,
				messageContent: "",
				areaName: "请选择区域",
				areaId: "",
				areaDown: false,
				areaList: [], //地区列表
				areaListSource: [],
				countryList: [
					{ country: "美国", countryId: "3" },
					{ country: "加拿大", countryId: "11" },
					{ country: "澳大利亚", countryId: "9" },
					{ country: "新西兰", countryId: "10" },
					{ country: "英国", countryId: "13" },
					{ country: "法国", countryId: "15" },
					{ country: "德国", countryId: "14" },
				],
				country: "",
				oldCountry: "",
				weight: "",
				length: "",
				width: "",
				height: "",
				popupVisible: false,
				search: "",
				slots: [
					{
						flex: 1,
						values: [],
						textAlign: "left",
					},
				],
				value: "",
			};
		},
		components: {
			messageDialog,
			emptyInfo,
		},
		created() {
			_self = this;
			this.getCountryList();
		},
		mounted() {
			let that = this;
			let oriWinHeight = window.innerHeight;
			window.onresize = function () {
				that.isKeybordAvail = true;
				let newHeight = window.innerHeight;
				// 阀值大于140判断为键盘收起
				if (newHeight - oriWinHeight > 140) {
					that.footShow = true;
				} else {
					that.footShow = false;
				}
				oriWinHeight = newHeight;
			};
		},
		methods: {
			getCountryList() {
				this.$post("/api/h5/getAddAddressData", { countryId: 0 }).then(
					(res) => {
						if (res.resultCode == 10000) {
							this.areaList = res.result.resourseList;
							this.areaListSource = res.result.resourseList;
						}
					}
				);
			},
			refreshList(datas) {
				this.$refs.picker.setSlotValues(
					0,
					datas.map((x) => x.country)
				);
			},
			openSelect() {
				if (this.areaDown == true) {
					this.areaDown = false;
				} else {
					this.areaDown = true;
				}
			},
			areaChange(item) {
				this.areaName = item.country;
				this.areaId = item.countryId;
				this.areaDown = false;
			},
			closeSelect() {
				this.areaDown = false;
			},
			goCalc() {
				if (this.areaId == "") {
					this.messageContent = "请选择国家或地区!";
					this.promit = true;
					var that = this;
					setTimeout(function () {
						that.promit = false;
					}, 1500);
					return false;
				}
				if (
					this.weight == "" ||
					this.length == "" ||
					this.width == "" ||
					this.height == ""
				) {
					this.messageContent = "请填写重量和体积!";
					this.promit = true;
					var that1 = this;
					setTimeout(function () {
						that1.promit = false;
					}, 1500);
					return false;
				}

				if (
					!/^[0-9]*$/.test(this.length) ||
					!/^[0-9]*$/.test(this.width) ||
					!/^[0-9]*$/.test(this.height)
				) {
					this.messageContent = "体积只能输入数字";
					this.promit = true;
					var that2 = this;
					setTimeout(function () {
						that2.promit = false;
					}, 1500);
					return false;
				}
				if (!/^-?\d+(\.\d{1,3})?$/.test(this.weight)) {
					this.messageContent = "重量最多3位小数";
					this.promit = true;
					var that3 = this;
					setTimeout(function () {
						that3.promit = false;
					}, 1500);
					return false;
				}
				this.$router.push({
					name: "countResult",
					query: {
						countryId: this.areaId,
						weight: this.weight,
						length: this.length,
						width: this.width,
						height: this.height,
						country: this.areaName,
					},
				});
			},
			showPop() {
        //console.log(this.areaList)
        this.refreshList(this.areaList);
        _self.popupVisible = true;
        _self.oldCountry = this.country;

			/* 	_self.$refs.scanTextbox.$refs.input.setAttribute(
					"readonly",
					"readonly"
				);
				setTimeout(() => {
					_self.$refs.scanTextbox.$refs.input.removeAttribute("readonly");
				}, 200); */

				/* this.refreshList(this.areaList);
				_self.popupVisible = true;
				_self.oldCountry = this.country; */
			},
			onValuesChange(picker, values) {
				this.country = values[0];
				var datas = this.areaList.filter((x) => x.country == values[0]);
				if (datas.length > 0) this.areaId = datas[0].countryId;
			},
			searchChange() {
				let q = this.search;
				this.areaList = this.areaListSource.filter(
					(x) => x.country.indexOf(q) !== -1
				);
				this.refreshList(this.areaList);
			},
			selectCity(flag) {
				this.popupVisible = false;
				if (!flag) {
					this.country = this.oldCountry;
				}
			},
		},
	};
</script>

<style lang="scss">
	.el-input__inner {
		height: 100px !important;
		font-size: 3.2rem !important;
	}

	.carriage {
		width: 100%;
		min-height: 100%;
		.mainContent {
			width: 100%;
			height: 100%;
			display: flex;
			flex-direction: column;
			.area {
				width: 100%;
				padding: 3rem;
				text-align: left;
				background: #fff;
				.title {
					color: #333;
					font-size: 3rem;
					padding-bottom: 1.5rem;
					position: relative;
					.import {
						position: absolute;
						width: 1.5rem;
						height: 1.5rem;
						left: 21.5rem;
						top: 0.2rem;
					}
				}
				.selectView {
					padding-top: 15px;
					position: relative;
					height: 120px;
					.valueView {
						border: 2px solid #dcdcdc;
						border-radius: 10px;
						line-height: 90px;
						height: 90px;
						display: flex;
						justify-content: space-between;
						align-items: center;
						padding: 0 30px;
						span {
							color: #333;
							font-size: 2.5rem;
						}
					}
					.downList {
						position: absolute;
						left: 0;
						top: 105px;
						width: 100%;
						min-height: 200px;
						border: 2px solid #dcdcdc;
						border-radius: 10px;
						background: #fff;
						padding: 20px 0;
						max-height: 400px;
						overflow: auto;
						li {
							padding: 20px 30px;
							color: #333;
							font-size: 2.5rem;
						}
						li:hover {
							background: #f9f9f9;
						}
					}
				}
				.countryList {
					display: flex;
					flex-wrap: wrap;
					padding-top: 2rem;
					li {
						color: #fff;
						font-size: 2.5rem;
						background: #ef3c4d;
						border-radius: 10px;
						padding: 1rem 2rem;
						flex-shrink: 0;
						margin: 1rem 0.8rem;
					}
				}
			}
			.package {
				background: #fff;
				margin-top: 1.5rem;
				padding: 3rem;
				flex-grow: 1;
				display: flex;
				flex-direction: column;
				.packageInfo {
					flex-grow: 1;
				}
				.infoList {
					li {
						font-size: 3rem;
						color: #333;
						display: flex;
						padding: 1rem 0;
						align-items: center;
						span {
							display: inline-block;
							width: 18rem;
							text-align: left;
						}
						input {
							flex-grow: 1;
							border: 2px solid #dcdcdc;
							height: 100px;
							border-radius: 10px;
							font-size: 2.5rem;
							padding-left: 20px;
						}
					}
				}
				.promit {
					color: #999;
					font-size: 2.2rem;
					text-align: left;
					padding: 2rem 0;
				}
				.btn {
					padding: 2rem 0;
					span {
						background: #ef3c4d;
						display: inline-block;
						padding: 2.5rem 8rem;
						color: #fff;
						font-size: 2.8rem;
						border-radius: 10px;
						cursor: pointer;
					}
				}
				.tips {
					margin-top: 2rem;
					background: #f9f9f9;
					padding: 2rem;
					text-align: left;
					color: #999;
					font-size: 2.4rem;
					border-radius: 10px;
					width: 100%;
					p {
						line-height: 6rem;
					}
					ul {
						li {
							line-height: 4.5rem;
						}
					}
				}
			}
		}
	}

	.item-size {
		input {
			width: 80%;
			text-align: center;
			border-bottom: 1px solid gray;
		}
	}

	.reminder {
		flex-grow: 1;
		ul li {
			list-style: decimal;
			margin-left: 30px;
		}
	}

	.my-panel.field {
		text-align: left;
	}

	.my-popup {
		border-radius: 10px 10px 0 0;

		.title {
			margin: 15px 0;
			position: relative;

			span {
				font-weight: 600;
				color: black;
				font-size: 20px;
				vertical-align: middle;
				display: inline-block;
				height: 100%;
				line-height: 100%;

				&.btn {
					position: absolute;
					font-size: 14px;
					font-weight: normal;

					&.cancel {
						left: 10px;
					}

					&.ok {
						right: 10px;
					}
				}
			}
		}

		.line {
			border-bottom: 1px solid rgb(207, 207, 207);
		}
	}

	.required {
		.mint-cell-title {
			.mint-cell-text {
				position: relative;

				&::after {
					content: "*";
					color: red;
					display: inline-block;
					position: absolute;
					top: 0;
					right: -8px;
				}
			}
		}
	}

	.context {
		display: flex;
		flex-direction: column;
		position: fixed;
		height: 100%;
	}

	.flex-btn {
		display: flex;
		flex-direction: column;
	}
</style>
