<template>
	<div class="commentList">
		<emptyInfo v-show="empty"></emptyInfo>
		<div class="mainContent">
			<ul class="commentList">
				<li v-for="(item,index) in commentList" :key="index">
					<div class="userInfo">
						<p>
							<img :src="item.userHeadImg" v-show="item.userHeadImg != null" class="userImg">
							<img src="@/assets/images/logo.png" v-show="item.userHeadImg == null" class="logo">
							<span>{{item.userName}}</span>
						</p>
						<p>{{item.createTime}}</p>
					</div>
					<p class="commentContent">{{item.content}}</p>
					<p></p>
					<p class="imglist">
						<img :src="item2" v-for="(item2,index) in item.picUrl" :key="index" style="width:150px;height:150px;"/>
					</p>
				</li>
			</ul>
			<p class="promit" v-show="pageNo >= totalPage && commentList.length > 10">没有更多内容了呦</p>
			<p class="promit" v-show="commentList.length  == 0">暂无评论信息</p>
		</div>
		<foot-bar :pageIndex="2"></foot-bar>
	</div>
</template>

<script>
import emptyInfo from '@/components/emptyInfo.vue';
import FootBar from "../components/footBar";
export default {
  name: 'commentlist',
  data(){
    return{
		empty:false,
		isEnd:true,
		pageNo:1,
		pageSize:10,
		totalPage:0,
		commentList:[]
    }
  },
  components:{
    FootBar,
	emptyInfo
  },
  created(){
      this.getIndexComment();
  },
  mounted(){
    window.addEventListener('scroll', this.onScroll);
  },
  methods:{
	onScroll() {
      //可滚动容器的高度
      let innerHeight = document.querySelector('#app').clientHeight;
      //屏幕尺寸高度
      let outerHeight = document.documentElement.clientHeight;
      //可滚动容器超出当前窗口显示范围的高度
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop || window.pageYOffset;
      //scrollTop在页面为滚动时为0，开始滚动后，慢慢增加，滚动到页面底部时，出现innerHeight < (outerHeight + scrollTop)的情况，严格来讲，是接近底部。
      if (innerHeight <= (outerHeight + scrollTop + 5)) {
        //加载更多操作
        if(this.pageNo <= this.totalPage && this.isEnd == true){
          this.isEnd = false;
          this.getIndexComment();
        }
      }
    },
	getIndexComment(){
		var that = this;
		var url = '/api/h5/index';
		var data = {
			pageNo:this.pageNo,
			pageSize:this.pageSize
		}
		this.$post(url,data).then(res=>{
			that.isEnd = true;
			if(res.resultCode == 10000){
				that.pageNo += 1;
				that.totalPage = res.result.page.pageCount;
				if(res.result.evaluateList != undefined &&　res.result.evaluateList.length > 0){
					var evaluateList = res.result.evaluateList;
					for(let i=0;i<evaluateList.length;i++){
						if(evaluateList[i].picUrl.length==0){
							evaluateList[i].picUrl=[]
						}else{
							evaluateList[i].picUrl=evaluateList[i].picUrl.split(",")
						}
						
						if(evaluateList[i].createTime == ''){
						evaluateList[i].createTime = '--';
						}else{
						evaluateList[i].createTime = this.$getDate(evaluateList[i].createTime);
						}
					}
					this.commentList = this.commentList.concat(evaluateList);
				}
			}
		})
	}
  },
  destroyed(){
    window.removeEventListener('scroll', this.onScroll)
  }
}
</script>

<style lang="scss">
.imglist{
	img{
		margin-top: 30px;
		margin-right: 30px;
	}
}
.commentList{
	width:100%;
	min-height:100%;
	background: #F6F6F6;
	overflow: scroll;
	padding-bottom:140px;
	.footbar{
		position: fixed;
		left:0;
		right:0;
		bottom:0;
		z-index:100;
	}
	.mainContent{
		.banner{
			background:#fff;
			width:100%;
			img{
				width:100%;
			}
		}
		.serveList{
			background:#fff;
			padding:0 5rem;
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			li{
				flex-shrink: 0;
				width:30%;
				padding:3rem 0;
				img{
					width:10rem;
					height:10rem;
				}
				p{
					padding-top:1rem;
					font-size:3rem;
				}
			}
		}
		.commentList{
			padding:0 1.5rem;
			li{
				margin-top:1.5rem;
				background:#fff;
				border-radius: 1rem;
				padding:2rem;
				text-align: left;
				.commentContent{
					color:#333;
					font-size:12px;
					-webkit-text-size-adjust: none;
					max-height: 200px;
					margin-top: 1.5rem;
				}
				.userInfo{
					padding-top:3rem;
					display: flex;
					justify-content: space-between;
					align-items: center;
					p:nth-child(1){
						display: flex;
						align-items: center;
						.userImg{
							width:4rem;
							height:4rem;
							border-radius: 50%;
							margin-right:2rem;
						}
						.logo{
							width:4rem;
							height:4rem;
							margin-right:2rem;
						}
						span{
							font-size:2rem;
						}
					}
					p:nth-child(2){
						color:#999;
						font-size:2rem;
					}
				}
			}
		}
		.promit{
			color:#999;
			font-size:1.8rem;
			padding:2.5rem 0;
			position:relative;
		}
		.promit:before{
			content:'';
			background:#E5E5E5;
			height:2px;
			width:150px;
			position:absolute;
			top:50%;
			left:calc(50% - 200px);
			transform: translate(-50%,-50%);
		}
		.promit:after{
			content:'';
			background:#E5E5E5;
			height:2px;
			width:150px;
			position:absolute;
			top:50%;
			left:calc(50% + 200px);
			transform: translate(-50%,-50%);
		}
	}
}
.index::-webkit-scrollbar{
	width: 0;
	height:0;
	background-color: transparent;
}
</style>