<template>
	<div class="addressList">
		<emptyInfo v-show="empty" content="请添加收货地址"></emptyInfo>
		<div class="mainContent">
			<div
				class="item"
				v-for="(item, index) in addressList"
				:key="index"
				v-show="!empty"
			>
				<div class="addressView">
					<p>
						<span>{{
							item.recipients.length > 25
								? item.recipients.substring(0, 25) + "..."
								: item.recipients
						}}</span>
					</p>
					<p>
						<span>{{ item.telphone }}</span>
					</p>
					<p>
						{{ item.country }}{{ item.province }}{{ item.city
						}}{{ item.county }}{{ item.address }}
					</p>
				</div>
				<div class="btn">
					<span @click="editAdress(item.addressId)">
						<img src="@/assets/images/edit.png" />
					</span>
					<span @click="openAlert(item.addressId)">
						<img src="@/assets/images/delete.png" />
					</span>
				</div>
			</div>
			<div class="addView">
				<p class="addAdress" @click="editAdress('0')">新增收货地址</p>
			</div>
		</div>
		<div class="alert" v-show="alert">
			<div class="content">
				<p>提示</p>
				<p>确定删除这条地址吗？</p>
				<p>
					<span @click="closeAlert">取消</span>
					<span @click="deleteAdress">确定</span>
				</p>
			</div>
			<span class="lawyer"></span>
		</div>
	</div>
</template>

<script>
	import emptyInfo from "@/components/emptyInfo.vue";
	export default {
		name: "addressList",
		data() {
			return {
				empty: false,
				alert: false,
				isEnd: true,
				addressList: [],
				pageNo: 1,
				pageSize: 10,
				totalPage: 0,
				addressId: "",
			};
		},
		components: {
			emptyInfo,
		},
		created() {
			this.getAddressList();
		},
		mounted() {
			window.addEventListener("scroll", this.onScroll);
		},
		methods: {
			onScroll() {
				//可滚动容器的高度
				let innerHeight = document.querySelector("#app").clientHeight;
				//屏幕尺寸高度
				let outerHeight = document.documentElement.clientHeight;
				//可滚动容器超出当前窗口显示范围的高度
				let scrollTop =
					document.documentElement.scrollTop ||
					document.body.scrollTop ||
					window.pageYOffset;
				//scrollTop在页面为滚动时为0，开始滚动后，慢慢增加，滚动到页面底部时，出现innerHeight < (outerHeight + scrollTop)的情况，严格来讲，是接近底部。
				if (innerHeight <= outerHeight + scrollTop) {
					//加载更多操作
					if (this.pageNo <= this.totalPage && this.isEnd == true) {
						this.isEnd = false;
						this.getAddressList();
					}
				}
			},
			getAddressList() {
				var that = this;
				var url = "/api/h5/addressList";
				var data = {
					pageNo: this.pageNo,
					pageSize: this.pageSize,
				};
				this.$post(url, data).then((res) => {
					that.isEnd = true;
					if (res.resultCode == 10000) {
						that.pageNo += 1;
						that.totalPage = res.result.page.pageCount;
						if (
							res.result.addressList != undefined &&
							res.result.addressList.length > 0
						) {
							this.addressList = this.addressList.concat(
								res.result.addressList
							);
						}
						if (this.addressList.length == 0) {
							this.empty = true;
						}
					} else {
						this.empty = true;
					}
				});
			},
			closeAlert() {
				this.alert = false;
			},
			openAlert(addressId) {
				this.alert = true;
				this.addressId = addressId;
			},
			editAdress(addressId) {
				this.$router.push({
					path: "/addressedit/" + addressId,
				});
			},
			deleteAdress() {
				var that = this;
				var url = "/api/h5/editAddress";
				var data = {
					editType: 3,
					addressId: this.addressId,
				};
				this.$post(url, data).then((res) => {
					if (res.resultCode == 10000) {
						this.pageNo = 1;
						this.alert = false;
						this.addressList = [];
						this.getAddressList();
					}
				});
			},
		},
		destroyed() {
			window.removeEventListener("scroll", this.onScroll);
		},
	};
</script>

<style lang="scss" scoped>
	.addressList {
		position: relative;
		background: #f6f6f6;
		width: 100%;
		min-height: 100%;
		.mainContent {
			width: 100%;
			height: calc(100% - 200px);
			margin-bottom: 200px;
			overflow: auto;
		}
		.item {
			background: #fff;
			margin-bottom: 20px;
			.addressView {
				text-align: left;
				padding: 2rem 6% 2rem 10%;
				border-bottom: 2px solid #eeeeee;
				line-height: 5rem;
				position: relative;
				p:nth-child(1) {
					font-size: 3rem;
					color: #333;
					position: relative;
					word-break: break-all;
					span {
						font-weight: 500;
					}
					span:nth-child(2) {
						padding: 0 2rem;
					}
				}
				p:nth-child(2) {
					overflow: hidden;
					word-break: break-all;
				}
				p:nth-child(2),
				p:nth-child(3) {
					font-size: 2.4rem;
					color: #333;
					word-break: break-all;
				}
				p:nth-child(1):before {
					content: "";
					display: inline-block;
					width: 2rem;
					height: 2.5rem;
					background: url("../assets/images/address.png") no-repeat;
					background-size: 100% 100%;
					position: absolute;
					top: 50%;
					left: -50px;
					transform: translate(0, -50%);
				}
			}
			.btn {
				text-align: right;
				padding: 2rem 6% 2rem 0;
				span {
					padding: 0 1rem;
					display: inline-block;
					width: 15rem;
					img {
						width: 100%;
					}
				}
			}
		}
		.addView {
			width: 100%;
			background: #f6f6f6;
			position: fixed;
			bottom: 0;
			padding: 3rem 0;
			display: flex;
			justify-content: center;
		}
		.addAdress {
			width: 88%;
			color: #fff;
			font-size: 2.5rem;
			text-align: center;
			padding: 14px 0;
			background: #ef3c4d;
			border-radius: 20px;
		}
		.alert {
			position: fixed;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background: rgba(0, 0, 0, 0.5);
			z-index: 9;
			.content {
				width: 60%;
				z-index: 10;
				background: #fff;
				border-radius: 1rem;
				padding-top: 2rem;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				p:nth-child(1) {
					color: #000;
					font-size: 3rem;
				}
				p:nth-child(2) {
					color: #666666;
					font-size: 2.5rem;
					padding: 1rem 0 2rem 0;
				}
				p:nth-child(3) {
					border-top: 1px solid #eeeeee;
					span {
						display: inline-block;
						width: 50%;
						padding: 2rem 0;
						font-size: 2.5rem;
						color: #000;
						text-align: center;
					}
					span:nth-child(1) {
						color: #0080cb;
						border-right: 1px solid #eeeeee;
					}
				}
			}
		}
	}
</style>
