<template>
	<div class="configOrder">
		<div class="adress">
			<div @click="openAdressList" class="div-0">
        <img src="https://jcex-file-service.oss-cn-hangzhou.aliyuncs.com/jc-wms/addrico.png" style="width: 6rem;height: 6rem;">
				<div class="div-1" style="width: 82%;margin-left: 2rem;">
					<span style="font-size: 15px;color:#424242">{{adressInfo.showAdress == ''?'请选择您的收货地址' : adressTem[0]}}</span>
					<br />
					<h1 style="height: 1rem;color: white;" />
					<span style="color: #b2b2b2">{{adressInfo.showAdress == ''?'' : adressTem[1]}}</span>
				</div>
				<img src="@/assets/images/right_arrow_gray.png" class="div-2" />

			</div>
		</div>
		<!-- <p class="tips">温馨提示：集运{{country}}方向包裹单次限重{{limitWeight}}KG</p> -->
		<div class="modal" v-show="modal">
			<div class="modalView">
				<p class="title">
					<span>选择收货地址</span>
					<span @click="closeModal">取消</span>
					<span @click="configSelect">确认</span>
				</p>
				<ul>
					<li v-for="(item,index) in addressList" :key="index" :style="addressList.length - 1 == index?'border:0' : ''" :id="item.addressId" @click="selectAddress($event,item)">
						<p>{{item.recipients.length > 25?item.recipients.substring(0,25) + '...' : item.recipients}}</p>
						<p>{{item.showAdress}}</p>
						<img src="@/assets/images/pay-chose.png" ref="picID" class="selectImg"  v-show="isPic == item.addressId?true:false"/>
						<span  class="startBorder"></span>
						<img src="@/assets/images/edit_icon.png" class="editImg" @click="goEditAdress(item.addressId)" />
					</li>
				</ul>
				<p class="footBtn" @click="goAddAdress">
					<img src="@/assets/images/add_icon.png">
					<span>新增收货地址</span>
				</p>
			</div>
		</div>
    <messageDialog :content='messageContent' v-show="messageDialog"></messageDialog>
	</div>
</template>

<script>
  import messageDialog from '@/components/messageDialog.vue'
	export default {
		name: 'question',
		/* props: {
			addressId: []
		}, */
    components: {
    	messageDialog,
    },
		data () {
			return {
				orderSn: '',
				limitWeight: 30,
				recipients: '',
				telphone: '',
				postCode: '',
				detailInfo: '',
				orderCount: '',
				modal: false,
				pageNo: 1,
				pageSize: 999,
				totalPage: 0,
				addressList: [],
				addressId: '',
				orderSns: '',
				adressInfo: {
					showAdress: [],
				},
				messageDialog: false,
				messageContent: '',
				iconSelect: false,
				country: '中国',
				province: '',
				city: '',
				addressStr: '',
				protoalModal: false,
				adressTem: [],
        isPic:""
			}
		},
		created () { },
		mounted () {
			window.addEventListener('scroll', this.onScroll)
			this.adressTem = this.adressTemParam;
		},
		methods: {
			onScroll () {
				//可滚动容器的高度
				let innerHeight = document.querySelector('#app').clientHeight
				//屏幕尺寸高度
				let outerHeight = document.documentElement.clientHeight
				//可滚动容器超出当前窗口显示范围的高度
				let scrollTop =
					document.documentElement.scrollTop ||
					document.body.scrollTop ||
					window.pageYOffset
				//scrollTop在页面为滚动时为0，开始滚动后，慢慢增加，滚动到页面底部时，出现innerHeight < (outerHeight + scrollTop)的情况，严格来讲，是接近底部。
				if (innerHeight <= outerHeight + scrollTop) {
					//加载更多操作
					if (this.pageNo <= this.totalPage && this.isEnd == true) {
						this.isEnd = false
						this.getAddressList()
					}
				}
			},
			openAdressList () {
				this.modal = true
				var that = this
				var url = '/api/h5/addressList'
				var data = {
					pageNo: this.pageNo,
					pageSize: this.pageSize,
				}
				this.$post(url, data).then((res) => {
					that.isEnd = true
					if (res.resultCode == 10000) {
						that.pageNo += 1
						that.totalPage = res.result.page.pageCount
						if (
							res.result.addressList != undefined &&
							res.result.addressList.length > 0
						) {
							this.addressList = this.addressList.concat(res.result.addressList)
							for (let i = 0; i < this.addressList.length; i++) {
								let addressInfo = this.addressList[i]
								var adress =
									addressInfo.country +
									'  ' +
									addressInfo.province +
									'  ' +
									addressInfo.city +
									'  ' +
									addressInfo.county +
									'  ' +
									addressInfo.address
								if (adress.length > 16) {
									this.addressList[i].showAdress = adress.substring(0, 16) + '...'
								} else {
									this.addressList[i].showAdress = adress
								}
							}
						}
					}
				})
			},
			protoalStatus () {
				if (this.iconSelect == false) {
					this.iconSelect = true
				} else {
					this.iconSelect = false
				}
			},
			openProtoalModal () {
				this.protoalModal = true
			},

      //点击选择地址时
			selectAddress (e,item) {
        if(e.currentTarget.id == item.addressId){
            this.isPic = item.addressId
        }
				this.country = item.country
				this.addressId = item.addressId

				let adress =
					item.recipients +
					'  ' +
					item.telphone +
					',' +
					item.country +
					'  ' +
					item.province +
					'  ' +
					item.city +
					'  ' +
					item.address
				this.adressTem = adress.split(',')
				if (this.adressTem[1].length > 45) {
					this.adressTem[1] = this.adressTem[1].substring(0, 45) + '...'
				}
			},
			configSelect () {

				if (this.addressId != '') {
					var that = this
					var url = '/api/h5/fetchLimitWeightByCountry'
					var data = {
						country: this.country,
					}
					this.$post(url, data).then((res) => {
						if (res.resultCode == 10000) {
							this.limitWeight = (res.result / 1000).toFixed(2)
							this.modal = false
							if (this.adressTem.length > 40) {
								this.adressInfo.showAdress = this.adressTem.substring
							} else {
								this.adressInfo.showAdress = this.adressTem
							}
							this.$emit(
								'selectAddressId',
								this.addressId,
								this.country,
								res.result
							)
						}
            if(res.resultCode ==  1015){
              this.messageContent = '暂无此地区渠道'
              this.messageDialog = true
              var that = this
              setTimeout(function () {
              	that.messageDialog = false
              }, 1000)

            }

						if (res.resultCode == 70009) {
							this.messageContent = '订单已结算'
							this.messageDialog = true
							var that = this
							setTimeout(function () {
								that.messageDialog = false
							}, 1500)
						}
						if (res.resultCode == 70020) {
							this.messageContent = res.reason
							this.messageDialog = true
							var that1 = this
							setTimeout(function () {
								that1.messageDialog = false
								that1.$router.go(-1)
							}, 1500)
						}
					})
				}
			},
			goAddAdress () {
				this.$router.push({
					path: '/addressedit/0',
				})
			},

      //点击修改地址时页面跳转
			goEditAdress (addressId) {
				this.$router.push({
					path: '/addressedit/' + addressId,
				})
			},
			closeModal () {
				if (this.modal == true) {
					this.modal = false
					this.addressId = ''
				}
				if (this.protoalModal == true) {
					this.protoalModal = false
				}
			},
		},
		/* watch: {
			addressId (value) {
				this.configSelect()
			}
		} */
	}
</script>

<style lang="scss" scoped>
  .configOrder .modal .modalView .title span[data-v-710db384]:nth-child(3) {
      color: #ef3c4d;
      font-size: 3rem;
      position: absolute;
      top: 50%;
      left: calc(100% - 12rem)!important;
      -webkit-transform: translate(0, -50%);
      transform: translate(0, -50%);
      padding: 2rem;
  }
  .configOrder .modal .modalView .title span[data-v-710db384]:nth-child(2) {
      color: #ef3c4d;
      font-size: 3rem;
      position: absolute;
      top: 50%;
      left: 2rem!important;
      -webkit-transform: translate(0, -50%);
      transform: translate(0, -50%);
      padding: 2rem;
  }


	.configOrder {
		width: 100%;
		min-height: 100%;
		background: #f6f6f6;
		/*padding-bottom:180px;*/
		.adress {
			padding-left: 3rem;
			background: #fff;
			p {
				padding: 2.5rem 0;
				text-align: left;
			}
			p:nth-child(1) {
				border-bottom: 2px solid #eee;
				color: #333;
				font-size: 3rem;
			}
			p:nth-child(2) {
				color: #999;
				font-size: 2.5rem;
				position: relative;
				padding-right: 6rem;
				.arrow {
					position: absolute;
					top: 50%;
					left: calc(100% - 4.5rem);
					transform: translate(0, -50%);
					width: 1.5rem;
				}
			}
			.div-0 {
				height: 11rem;
				font-size: 2.5rem;
				display: flex;
				align-items: center;
				.div-1 {
					width: 92%;
					float: left;
				}
				.div-2 {
					float: right;
					width: 2rem;
				}
			}
		}
		.tips {
			/*padding:2rem 4% 0 4%;*/
			text-align: left;
			color: #999;
			font-size: 2.5rem;
			border-radius: 10px;
			width: 100%;
			background: #f6f6f6;
			padding-left: 3rem;
			padding-top: 1rem;
		}
		.orderInfo {
			background: #fff;
			margin-top: 2rem;
			padding-left: 3rem;
			li {
				text-align: left;
				padding: 2.5rem 3rem 2.5rem 0;
				font-size: 2.5rem;
				display: flex;
				justify-content: space-between;
				border-bottom: 2px solid #eeeeee;
				color: #333;
				.noInfo {
					color: #999;
				}
			}
		}
		.protoal {
			display: flex;
			align-items: center;
			padding: 3rem;
			span {
				width: 3rem;
				height: 3rem;
				border: 1px solid #b5b5b5;
				border-radius: 50%;
				display: inline-block;
				margin-right: 2rem;
				img {
					width: 100%;
				}
			}
			p {
				font-size: 2.5rem;
				color: #057eed;
			}
		}
		.footPart {
			font-size: 3rem;
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 1.5rem 3rem;
			background: #fff;
			position: fixed;
			bottom: 0;
			width: 100%;
			p:nth-child(1) {
				padding: 3rem 0;
				span:nth-child(1) {
					color: #333;
				}
				span:nth-child(2) {
					color: #ef3c4d;
				}
			}
			p:nth-child(2) {
				padding: 2.5rem 5rem;
				background: #ef3c4d;
				line-height: 1;
				color: #fff;
				border-radius: 10px;
			}
		}
		.modal {
			width: 100%;
			height: 100%;
			background: rgba(0, 0, 0, 0.6);
			position: fixed;
			top: 0;
			left: 0;
			z-index: 4;
			.modalView {
				background: #fff;
				height: 60%;
				width: 100%;
				position: fixed;
				bottom: 0;
				overflow: auto;
				padding-bottom: 50px;
				padding-top: 60px;
				.title {
					position: relative;
					padding: 3rem 0;
					border-bottom: 2px solid #eee;
					position: fixed;
					width: 100%;
					top: 40%;
					left: 0;
					background: #fff;
					z-index: 100;
					text-align: center;
					span:nth-child(1) {
						color: #333;
						font-size: 3rem;
					}
					span:nth-child(2) {
						color: #ef3c4d;
						font-size: 3rem;
						position: absolute;
						top: 50%;
						left: 1rem;
						transform: translate(0, -50%);
						padding: 2rem;
					}
					span:nth-child(3) {
						color: #ef3c4d;
						font-size: 3rem;
						position: absolute;
						top: 50%;
						left: calc(100% - 10rem);
						transform: translate(0, -50%);
						padding: 2rem;
					}
				}
				ul {
					padding-left: 8rem;
					li {
						padding: 2rem 0;
						text-align: left;
						font-size: 3rem;
						color: #333;
						border-bottom: 2px solid #eee;
						position: relative;
						p {
							height: 3.5rem;
							line-height: 3.5rem;
						}
						p:nth-child(2) {
							margin-top: 2rem;
						}
						.selectImg {
							position: absolute;
							width: 4rem;
							height: 4rem;
							top: 50%;
							left: -6rem;
							transform: translate(0, -50%);
						}
						.startBorder {
							position: absolute;
							width: 4rem;
							height: 4rem;
							top: 50%;
							left: -6rem;
							transform: translate(0, -50%);
							border: 2px solid #eee;
							border-radius: 50%;
						}
						.editImg {
							position: absolute;
							width: 4rem;
							height: 4rem;
							top: 50%;
							left: calc(100% - 8rem);
							transform: translate(0, -50%);
						}
					}
				}
				.footBtn {
					position: relative;
					padding: 2rem 0;
					border-top: 2px solid #eee;
					color: #333;
					font-size: 3rem;
					position: fixed;
					bottom: 0;
					width: 100%;
					display: flex;
					justify-content: center;
					align-items: center;
					background: #fff;
					img {
						width: 3rem;
						height: 3rem;
						margin-right: 1rem;
					}
				}
			}
		}
		.protoalModal {
			width: 100%;
			height: 100%;
			background: rgba(0, 0, 0, 0.6);
			position: fixed;
			top: 0;
			left: 0;
			.modalView {
				background: #fff;
				height: 80%;
				width: 90%;
				overflow: auto;
				position: absolute;
				top: 10%;
				left: 50%;
				transform: translate(-50%, 0);
				border-radius: 10px;
				text-align: left;
				padding: 3rem;
				font-size: 2rem;
			}
		}
	}
</style>
