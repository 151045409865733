<template>
    <div class="wrap">
        <headerTitle title='我的积分'>
            <i class="el-icon-question"></i>  <span @click="goIntegralDetail('integralRule')" class="ruleBox">积分规则</span>
        </headerTitle>
        <div class="integralBack">
            <div class="totalBox">
                <span>总积分</span>
                <img src="../assets/images/invit-t2-4.png" alt="">
            </div>
            <div class="integralNum">
                <div class="numBox">{{pointObj.totalPoints}}</div>
                <div @click="goIntegralDetail('integralDetail')" class="integralDetail">积分明细</div>
            </div>
        </div>
        <div class="integralOpe">
            <div class="integralGetBox">
                <div class="integralImg">
                    <img src="../assets/images/integ-1.png" alt="">
                    <div class="withdrawBox">
                        <div>积分可提现</div>
                        <div class="moneyBox">{{pointObj.withdrawalAmount}}元</div>
                    </div>
                </div>

                <div @click="goWithdrawDeposit('withdrawDeposit')" class="withdrawBtn">
                    去提现
                </div>
            </div>
        </div>
        <div class="integralOpe">
            <div class="integralGetBox">
                <div class="integralImg">
                    <img src="../assets/images/integ-2.png" alt="">
                    <div class="withdrawBox">
                        <div>积分可抵扣</div>
                        <div class="deductionBox">{{pointObj.validAmount }}元</div>
                    </div>
                </div>

                <div @click="goTransport('transport')" class="deductionBtn">
                    去使用
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import headerTitle from '../components/headerTitle'
    export default {
        name: "myIntegral",
        components:{
            headerTitle
        },
        data(){
            return {
                userId:localStorage.getItem('userId')||'',
                pointObj:{}
            }
        },
        mounted(){
            this.getMyPoint()
        },
        methods:{
            getMyPoint(){
                this.$get("/api/h5/user/points/home",{
                    uid:this.userId
                }).then(res=>{
                    console.log(res)
                    this.pointObj = res.result||{}
                })
            },
            goWithdrawDeposit(path){
                this.$router.push({
                    path:path
                })
            },
            goTransport(path){
                this.$router.push({
                    path:path,
                    query:{
                        channel:0
                    }
                })
            },
          goIntegralDetail(path){
            this.$router.push({
              path:path
            })
          }
        }
    }
</script>

<style scoped lang="scss">
    .wrap{
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top:0;
        background: #F1EFF2;
    }
.integralBack{
    background: url("../assets/images/integ-bg.png");
    width: 100%;
    height: 26rem;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    box-sizing: border-box;
    padding: 0 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
    .totalBox{
        font-size: 4rem;
        color: white;
        display: flex;
        align-items: center;
        img{
            width: 3rem;
            margin-left: 5px;
        }
    }
    .integralNum{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 5px;
        .numBox{
            color: #fff;
            font-size: 5rem;
        }
        .integralDetail{
            color: #fff;
            font-size: 2rem;
            box-sizing: border-box;
            padding: .8rem 1.5rem;
            border-radius: 8px;
            border: 1px solid #fff;
        }
    }
    .integralOpe{
        margin-top: 3rem;
        margin-bottom: 3rem;
        box-sizing: border-box;
        padding: 0 3rem;
        .integralGetBox{
            background: #fff;
            box-sizing: border-box;
            padding: 2rem;
            border-radius: 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        .integralImg{
            display: flex;
            img{
                width: 9rem;
            }
        }
        .withdrawBox{
            font-size: 2.5rem;
            margin-left: 20px;
            .deductionBox{
                font-size: 3.5rem;
                color: #FAA923;
            }
        }
        .withdrawBox{
            font-size: 2.5rem;
            margin-left: 20px;
            .moneyBox{
                font-size: 3.5rem;
                color: #0C8FE3;
            }
        }
        .withdrawBtn{
            box-sizing: border-box;
            color: #fff;
            font-size: 2.5rem;
            background: #0C8FE3;
            width: 14rem;
            text-align: center;
            border-radius: 6rem;
            height: 4rem;
            line-height: 4rem;
        }
        .deductionBtn{
            box-sizing: border-box;
            color: #fff;
            font-size: 2.5rem;
            background: #FAA923;
            width: 14rem;
            text-align: center;
            border-radius: 6rem;
            height: 4rem;
            line-height: 4rem;
        }
    }
    .ruleBox{
        color: #fff;
        font-size: 2.5rem;
    }
    .el-icon-question{
        color: #fff;
        font-size: 2.5rem;
    }
</style>
