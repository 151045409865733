<template>
  <div class="payway">
    <emptyInfo v-show="empty"></emptyInfo>
    <div v-show="!empty">
      <div class="chose" v-show="type==0">
        <div class="choseView">
          <div class="way">请选择支付方式</div>
          <div class="way"
               @click="payChose(item)"
               v-for="(item ,index) of payList"
               :key="index">
            <img src="@/assets/images/weixin.png"
                 class="way-1"
                 v-show="item.payWay == 1" />
            <p v-show="item.payWay == 2"
               class="taobao">
              <img src="@/assets/images/taobao.png"
                   class="way-2" />
              <span>淘宝</span>
            </p>
            <span>
              <img src='@/assets/images/pay-chose.png'
                   v-show="select==item.payWay" />
            </span>
          </div>
        </div>
        <div class="payBtn" @click="zhifuClick">确定</div>
        <p class="tip">提示： 此订单为二次拍照费用</p>
        <p class="tip ts">备注： 客官，若您选择淘宝支付，需要提供付款订单编号给专属客服处理哦。</p>
      </div>
      <div class="zhifubao"
           v-show="type==1">
        <p>淘宝支付</p>
        <p>{{payUrl}}</p>
        <p>
          <span>说明: 淘宝支付 请跳转淘宝补差价链接进行支付</span>
          <span>
            <img src="@/assets/images/copy.png" @click="copySuccess" />
          </span>
        </p>
      </div>
      <messageDialog :content='messageContent' v-show="promit"></messageDialog>
    </div>
  </div>
</template>

<script>
import { wxConfig } from '@/assets/js/wxConfig'
import { handleClipboard } from '@/assets/js/clipboard'
import emptyInfo from '@/components/emptyInfo.vue'
import messageDialog from '@/components/messageDialog.vue'
import selectChennalShow from '@/pages/selectChennal.vue'
export default {
  name: 'paywayNew',
  data() {
    return {
		rephotofee:1,//拍照单价
      empty: false,
      showCoupon: false,
      showJf: false,
      selectExpressShow: false,
      select: 1,
      type: 0,
      promit: false,
      payUrl: '',
      wxPay: '',
      payAmount1: '',
      time: '',
      payList: [],
      expressList: [],
      expressCode: '',
      expressName: '',
      formula: '', //计算公式
      sumHeavy: 0, //重量
      sumPrice: 0,
      storeOneMoney: 0,
      serviceMoney: 0,
      orderCount: '',
      insuredPremium: 0, //保费
      messageContent: '',
      country: '0',
      minWeight: 0,
      userId:localStorage.getItem('userId')||'',
      pointObj: {}, // 积分对象
      pointsPayUsed: '', //使用的积分
      couponsIdPayUsed: '', // 使用的优惠劵id
      oldCouponsIdPayUsed: '',
      baseList: [
        // {id:1,couamount:10, userlimitnum: '用户无门槛', checked: false},
        // {id:2,couamount:30, userlimitnum: '用户无门槛', checked: false}
      ],
      currentCoupon: {},
      oldCurrentCoupon: {},
      isNoUseCoupon: true,// 是否不使用优惠劵
      oldIsNoUseCoupon: true,
      rate: 0,
      bPrice: 0, // SN0 开头的订单号，所获得的合计运费
      orderSnList: "",
    }
  },
  components: {
    emptyInfo,
    messageDialog,
    selectChennalShow,
  },
  computed: {
    orderAmount () {
      const {orderCount, bPrice, selectExpressShow} = this
      return selectExpressShow ? (orderCount / 100 || 0) : (bPrice / 100 || 0)
    },
    payAmount () {
      const {couponsIdPayUsed, currentCoupon, pointsPayUsed, rate, orderAmount} = this
      let num = orderAmount
      if (couponsIdPayUsed) {
        let couamount = 0
        if (currentCoupon.type == '优惠券') {
           couamount = parseFloat(currentCoupon.couamount) || 0
        }
        if (currentCoupon.type == '打折券') {
           couamount = orderAmount*((10 - parseFloat(currentCoupon.couamount))/10)
        }
        num =  parseFloat(num - (couamount || 0)).toFixed(2)
      }
      if (pointsPayUsed) {
        num =  parseFloat(num - (rate*pointsPayUsed || 0)).toFixed(2)
      }
       return num
    },
    couponAmount () {
      const {currentCoupon, couponsIdPayUsed, orderAmount} = this
      let couamount = 0
      if (couponsIdPayUsed) {
        couamount = currentCoupon.couamount || 0
        if (currentCoupon.type == '优惠券') {
           couamount = parseFloat(currentCoupon.couamount) || 0
        }
        if (currentCoupon.type == '打折券') {
           couamount = orderAmount*((10 - parseFloat(currentCoupon.couamount))/10)
        }
      }
      return couamount.toFixed(2) || 0
    }
  },
  created() {
    //this.orderSn = this.$route.params.id
    //this.wxConfig()
    this.getTypeList()
    var data = JSON.parse(sessionStorage.getItem("data"));
    this.time = data.time
    this.orderSnList = data.orderSnList
    this.payAmount1 = data.payAmount
    this.rephotofee = data.rephotofee
    this.logisticsNumbers = data.logisticsNumbers
  },
  methods: {

   /* wxConfig() {
      var url = window.location.href
      var data = {
        url: url.split('#')[0],
      }
      this.$post('/api/h5/wechatAuthPay', data).then((res) => {
        var signInfo = res.result.weixin
        // eslint-disable-next-line no-undef
        wx.config({
          debug: false,
          appId: signInfo.appId,
          timestamp: signInfo.timestamp,
          nonceStr: signInfo.noncestr,
          signature: signInfo.signature,
          jsApiList: ['chooseWXPay'],
        })
        // eslint-disable-next-line no-undef
        wx.ready(function () {
          // eslint-disable-next-line no-undef
          wx.checkJsApi({
            jsApiList: ['chooseWXPay'],
            success: function (res) {
            },
            fail: function (res) {

            },
          })
        })
      })
    },*/
    /*支付方式*/
    getTypeList() {
     // this.selectExpressShow = this.orderSn.startsWith('SN1')

      var that = this
      var url = '/api/h5/checkstandList'
      var data = {}
      this.$post(url, data).then((res) => {
        if (res.resultCode == 10000) {
          this.payList = res.result.checkstandList
          if (this.payList.length == 0) {
            this.empty = true
          }
        } else {
          this.empty = true
        }
      })
    },
    payChose(item) {
      this.select = item.payWay
    },
    zhifuClick() {
      // var that = this
      var url = '/api/h5/rePhotoToPay'
      var data = {
        orderSn: this.time,
        payWay: this.select,
        payAmount: this.payAmount1*this.rephotofee,
        userId: this.userId,
		//rephotofee:this.rephotofee,
      }
      const {pointsPayUsed, couponsIdPayUsed, payAmount} = this
      if (pointsPayUsed) data.pointsPayUsed = pointsPayUsed
      if (couponsIdPayUsed) data.couponsIdPayUsed = couponsIdPayUsed
      if (payAmount) data.payAmount = payAmount * 100
      this.$post(url, data).then((res) => {
        if (res.resultCode == 10000) {
          this.payUrl = res.result.payUrl
          this.wxPay = res.result.weixin
          if (this.select == 2) {
            this.type = 1
            this.toTbPay()
          } else {
            this.toWxPay(this.logisticsNumbers,this.orderSnList,this.time,this.userId)
          }
        }
        if (res.resultCode == 70009) {
          this.messageContent = '订单已结算'
          this.promit = true
          var that1 = this
          setTimeout(function () {
            that1.promit = false
          }, 1500)
        }
      })
    },
    toTbPay() {
      var that = this
      var url = '/api/h5/taoBaoPay'
      var data = {
        orderSn: this.time,
      }
      this.$post(url, data).then((res) => {
        //if (res.resultCode == 10000) {
        //}
      })
    },
    toWxPay(logisticsNumbers,orderSnList,time,userId) {
      let that = this;
      WeixinJSBridge.invoke(
      'getBrandWCPayRequest', {
          "appId":this.wxPay.appId,     //公众号ID，由商户传入
          "timeStamp": JSON.stringify(this.wxPay.timeStamp), // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
          "nonceStr": this.wxPay.nonceStr, // 支付签名随机串，不长于 32
          "package": this.wxPay.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
          "signType": this.wxPay.signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
          "paySign": this.wxPay.sign, // 支付签名
        },
        function(res){
          if(res.err_msg == "get_brand_wcpay_request:ok" ){
            var data = []
            for (var i = 0;i<logisticsNumbers.length;i++){
              data.push({
                "logisticsNumbers": logisticsNumbers[i],
                "orderSn": orderSnList[i],
                "paySn": time,
                "photoFare": 1,
                "photoNum": 1,
                "userId": userId
              })
            }
            that.$post("/api/h5/rePhoto", data).then((res) => {

            })

          }
        });

    },

    copySuccess() {
      handleClipboard(this.payUrl, event, () => {
        this.messageContent = '复制成功！'
        this.promit = true
        var that = this
        setTimeout(function () {
          that.promit = false
        }, 1500)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.detaileList {
  line-height: 4.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.insured {
  display: inline-block !important;
  width: 100%;
  .insuredSpan {
    display: flex;
    width: 100%;
    span {
      width: 15rem !important;
      line-height: 4.5rem;
    }
  }
}
.orderInfo {
  background: #fff;
  margin-top: 2rem;
  padding-left: 3rem;
  li {
    text-align: left;
    // padding:2.5rem 3rem 2.5rem 0;
    padding: 1rem 3rem 1rem 0;
    font-size: 2.5rem;
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid #eeeeee;
    color: #333;
    .noInfo {
      color: #999;
    }
  }
}
.payway {
  width: 100%;
  height: 100%;
  background: #fff;
  .chose {
    .choseView {
      padding-left: 6%;
      background: #fff;
      .way {
        padding: 3rem;
        border-bottom: 1px solid #e5e5e5;
        color: #333;
        font-size: 3rem;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: space-between;
        justify-content: space-between;
        -moz-box-pack: space-between;
        -webkit--moz-box-pack: space-between;
        box-pack: space-between;
        align-items: center;
        -webkit-align-items: center;
        box-align: center;
        -moz-box-align: center;
        -webkit-box-align: center;
        .way-1 {
          width: 17rem;
        }
        .taobao {
          display: flex;
          align-items: center;
          .way-2 {
            width: 6rem;
            margin-right: 1.5rem;
          }
          span {
            font-size: 2.8rem;
            border: 0;
            display: inline-block;
            width: auto;
          }
        }
        span {

          img{
            width: 100%;
          }
          margin-right: 3rem;
          width: 4rem;
          height: 4rem;
          border-radius: 50%;
          border: 1px solid #d2d2d2;
        }
      }
    }
    .payBtn {
      margin: 3rem 6%;
      background: #ef3c4d;
      color: #fff;
      text-align: center;
      padding: 2rem 0;
      font-size: 3rem;
      border-radius: 1rem;
    }
    .tip {
      margin-top: 50px;
      font-size: 16px;
      padding: 0 6%;
      text-align: left;
      line-height: 30px;
      color: #999;
      &.ts {
        margin-top: 10px;
      }
    }
  }
  .zhifubao {
    padding-left: 6%;
    text-align: left;
    background: #fff;
    height: 100%;
    width: 100%;
    p:nth-child(1) {
      padding: 3rem 2rem;
      font-size: 3rem;
      color: #333;
      border-bottom: 1px solid #d2d2d2;
    }
    p:nth-child(2) {
      padding: 2rem;
      font-size: 2.5rem;
      color: #333;
      line-height: 4rem;
      word-wrap: break-word;
      word-break: normal;
    }
    p:nth-child(3) {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: space-between;
      justify-content: space-between;
      -moz-box-pack: space-between;
      -webkit--moz-box-pack: space-between;
      box-pack: space-between;
      align-items: center;
      -webkit-align-items: center;
      box-align: center;
      -moz-box-align: center;
      -webkit-box-align: center;
      color: #999999;
      font-size: 2.1rem;
      padding-right: 6%;
      span:nth-child(1) {
        padding: 0 2rem;
      }
      img {
        width: 3rem;
      }
    }
    p:nth-child(4) {
      width: 65%;
      margin: 3rem auto;
      padding: 2rem 0;
      text-align: center;
      background: rgba(0, 0, 0, 0.5);
      color: #fff;
      border-radius: 20rem;
      font-size: 3rem;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .pay-info {
     overflow:hidden;
     padding: 0 2rem;
     box-sizing: border-box;
     width: 100%;
     padding-top: 2rem;
     .info-list {
       display: flex;
       justify-content: space-between;
       align-items: center;
       height: 6rem;
       .tis {
         font-size: 3rem;
         color: #333;
       }
       .ct {
         font-size: 3rem;
         color: #999;
         display: flex;
         align-items: center;
         &.special {
           color: #ef3c4d;
           font-size: 4rem;
         }
       }
     }
  }
}
.choice-coupon {
  width: 100%;
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  overflow: hidden;
  font-size: 0;
  .title {
    font-size: 4rem;
    height: 10rem;
    background: #fff;
    line-height: 10rem;
    border-radius: 2rem  2rem 0 0;
    color: #333;
    font-weight: 600;
  }
  .coupon-list {
    overflow: hidden;
    background: #fff;
    max-height: 40rem;
    overflow-y:scroll;
    .list-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 8rem;
      font-size: 3rem;
      color: #333;
      padding: 0 2rem;
      .r {
         .van-icon-checked {
           color: green;
         }
      }
    }
  }
  .control {
    height: 18rem;
    line-height: 18rem;
    text-align:center;
    background: #fff;
    .confirm {
      font-size: 3rem;
      display: inline-block;
      width: 60%;
      height: 8rem;
      line-height: 8rem;
      text-align: center;
      border-radius:4rem;
      background:#ef3c4d;
      color: #fff;
      font-weight:600;
    }
  }
  .inner-jf {
    padding:6rem 10rem;
    overflow: hidden;
    background: #fff;
    input {
      display: inline-block;
      width: 100%;
      height: 8rem;
      border:1px solid  #aaa;
      border-radius: 0.5rem;
      color:#333;
      font-size: 3rem;
      padding-left: 2rem;
    }
    .all-num {
      font-size: 3rem;
      margin-top: 3rem;
      .num {
        color: #ef3c4d;
        font-weight: 600;
      }
    }
  }
}
</style>
