<template>
  <div class="payway">
    <emptyInfo v-show="empty"></emptyInfo>
    <div v-show="!empty">
      <div class="chose"
           v-show="type==0">
        <div class="choseView">

          <div class="way">请选择支付方式</div>
          <div class="way"
               @click="payChose(item)"
               v-for="(item ,index) of payList"
               :key="index">
<!--            <img src="@/assets/images/weixin1.png"
                 class="way-1"
                 v-show="item.payWay == 1" />-->
            <p v-show="item.payWay == 1"
               class="taobao">
              <img src="@/assets/images/weixin1.png"
                   class="way-1" />
              <span>微信支付</span>
            </p>
            <p v-show="item.payWay == 2"
               class="taobao">
              <img src="@/assets/images/taobao1.png"
                   class="way-2" />
              <span>淘宝【好评立返10元优惠券】</span>
            </p>
            <span>
              <img src='@/assets/images/pay-chose.png'
                   v-show="select==item.payWay" />
            </span>
          </div>
          <div class="pay-info">
              <div class="info-list">
                 <span class="tis">合计运费</span>
                 <div class="ct special">￥{{ jine }}</div>
              </div>
              <div class="info-list" v-show="select!==2">
                 <span class="tis">优惠劵</span>
                 <div class="ct" @click="showCoupon = true"><span v-if="!couponsIdPayUsed">选择优惠劵</span><span v-else style="color:#333;">-￥{{couponAmount}}</span><van-icon name="arrow"  /></div>
              </div>
              <div class="info-list" v-show="select!==2">
                 <span class="tis">积分</span>
                 <div class="ct" @click="showJf = true"><span v-if="!pointsPayUsed">共{{pointObj.totalPoints || 0}}积分可用</span><span v-if="pointsPayUsed">使用了{{pointsPayUsed}}积分</span><van-icon name="arrow"  /></div>
              </div>
          </div>
        </div>
        <p class="tip1" style="color: #DD7F24">提示： 优惠劵与积分只能使用一个，不能同时使用。</p>
        <div class="payBtn"
             @click="zhifuClick">确定</div>
        <p class="tip ts">备注： 客官，若您选择淘宝支付，需要提供付款订单编号给专属客服处理哦。</p>
      </div>
      <div class="zhifubao"
           v-show="type==1">
        <p>淘宝支付</p>
        <p>{{payUrl}}</p>
        <p>
          <span>说明: 淘宝支付 请跳转淘宝补差价链接进行支付</span>
          <span>
            <img src="@/assets/images/copy.png"
                 @click="copySuccess" />
          </span>
        </p>
      </div>
      <messageDialog :content='messageContent'
                     v-show="promit"></messageDialog>
    </div>
    <van-overlay :show="showCoupon" @click="showCoupon = false,resetChoice()"  :lock-scroll="false">
       <div class="choice-coupon" @click.stop>
          <div class="title">
            选择优惠劵
          </div>
          <div class="coupon-list">
            <div class="list-item" v-for="(item, i) in baseList" :key="i" @click="choiceCoupons(item, i)">
              <span class="tis">{{item.couponsname}}</span>
              <div class="r">
                <van-icon name="circle" v-if="couponsIdPayUsed != item.id" />
                <van-icon name="checked" v-else/>
              </div>
            </div>
             <div class="list-item" @click="choiceNoUse()">
                <span class="tis">不使用优惠劵</span>
                <div class="r">
                  <van-icon name="circle" v-if="isNoUseCoupon"/>
                  <van-icon name="checked" v-else  />
                </div>
             </div>
          </div>
          <div class="control">
            <span class="confirm" @click="confirmChoiceCoupon">完成</span>
          </div>
       </div>
    </van-overlay>
    <van-overlay  :show="showJf"  @click=" pointsPayUsed = ''">
       <div class="choice-jf choice-coupon" @click.stop>
          <div class="title">
            填写抵用积分
          </div>
          <div class="inner-jf">
             <input type="number" placeholder="填写使用积分"  v-model.trim="pointsPayUsed">
             <div class="all-num">
               共<span class="num">{{pointObj.totalPoints || 0}}</span>积分可用
             </div>
              <div class="all-num">
                【该订单最多可用<span class="num">{{ parseInt((myjine / 1.2) * 100) > pointObj.totalPoints ? pointObj.totalPoints - 1 : parseInt((myjine / 1.2) * 100) - 1 }}</span>积分】
              </div>

          </div>
          <div class="control">
            <span class="confirm" @click="confirmSaveRate">完成</span>
          </div>
       </div>
    </van-overlay>
  </div>
</template>

<script>
import { wxConfig } from '@/assets/js/wxConfig'
import { handleClipboard } from '@/assets/js/clipboard'
import emptyInfo from '@/components/emptyInfo.vue'
import messageDialog from '@/components/messageDialog.vue'
import selectChennalShow from '@/pages/selectChennal.vue'
export default {
  name: 'payway',
  data() {
    return {
      empty: false,
      showCoupon: false,
      showJf: false,
      selectExpressShow: false,
      select: 1,
      type: 0,
      promit: false,
      payUrl: '',
      wxPay: '',
      payList: [],
      expressList: [],
      expressCode: '',
      expressName: '',
      formula: '', //计算公式
      sumHeavy: 0, //重量
      sumPrice: 0,
      storeOneMoney: 0,
      serviceMoney: 0,
      orderCount: '',
      insuredPremium: 0, //保费
      messageContent: '',
      country: '0',
      minWeight: 0,
      userId:localStorage.getItem('userId')||'',
      pointObj: {}, // 积分对象
      pointsPayUsed: '', //使用的积分
      couponsIdPayUsed: '', // 使用的优惠劵id
      oldCouponsIdPayUsed: '',
      baseList: [
        // {id:1,couamount:10, userlimitnum: '用户无门槛', checked: false},
        // {id:2,couamount:30, userlimitnum: '用户无门槛', checked: false}
      ],
      currentCoupon: {},
      oldCurrentCoupon: {},
      isNoUseCoupon: true,// 是否不使用优惠劵
      oldIsNoUseCoupon: true,
      rate: 0,
      bPrice: 0, // SN0 开头的订单号，所获得的合计运费
      jine:"",
      myjine:"",
      Bjine:"",
      conunt:0,
    }
  },
  components: {
    emptyInfo,
    messageDialog,
    selectChennalShow,
  },
  computed: {
    orderAmount () {

      const {orderCount, bPrice, selectExpressShow} = this
      return selectExpressShow ? (orderCount / 100 || 0) : (bPrice / 100 || 0)
    },
    payAmount () {
      const {couponsIdPayUsed, currentCoupon, pointsPayUsed, rate, orderAmount} = this
      let num = orderAmount
      if (couponsIdPayUsed) {
        let couamount = 0
        if (currentCoupon.type == '优惠券') {
           couamount = parseFloat(currentCoupon.couamount) || 0
        }
        if (currentCoupon.type == '打折券') {
           couamount = orderAmount*((10 - parseFloat(currentCoupon.couamount))/10)
        }
        num =  parseFloat(num - (couamount || 0)).toFixed(2)
      }
      if (pointsPayUsed) {
        num =  parseFloat(num - (rate*pointsPayUsed || 0)).toFixed(2)
      }
       return num
    },
    couponAmount () {
      const {currentCoupon, couponsIdPayUsed, orderAmount} = this
      let couamount = 0
      if (couponsIdPayUsed) {
        couamount = currentCoupon.couamount || 0
        if (currentCoupon.type == '优惠券') {
           couamount = parseFloat(currentCoupon.couamount) || 0
        }
        if (currentCoupon.type == '打折券') {
           couamount = orderAmount*((10 - parseFloat(currentCoupon.couamount))/10)
        }
      }
      return couamount.toFixed(2) || 0
    }
  },
  created() {
    this.orderSn = this.$route.params.id
    this.jine = ((this.$route.params.jine)/100).toFixed(2)
    this.myjine = ((this.$route.params.jine)/100).toFixed(2)
    this.Bjine = ((this.$route.params.jine)/100).toFixed(2)
    this.selectAllowExpressList()
    this.wxConfig()
    this.getTypeList()
    this.getMyPoint()
    this.getRate()
  },
  methods: {
    // 获取利率
    getRate(){
        this.$get("/api/h5/user/points/order/rate",{
            uid:this.userId
        }).then(res=>{
            if (res.resultCode == 10000) {
              this.rate = res.result || 0
            }
        })
    },
    choiceNoUse () {
       this.isNoUseCoupon = !this.isNoUseCoupon
       if (!this.isNoUseCoupon) {
         this.currentCoupon = {}
         this.couponsIdPayUsed = ''
       }
    },
    // 重置选择
    resetChoice () {
      this.currentCoupon = this.oldCurrentCoupon
      this.couponsIdPayUsed = this.oldCouponsIdPayUsed
      this.isNoUseCoupon  = this.oldIsNoUseCoupon
    },


    // 确定要获取兑换率
    confirmSaveRate () {
       this.showJf = false
       if (!this.pointsPayUsed){
         this.jine = this.myjine
         return
       }
       let maxPoints =  parseInt((this.myjine / 1.2) * 100) > this.pointObj.totalPoints ? this.pointObj.totalPoints - 1 : parseInt((this.myjine / 1.2) * 100) - 1
       if (maxPoints < this.pointsPayUsed) {
         this.messageContent = '兑换积分不得超过' + maxPoints + '分'
         if(this.couponAmount >0 ){
           this.jine = (this.myjine - this.couponAmount).toFixed(2)
         }else {
           this.jine = this.myjine
         }

          this.promit = true
          var that = this
          setTimeout(function () {
            that.promit = false
          }, 1500)
          this.pointsPayUsed = ''
          return
       }
       const {orderAmount, rate, pointsPayUsed} = this
       if (pointsPayUsed*rate > this.myjine) {
         this.messageContent = '兑换积分优惠金额不得大于订单金额'
         this.jine = this.myjine
          this.promit = true
          var that = this
          setTimeout(function () {
            that.promit = false
          }, 1500)
          this.pointsPayUsed = ''
          return
       }else{
         this.conunt ++
         if(this.conunt>1){
            this.jine = this.myjine
            this.jine =(Number(this.jine) - Number(pointsPayUsed*rate)).toFixed(2)
         }else{
           this.jine =(Number(this.jine) - Number(pointsPayUsed*rate)).toFixed(2)
         }
       }



       if (this.pointsPayUsed) {
         this.oldCurrentCoupon = {}
         this.oldCouponsIdPayUsed = ''
         this.currentCoupon = {}
         this.couponsIdPayUsed = ''
       }
    },
    // 确定选择优惠劵
    confirmChoiceCoupon () {
      const {orderAmount, currentCoupon} = this
      let couamount = 0
      if (currentCoupon.type == '优惠券') {
          couamount = parseFloat(currentCoupon.couamount)

          if (currentCoupon.userlimitnum) {
            if (this.jine < currentCoupon.userlimitnum) {
                this.messageContent = '订单金额没有满'+currentCoupon.userlimitnum+'元，不能使用'
                this.promit = true
                var that = this
                setTimeout(function () {
                  that.promit = false
                }, 1500)
                this.oldCurrentCoupon = {}
                this.oldCouponsIdPayUsed = ''
                this.currentCoupon = {}
                this.couponsIdPayUsed = ''
                this.showCoupon = false
                return
            }
          }
      }
      if (currentCoupon.type == '打折券') {
          couamount = orderAmount*((10 - parseFloat(currentCoupon.couamount))/10)
          if (currentCoupon.userlimitnum) {
            if (this.jine < currentCoupon.userlimitnum) {
                this.messageContent = '订单金额没有满'+currentCoupon.userlimitnum+'元，不能使用'
                this.promit = true
                var that = this
                setTimeout(function () {
                  that.promit = false
                }, 1500)
                this.oldCurrentCoupon = {}
                this.oldCouponsIdPayUsed = ''
                this.currentCoupon = {}
                this.couponsIdPayUsed = ''
                this.showCoupon = false
                return
            }
          }
      }
      if (couamount > this.Bjine) {
          this.messageContent = '选择的优惠劵优惠劵金额不得大于订单金额'
          this.promit = true
          var that = this
          setTimeout(function () {
            that.promit = false
          }, 1500)
          this.oldCurrentCoupon = {}
          this.oldCouponsIdPayUsed = ''
          this.currentCoupon = {}
          this.couponsIdPayUsed = ''
          this.showCoupon = false
          return
       } else {


          this.conunt ++
          if(this.conunt>1){

             this.jine = (Number(this.Bjine) - Number(couamount)).toFixed(2)

          }else{
            this.jine = (Number(this.Bjine) - Number(couamount)).toFixed(2)

          }


          this.oldCurrentCoupon = this.currentCoupon
          this.oldCouponsIdPayUsed = this.couponsIdPayUsed
          this.oldIsNoUseCoupon = this.isNoUseCoupon
          this.pointsPayUsed = ''
          this.showCoupon = false
       }
    },
    choiceCoupons (item) {

      this.couponsIdPayUsed = item.id
      this.currentCoupon = item
      this.isNoUseCoupon = true
    },
    // 获取优惠劵列表
    baseFun(totalpay){
          this.$post('/api/h5/chooseCouponsToPay', { userId: this.userId, totalpay }).then(res=>{
            if(res.resultCode == 10000){
                let list = res.result || []
                list = list.filter(rr => rr.couponsname !='不使用优惠券')
                this.baseList = list
            }
        })
    },
    // 获取积分
    getMyPoint(){
        this.$get("/api/h5/user/points/home",{
            uid:this.userId
        }).then(res=>{
            this.pointObj = res.result||{}
        })
    },
    getSelectAddressId: function (param) {
      this.expressCode = param[0]
      this.expressName = param[1]
      if (this.expressCode == '') {
        return
      }
      this.selectExpressToCalculation(param[0])
    },
    wxConfig() {
      var url = window.location.href
      var data = {
        url: url.split('#')[0],
      }
      this.$post('/api/h5/wechatAuthPay', data).then((res) => {
        var signInfo = res.result.weixin
        // eslint-disable-next-line no-undef
        wx.config({
          debug: false,
          appId: signInfo.appId,
          timestamp: signInfo.timestamp,
          nonceStr: signInfo.noncestr,
          signature: signInfo.signature,
          jsApiList: ['chooseWXPay'],
        })
        // eslint-disable-next-line no-undef
        wx.ready(function () {
          // eslint-disable-next-line no-undef
          wx.checkJsApi({
            jsApiList: ['chooseWXPay'],
            success: function (res) {

            },
            fail: function (res) {

            },
          })
        })
      })
    },
    getTypeList() {
      /* this.selectExpressShow = this.orderSn.startsWith('SN1')
 */
      var that = this
      var url = '/api/h5/checkstandList'
      var data = {}
      this.$post(url, data).then((res) => {
        if (res.resultCode == 10000) {
          this.payList = res.result.checkstandList
          if (this.payList.length == 0) {
            this.empty = true
          }
        } else {
          this.empty = true
        }
      })
    },
    payChose(item) {
      this.jine = this.myjine
      this.select = item.payWay
    },
    zhifuClick() {

      // var that = this
      var url = '/api/h5/toPay'
      var data = {
        orderSn: this.orderSn,
        payWay: this.select,
        //payAmount:300,
      }
      const {pointsPayUsed, couponsIdPayUsed, payAmount} = this
      if (pointsPayUsed) data.pointsPayUsed = pointsPayUsed
      if (couponsIdPayUsed) data.couponsIdPayUsed = couponsIdPayUsed
      if (payAmount) data.payAmount = payAmount * 100
      this.$post(url, data).then((res) => {
        if (res.resultCode == 10000) {
          this.payUrl = res.result.payUrl
          this.wxPay = res.result.weixin
          if (this.select == 2) {
            this.type = 1
            this.toTbPay()
          } else {
            this.toWxPay()
          }
        }
        if (res.resultCode == 70009) {
          this.messageContent = '订单已结算'
          this.promit = true
          var that1 = this
          setTimeout(function () {
            that1.promit = false
          }, 1500)
        }
      })
    },
    toTbPay() {
      var that = this
      var url = '/api/h5/taoBaoPay'
      var data = {
        orderSn: this.orderSn,
      }
      this.$post(url, data).then((res) => {
        //if (res.resultCode == 10000) {
        //}
      })
    },
    toWxPay() {
      var that = this
      // eslint-disable-next-line no-undef
      WeixinJSBridge.invoke(
        'getBrandWCPayRequest', {
          "appId":this.wxPay.appId,     //公众号ID，由商户传入
          "timeStamp": JSON.stringify(this.wxPay.timeStamp), // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
          "nonceStr": this.wxPay.nonceStr, // 支付签名随机串，不长于 32
          "package": this.wxPay.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
          "signType": this.wxPay.signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
          "paySign": this.wxPay.sign, // 支付签名
        },
        function(res){
          if(res.err_msg == "get_brand_wcpay_request:ok" ){

            that.$post('/api/h5/toPaySuccess  ', {
              orderSn:that.orderSn
            }).then((res) => {

            })

          }
        });
    },
    copySuccess() {
      handleClipboard(this.payUrl, event, () => {
        this.messageContent = '复制成功！'
        this.promit = true
        var that = this
        setTimeout(function () {
          that.promit = false
        }, 1500)
      })
    },
    selectExpressToCalculation(expressCode) {
      //选择出货渠道后返回计算结果
      var url = '/api/h5/selectExpressToCalculation'
      var data = {
        expressCode: expressCode,
        orderSn: this.orderSn,
      }
      //                let that = this;
      this.$post(url, data).then((res) => {
        this.formula = res.result.formula
        this.sumHeavy = res.result.sumHeavy
        this.sumPrice = res.result.sumPrice
        this.storeOneMoney = res.result.storeOneMoney
        this.serviceMoney = res.result.serviceMoney
        this.insuredPremium = res.result.insuredPremium
        this.orderCount =
          this.sumPrice +
          this.storeOneMoney +
          this.serviceMoney +
          this.insuredPremium
      })
    },

    selectAllowExpressList() {
      //根据国家和限重获取可使用的渠道列表
      var url = '/api/platExpress/selectAllowExpressList'
      var data = {
        country: '0',
        minWeight: 0,
        orderSn: this.orderSn,
      }
      let that = this
      this.$post(url, data).then((res) => {

        console.log(res)
        that.expressList = res.result.expressList
       // that.bPrice = res.result.fixedPrice || 0
        that.baseFun(that.bPrice)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.detaileList {
  line-height: 4.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.insured {
  display: inline-block !important;
  width: 100%;
  .insuredSpan {
    display: flex;
    width: 100%;
    span {
      width: 15rem !important;
      line-height: 4.5rem;
    }
  }
}
.orderInfo {
  background: #fff;
  margin-top: 2rem;
  padding-left: 3rem;
  li {
    text-align: left;
    // padding:2.5rem 3rem 2.5rem 0;
    padding: 1rem 3rem 1rem 0;
    font-size: 2.5rem;
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid #eeeeee;
    color: #333;
    .noInfo {
      color: #999;
    }
  }
}
.payway {
  width: 100%;
  height: 100%;
  background: #fff;
  .chose {
    .choseView {
      padding-left: 6%;
      background: #fff;
      .way {
        padding: 3rem;
        border-bottom: 1px solid #e5e5e5;
        color: #333;
        font-size: 3rem;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: space-between;
        justify-content: space-between;
        -moz-box-pack: space-between;
        -webkit--moz-box-pack: space-between;
        box-pack: space-between;
        align-items: center;
        -webkit-align-items: center;
        box-align: center;
        -moz-box-align: center;
        -webkit-box-align: center;
        img{
          width: 21px;
          height:6rem;
        }
        .way-1 {
          width: 17rem;
        }
        .taobao {
          display: flex;
          align-items: center;
          .way-2 {
            width: 6rem;
            margin-right: 1.5rem;
          }
          .way-1 {
            width: 6rem;
            margin-right: 1.5rem;
          }
          span {
            font-size: 2.8rem;
            border: 0;
            display: inline-block;
            width: auto;
          }
        }
        span {
          margin-right: 3rem;
          width: 3rem;
          height: 3rem;
          border-radius: 50%;
          border: 1px solid #d2d2d2;
          img{
            width: 3.2rem;
            height: 3.2rem;
          }
        }
      }
    }
    .payBtn {
      margin: 3rem 6%;
      background: #ef3c4d;
      color: #fff;
      text-align: center;
      padding: 2rem 0;
      font-size: 3rem;
      border-radius: 1rem;
    }
    .tip1 {
      margin-top: 15px;
      font-size: 16px;
      padding: 0 6%;
      text-align: left;
      line-height: 25px;
      color: #999;
    }
    .tip {
      margin-top: 50px;
      font-size: 16px;
      padding: 0 6% 15px 6%;
      text-align: left;
      line-height: 25px;
      color: #999;
      &.ts {
        margin-top: 0px;
      }
    }
  }
  .zhifubao {
    padding-left: 6%;
    text-align: left;
    background: #fff;
    height: 100%;
    width: 100%;
    p:nth-child(1) {
      padding: 3rem 2rem;
      font-size: 3rem;
      color: #333;
      border-bottom: 1px solid #d2d2d2;
    }
    p:nth-child(2) {
      padding: 2rem;
      font-size: 2.5rem;
      color: #333;
      line-height: 4rem;
      word-wrap: break-word;
      word-break: normal;
    }
    p:nth-child(3) {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: space-between;
      justify-content: space-between;
      -moz-box-pack: space-between;
      -webkit--moz-box-pack: space-between;
      box-pack: space-between;
      align-items: center;
      -webkit-align-items: center;
      box-align: center;
      -moz-box-align: center;
      -webkit-box-align: center;
      color: #999999;
      font-size: 2.1rem;
      padding-right: 6%;
      span:nth-child(1) {
        padding: 0 2rem;
      }
      img {
        width: 3rem;
      }
    }
    p:nth-child(4) {
      width: 65%;
      margin: 3rem auto;
      padding: 2rem 0;
      text-align: center;
      background: rgba(0, 0, 0, 0.5);
      color: #fff;
      border-radius: 20rem;
      font-size: 3rem;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .pay-info {
     overflow:hidden;
     padding: 0 2rem;
     box-sizing: border-box;
     width: 100%;
     padding-top: 2rem;
     .info-list {
       display: flex;
       justify-content: space-between;
       align-items: center;
       height: 6rem;
       .tis {
         font-size: 3rem;
         color: #333;
       }
       .ct {
         font-size: 3rem;
         color: #999;
         display: flex;
         align-items: center;
         &.special {
           color: #ef3c4d;
           font-size: 4rem;
         }
       }
     }
  }
}
.payway .chose .choseView .way span[data-v-10dd656a] {
  margin-right: 1rem!important;
  margin-left: 1rem!important;
}
.choice-coupon {
  width: 100%;
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  overflow: hidden;
  font-size: 0;
  .title {
    font-size: 4rem;
    height: 10rem;
    background: #fff;
    line-height: 10rem;
    border-radius: 2rem  2rem 0 0;
    color: #333;
    font-weight: 600;
  }
  .coupon-list {
    overflow: hidden;
    background: #fff;
    max-height: 40rem;
    overflow-y:scroll;
    .list-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 8rem;
      font-size: 3rem;
      color: #333;
      padding: 0 2rem;
      .r {
         .van-icon-checked {
           color: green;
         }
      }
    }
  }
  .control {
    height: 18rem;
    line-height: 18rem;
    text-align:center;
    background: #fff;
    .confirm {
      font-size: 3rem;
      display: inline-block;
      width: 60%;
      height: 8rem;
      line-height: 8rem;
      text-align: center;
      border-radius:4rem;
      background:#ef3c4d;
      color: #fff;
      font-weight:600;
    }
  }
  .inner-jf {
    padding:6rem 10rem;
    overflow: hidden;
    background: #fff;
    input {
      display: inline-block;
      width: 100%;
      height: 8rem;
      border:1px solid  #aaa;
      border-radius: 0.5rem;
      color:#333;
      font-size: 3rem;
      padding-left: 2rem;
    }
    .all-num {
      font-size: 2rem;
      margin-top: 3rem;
      .num {
        color: #ef3c4d;
        font-weight: 600;
      }
    }
  }
}
</style>
