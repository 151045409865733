<template>
	<div class="emptyInfo">
        <div class="contentView">
            <img src="https://jcex-file-service.oss-cn-hangzhou.aliyuncs.com/jc-wms/nopackage.png">
            <p class="Pstyle" v-show="content == '' || content == undefined">客官，您还没有此类包裹哦。</p>
            <p class="pagestip" v-show="content == '' || content == undefined">请查看<span @click="changpage()" style="color: #409EFF;">待付款</span>订单及时付款。</p>
            <p class="Pstyle" v-show="content != '' && content != undefined">{{content}}</p>
        </div>
    </div>
</template>

<script>
export default {
  name: 'emptyInfoDFK',
  data(){
    return{

    }
  },
  props:['content'],
  methods:{
    changpage(){
      // console.log('跳转到付款页面')
      this.$router.push({
      	path: '/waitpayment'
      })
    }
  }
}
</script>

<style scoped lang="scss">
.emptyInfo{
    .contentView{
        position:absolute;
        left:50%;
        top:15rem;
        transform: translate(-50%,0);
        text-align: center;
        img{
            width:13rem;
            height:13rem;
        }
        .Pstyle{
            font-size:2.2rem;
            padding-top:5rem;
        }
        .pagestip{
          font-size:2.2rem;
          padding-top:1rem;
        }
    }
}
</style>
