<template>
	<div class="packageCheck">
    <emptyInfo v-show="empty"></emptyInfo>
    <div class="mainContent" v-show="!empty">
      <p class="wenx">温馨提示：</p>
      <p class="wenx">请仔细核对您的包裹，如需重新拍照核对，需另支付增值服务费</p>
      <div class="list">
        <div class="item" v-for="(item,index) of orderList" :key="index">
          <div class="left">
						<span @click="payChose(item)">
							<img src="@/assets/images/pay-chose.png" alt="" v-show="item.select">
						</span>
          </div>
          <div class="right">
            <div class="head">
              <p class="logisticsNumber">
                <span>国内快递：{{item.logisticsNumbers == null?'暂无' : item.logisticsNumbers != null && item.logisticsNumbers.length > 18?item.logisticsNumbers.substring(0,18) + '...' : item.logisticsNumbers}}</span>
                <img src="https://jcex-file-service.oss-cn-hangzhou.aliyuncs.com/jc-wms/copy.png" @click="copyContent(item.logisticsNumbers)" class="copyImg" v-show="item.logisticsNumbers != null">
              </p>
              <p style="font-size:2.0rem">{{item.entryTimeStr}}</p>
            </div>
            <div class="line"></div>
            <div class="content">
              <p class="package">
                <img src="https://jcex-file-service.oss-cn-hangzhou.aliyuncs.com/jc-wms/pic.jpg" v-show="item.parcelImgList == null">

                <template v-show="item.parcelImgList != null">

                  <el-carousel height="150px" :autoplay="false" arrow="always" indicator-position="outside">
                    <el-carousel-item v-for="(itemImg,indexImg) in item.parcelImgList" :key="indexImg">
                      <div class="demo-image__preview">
                        <el-image
                          :src="itemImg"
                          :preview-src-list="item.parcelImgList">
                        </el-image>
                      </div>
                    </el-carousel-item>
                  </el-carousel>
                </template>
              </p>
            </div>
            <div style="height: 5.3rem;">
            	<div class="viceDiv_1" style="width: 45%;padding-top: 1rem;font-size: 2.3rem;">
            		体积重量{{item.volumeWeight/1000}}(kg)
            	</div>
            	<div class="viceDiv_2" style="width:45%;float: right;padding-top: 1rem;font-size: 2.3rem;">
            		实际重量{{item.weight/1000}}(kg)
            	</div>
            </div>

            <div class="packageWeight">
            	<div class="viceDiv_3" style="float: right; width:45%">
            		入库计费重量：{{item.billWeight/1000}}(kg)
            	</div>
            </div>
          </div>
        </div>
      </div>
      <div class="foot">
        <div class="chose">
					<span @click="ChoseAll">
						<img src="@/assets/images/pay-chose.png" alt="" v-if="type">
					</span>
          <p>全选</p>
        </div>
        <div>
          <div class="btn" style="background:#FCA824 ;border-radius:4rem ;"  @click="openModal">重新拍照</div>
          <router-link to="/transport">
            <div class="btn"  style="background:#0A7DDE ;border-radius:4rem ;" >核对无误</div>
          </router-link>

        </div>
      </div>
      <div class="modal" v-show="modal">
        <div class="content">
          <p @click="closeModal">
            <img src="@/assets/images/close_gray.png" class="closeIcon">
          </p>
          <p>您将申请{{number}}个货物重新拍照，重新拍照将收取共{{(number*rephotofee/100).toFixed(2)}}元费用<span style="color: red;">(付款后请及时主动联系您的客服拍照)</span></p>
          <p>
            <span @click="goPay()">确定支付</span>
            <span @click="closeModal">取消拍照</span>
          </p>
        </div>
        <p class="lawyer"></p>
      </div>
    </div>
	</div>
</template>

<script>
	import emptyInfo from "@/components/emptyInfo.vue";
	import messageDialog from "@/components/messageDialog.vue";
	export default {
		name: "packageCheck",
		data() {
			return {
				empty: false,
				footShow: true,
				orderSn: "", //快递单号
        orderSnList:[], //快递单号
        logisticsNumbers:[], //快递单号
				typeList: [{ type: "入库包裹查询" }, { type: "出库包裹查询" }],
				promit: false,
        orderList: [],
        pageNo: 1,
        pageSize: 900,
        totalPage: 0,
        previewSrcList: [],
        modal: false,
        type: false,
        btnType: 1, //1:去支付 2:合并
        packageWeight: 0,
        number: 0,
		rephotofee:1,
			};
		},
		components: {
			emptyInfo,
		},
    created () {
      let self = this;
      var data = sessionStorage.getItem("transport-data");
      let orderSns = "";
      if (data) {
        sessionStorage.removeItem("transport-data");
        let query = JSON.parse(data);
        this.addressId = query.addressId;
        this.contury = query.contury;
        orderSns = query.orderSns;
      }
      this.getOrderList(function () {
        for (var i = 0; i < self.orderList.length; i++) {
          if (orderSns.indexOf(self.orderList[i].orderSn) >= 0) {
            self.orderList[i].select = true;
          }
        }
      });
      this.channel = this.$route.query.channel
      // console.log('-----------'+this.channel)
    },
		mounted() {
			let that = this;
			let oriWinHeight = window.innerHeight;
			window.onresize = function () {
				that.isKeybordAvail = true;
				let newHeight = window.innerHeight;
				// 阀值大于140判断为键盘收起
				if (newHeight - oriWinHeight > 140) {
					that.footShow = true;
				} else {
					that.footShow = false;
				}
				oriWinHeight = newHeight;
			};
		},
		methods: {
			typeChoose(index) {
				this.type = index;
			},
      getOrderList (callback) {
        var that = this
        var url = '/api/h5/orderListByType'
        var data = {
          pageNo: this.pageNo,
          pageSize: this.pageSize,
          orderStatus: 10,
        }
        this.$post(url, data).then((res) => {
          console.log(res)
          that.isEnd = true
          if (res.resultCode == 10000) {
			  this.rephotofee = res.result.orderList[0].rephotofee//照片费用单价
			  /* console.log("44444")
			  console.log(this.rephotofee)
			  console.log("44444") */
            that.pageNo += 1
            that.totalPage = res.result.page.pageCount
            var orderList = res.result.orderList
            for (let i = 0; i < orderList.length; i++) {
              orderList[i].select = false
              let url = orderList[i].parcelImg
              if (url != null) {
                this.previewSrcList.push(url)
              }
            }
            if (orderList != undefined && orderList.length > 0) {
              this.orderList = this.orderList.concat(orderList)
            }
            if (this.orderList.length == 0) {
              this.empty = true
            }

            callback();
          } else {
            this.empty = true
          }
        })
      },
      closeModal () {
        this.modal = false
      },
      openModal () {
        this.modal = true
      },
      ChoseAll () {
        this.type = !this.type
        if (this.type == true) {
          //this.orderList.length > 1 ? (this.btnType = 2) : (this.btnType = 1)
          this.packageWeight = 0
          for (var i = 0; i < this.orderList.length; i++) {
            this.packageWeight = this.packageWeight + this.orderList[i].billWeight
			this.orderList[i].select = this.type
          }
        } else {
		  this.packageWeight = 0
		  for (var i1 = 0; i1 < this.orderList.length; i1++) {
		  	this.orderList[i1].select = this.type
		  }
        }
		//循环列表得到选中个数
		var num = 0
		for (var i = 0; i < this.orderList.length; i++) {
		  if (this.orderList[i].select == true) {
		    num += 1
		  }
		}
		this.number =  num //选中个数
      },
      payChose (item) {
        if (item.select == false) {
          item.select = true
          this.packageWeight = this.packageWeight + item.billWeight
          this.orderSnList.push(item.orderSn)
          this.logisticsNumbers.push(item.logisticsNumbers)

        } else {
          item.select = false
          this.packageWeight = this.packageWeight - item.billWeight
        }
        var num = 0
        for (var i = 0; i < this.orderList.length; i++) {
          if (this.orderList[i].select == true) {
            num += 1
          }
        }
        this.number =  num //选中个数

        if (num == this.orderList.length) {
          this.type = true
        } else {
          this.type = false
        }//判断是否全选
      },
      goPay(){
        var timestamp = Date.parse(new Date());
			  var parms = {"orderSnList":this.orderSnList,"rephotofee":this.rephotofee,"payAmount":this.number,"logisticsNumbers":this.logisticsNumbers,"time":timestamp}
        sessionStorage.setItem("data",JSON.stringify(parms));
        /* console.log("999888")
        console.log(this.number)
        console.log(this.rephotofee)
        console.log("999888") */
        this.$router.push({
          path:'/paywayNew/'
        })
      },
		},
	};
</script>

<style lang="scss">


	.packageCheck {
		width: 100%;
		height: 100%;
		background: #f6f6f6;
    .wenx{
      font-size: 2.1rem;
      text-align: left;
      padding: 5px 4%;

    }
    .list {
      padding: 1rem 2%;
      padding-bottom: 140px;
      .item {
        background: #fff;
        border-radius: 1rem;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        margin-bottom: 2rem;
        .left {
          width: 8%;
          span {
            width: 3rem;
            height: 3rem;
            border: 1px solid #b5b5b5;
            border-radius: 50%;
            display: inline-block;
            margin: 2rem;
            img {
              width: 100%;
            }
          }
        }
        .right {
          flex: 1;
          .head {
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -webkit-justify-content: space-between;
            justify-content: space-between;
            -moz-box-pack: space-between;
            -webkit--moz-box-pack: space-between;
            box-pack: space-between;
            padding: 2rem 1rem;
            color: #333333;
            font-size: 12px;
            p {
              display: flex;
              align-items: center;
            }
            .logisticsNumber {
              color: #999;
            }
            .copyImg {
              width: 2.5rem;
              display: inline-block;
              margin-left: 2rem;
            }
          }
          .line {
            width: 100%;
            height: 0;
            border-bottom: 2px solid #eeeeee;
          }
          .content {
            padding: 1rem;
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -webkit-justify-content: space-between;
            justify-content: space-between;
            -moz-box-pack: space-between;
            -webkit--moz-box-pack: space-between;
            box-pack: space-between;
            align-items: flex-end;
            -webkit-align-items: flex-end;
            box-align: flex-end;
            -moz-box-align: flex-end;
            -webkit-box-align: flex-end;
            .package {
              overflow: hidden;
              width: 100%;
              height: 150px;
              /* width: 20rem;
              height: 18rem; */
              background: #eee;
              border-radius: 1rem;
              img {
                width: 150px;
                height: 131px;
                border-radius: 1rem;
                padding: 9px 0 0 0;
              }
            }
          }
          .packageWeight {
            padding-top: 1rem;
            font-size: 2.3rem;
            border-top: 2px dashed #eeeeee;
            text-align: left;
          }
          .viceDiv_1,
          .viceDiv_2,
          .viceDiv_3 {
            float: left;
            margin-bottom: 1rem;
          }
        }
      }
    }
    .foot {
      background: #fff;
      padding: 2rem 3rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 9;
      .chose {
        color: #333;
       /* //font-size: 30px;*/
        display: flex;
        justify-content: space-between;
        align-items: center;
        span {
          width: 3rem;
          height: 3rem;
          border: 1px solid #b5b5b5;
          border-radius: 50%;
          display: inline-block;
          img {
            width: 100%;
          }
        }
        p {
          font-size: 2.2rem;
          padding-left: 1rem;
          padding-right: 1rem;
        }
      }
      .btn {
        background: #479af1;
        padding: 1rem 2rem;
        color: #fff;
        border-radius: 5px;
        font-size: 2.1rem;
        float: left;
        width: 97px;
        margin: 0 5px 0 0;
      }
    }
    .modal {
      position: fixed;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.5);
      z-index: 30;
      .content {
        position: absolute;
        top: 35%;
        left: 15%;
        z-index: 10;
        background: #fff;
        border-radius: 2rem;
        width: 70%;
        p:nth-child(1) {
          text-align: right;
          padding: 2rem 6% 1rem 0;
          color: #bbbbbb;
          font-size: 3rem;
        }
        p:nth-child(2) {
          text-align: center;
          color: #333;
          font-size: 2.8rem;
          padding-bottom: 4rem;
          border-bottom: 1px solid #eee;
        }
        .closeIcon {
          width: 15px;
        }
        p:nth-child(3) {
          span {
            padding: 3rem 0;
            display: inline-block;
            font-size: 2.8rem;
            text-align: center;
            width: 50%;
          }
          span:nth-child(1) {
            color: #1ca1fe;
            border-right: 2px solid #eee;
          }
          span:nth-child(2) {

            color: #999999;
          }
        }
      }
    }

	}
</style>
