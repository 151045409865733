<template>
	<div class="addressEdit">
		<div class="item">
			<img src="@/assets/images/import_orange.png" class="import" />
			<span class="label">收件人</span>
			<input
				type="text"
				placeholder="必须与证件信息一致"
				v-model="recipients"
				maxlength="50"
				@focus="closeSelect"
			/>
		</div>
		<div class="item">
			<img src="@/assets/images/import_orange.png" class="import" />
			<span class="label">电话</span>
			<input
				type="text"
				placeholder="请输入电话"
				v-model="telphone"
				maxlength="15"
				@focus="closeSelect"
			/>
		</div>
		<div class="item">
			<img src="@/assets/images/import_orange.png" class="import" />
			<span class="label">固话</span>
			<input
				type="text"
				placeholder="请输入固话"
				v-model="fixphone"
				maxlength="15"
				@focus="closeSelect"
			/>
		</div>
		<div class="item">
			<img src="@/assets/images/import_orange.png" class="import" />
			<span class="label">国家</span>

			<template class="el-select">
				<el-select
					v-model="country"
					clearable
					@change="countryChange"
					filterable
				>
					<el-option
						v-for="item in countryList"
						:key="item.country"
						:label="item.country"
						:value="item"
					>
					</el-option>
				</el-select>
			</template>
		</div>

		<div class="item">
			<img src="@/assets/images/import_orange.png" class="import" />
			<span class="label">省（州）</span>
			<input
				type="text"
				placeholder="请填写省/州信息"
				v-model="province"
				maxlength="50"
				@focus="closeSelect"
			/>
		</div>
		<div class="item">
			<img src="@/assets/images/import_orange.png" class="import" />
			<span class="label">市</span>
			<input
				type="text"
				placeholder="请填写城市"
				v-model="city"
				maxlength="50"
				@focus="closeSelect"
			/>
		</div>

		<div class="item">
			<img src="@/assets/images/import_orange.png" class="import" />
			<span class="label addressDetail">详细地址</span>
			<textarea
				placeholder="请输入详细地址"
				v-model="address"
				maxlength="150"
				@focus="closeSelect"
			></textarea>
		</div>
		<div class="item">
			<img src="@/assets/images/import_orange.png" class="import" />
			<span class="label">邮编</span>
			<input
				type="text"
				placeholder="请输入邮编"
				v-model="zipCode"
				maxlength="10"
				@focus="closeSelect"
			/>
		</div>
		<div class="item">
			<b></b>
			<span class="label">公司</span>
			<input
				type="text"
				placeholder="请输入公司"
				v-model="company"
				maxlength="20"
				@focus="closeSelect"
			/>
		</div>
		<div class="item">
			<b></b>
			<span class="label">邮箱</span>
			<input
				type="text"
				placeholder="请输入邮箱"
				v-model="email"
				@focus="closeSelect"
			/>
		</div>
		<div class="submit" @click="submitEdit">确认修改</div>
		<successInfo
			v-show="successBox"
			:content="addressId == 0 ? '新增成功' : '修改成功'"
			@close-modal="closePromit"
		></successInfo>
		<messageDialog :content="messageText" v-show="promit"></messageDialog>
	</div>
</template>

<script>
	import successInfo from "@/components/successInfo";
	import messageDialog from "@/components/messageDialog.vue";
	export default {
		name: "addressEdit",
		data() {
			return {
				successBox: false,
				promit: false,
				messageText: "",
				type: 1,
				addressId: 0,
				addressInfo: "",
				countrySelect: false,
				provinceSelect: false,
				countryList: [],
				provinceList: [],
				recipients: "",
				telphone: "",
				fixphone: "",
				country: "可输入国家筛选",
				countryId: "",
				province: "",
				provinceId: "",
				city: "",
				area: "",
				address: "",
				email: "",
				zipCode: "",
				company: "",
			};
		},
		components: {
			successInfo,
			messageDialog,
		},
		created() {
			this.addressId = this.$route.params.id;
			if (this.addressId != 0) {
				this.getAdressInfo();
				this.type = 2;
			} else {
				this.type = 1;
			}

			this.chooseCountry();
		},
		methods: {
			getAdressInfo() {
				var that = this;
				var url = "/api/h5/getAddress";
				var data = {
					addressId: this.addressId,
				};
				this.$post(url, data).then((res) => {
					if (res.resultCode == 10000) {
						var addressInfo = res.result.addressInfo;
						this.addressInfo = addressInfo;
						this.recipients = addressInfo.recipients;
						this.telphone = addressInfo.telphone;
						this.fixphone = addressInfo.fixphone;
						this.country = addressInfo.country;
						this.province = addressInfo.province;
						this.city = addressInfo.city;
						this.address = addressInfo.address;
						this.email = addressInfo.email;
						this.zipCode = addressInfo.postCode;
						this.company = addressInfo.company;
						this.area = addressInfo.county;
						this.getareaId();
					}
				});
			},
			getareaId() {
				var that = this;
				var url = "/api/h5/getAddAddressData";
				var data = {
					countryId: 0,
				};
				this.$post(url, data).then((res) => {
					if (res.resultCode == 10000) {
						var countryList = res.result.resourseList;
						for (let i = 0; i < countryList.length; i++) {
							if (countryList[i].country == this.country) {
								this.countryId = countryList[i].countryId;
							}
						}
						var url = "/api/h5/getAddAddressData";
						var data = {
							countryId: this.countryId,
						};
						this.$post(url, data).then((res) => {
							if (res.resultCode == 10000) {
								var provinceList = res.result.resourseList;
								for (let i = 0; i < provinceList.length; i++) {
									if (provinceList[i].country == this.country) {
										this.provinceId =
											provinceList[i].provinceId;
									}
								}
							}
						});
					}
				});
			},
			chooseCountry() {
				this.provinceSelect = false;
				if (this.countrySelect == false) {
					this.countrySelect = true;
					var that = this;
					var url = "/api/h5/getAddAddressData";
					var data = {
						countryId: 0,
					};
					this.$post(url, data).then((res) => {
						if (res.resultCode == 10000) {
							this.countryList = res.result.resourseList;
						}
						if (res.resultCode == 1015) {
							this.countryList = [];
						}
					});
				} else {
					this.countrySelect = false;
				}
			},
			chooseProvince() {
				this.countrySelect = false;
				if (this.provinceSelect == false) {
					this.provinceSelect = true;
					var that = this;
					var url = "/api/h5/getAddAddressData";
					var data = {
						countryId: this.countryId,
					};
					this.$post(url, data).then((res) => {
						if (res.resultCode == 10000) {
							this.provinceList = res.result.resourseList;
						}
						if (res.resultCode == 1015) {
							this.provinceList = [];
						}
					});
				} else {
					this.provinceSelect = false;
				}
			},
			closeSelect() {
				this.countrySelect = false;
				this.provinceSelect = false;
			},
			countryChange(item) {
				this.country = item.country;
				this.countryId = item.countryId;
				this.countrySelect = false;
				this.province = "";
				this.provinceId = "";
			},
			provinceChange(item) {
				this.province = item.provice;
				this.provinceId = item.provinceId;
				this.provinceSelect = false;
			},
			submitEdit() {
				var that = this;
				if (this.recipients == "") {
					this.messageText = "请输入收件人";
					this.promit = true;
					setTimeout(function () {
						that.promit = false;
					}, 1500);
					return false;
				}
				if (this.telphone == "") {
					this.messageText = "请输入手机号";
					this.promit = true;
					setTimeout(function () {
						that.promit = false;
					}, 1500);
					return false;
				}
				if (this.fixphone == "") {
					this.messageText = "请输入固话";
					this.promit = true;
					setTimeout(function () {
						that.promit = false;
					}, 1500);
					return false;
				}
				if (this.country == "") {
					this.messageText = "请输入国家";
					this.promit = true;
					setTimeout(function () {
						that.promit = false;
					}, 1500);
					return false;
				}
				if (this.province == "") {
					this.messageText = "请输入省（州）";
					this.promit = true;
					setTimeout(function () {
						that.promit = false;
					}, 1500);
					return false;
				}
				if (this.city == "") {
					this.messageText = "请输入市";
					this.promit = true;
					setTimeout(function () {
						that.promit = false;
					}, 1500);
					return false;
				}
				if (this.address == "") {
					this.messageText = "请输入详细地址";
					this.promit = true;
					setTimeout(function () {
						that.promit = false;
					}, 1500);
					return false;
				}
				if (this.zipCode == "") {
					this.messageText = "请输入邮编";
					this.promit = true;
					setTimeout(function () {
						that.promit = false;
					}, 1500);
					return false;
				}

				var url = "/api/h5/editAddress";
				var data = {
					editType: this.type,
					recipients: this.recipients,
					telphone: this.telphone,
					fixphone: this.fixphone,
					email: this.email,
					country: this.country,
					province: this.province,
					city: this.city,
					address: this.address,
					postCode: this.zipCode,
					company: this.company,
				};
				if (this.type == 2) {
					data.addressId = this.addressId;
				}
				this.$post(url, data).then((res) => {
					if (res.resultCode == 10000) {
						this.successBox = true;
					}
				});
			},
			closePromit() {
				this.$router.go(-1);
			},
		},
	};
</script>

<style lang="scss" scoped>
	.el-input__inner {
		height: 90px !important;
		font-size: 3.2rem !important;
	}

	.addressEdit {
		text-align: left;
		background: #fff;
		min-height: 100%;
		width: 100%;
		.item {
			padding: 2.5rem 6%;
			border-bottom: 2px solid #eee;
			display: -webkit-box;
			display: -moz-box;
			display: -ms-flexbox;
			display: -webkit-flex;
			display: flex;
			-webkit-justify-content: space-between;
			justify-content: space-between;
			-moz-box-pack: space-between;
			-webkit--moz-box-pack: space-between;
			box-pack: space-between;
			align-items: flex-start;
			-webkit-align-items: flex-start;
			box-align: flex-start;
			-moz-box-align: flex-start;
			-webkit-box-align: flex-start;
			position: relative;
			align-items: center;
			.import {
				position: absolute;
				width: 1.5rem;
				height: 1.5rem;
				left: 3%;
				top: 2rem;
			}
			span {
				display: inline-block;
				color: #333;
				font-size: 14px;
				width: 30%;
			}
			span:nth-child(2) {
				font-weight: 600;
			}
			input,
			textarea {
				flex: 1;
				font-size: 3rem;
				color: #666;
				outline: none;
			}
			textarea {
				height: 10rem;
			}
			.option {
				flex: 1;
				font-size: 3rem;
				min-height: 30px;
				color: #666;
			}
			.down {
				width: 2rem;
			}
			.right {
				width: 1rem;
			}
			.addressDetail {
				margin-top: -6rem;
			}
			ul {
				position: absolute;
				z-index: 10;
				top: 9.5rem;
				left: 28%;
				right: 0;
				background: #fff;
				color: #333;
				font-size: 3rem;
				font-weight: 600;
				box-shadow: 1px 4px 16px 0px rgba(229, 229, 229, 0.67);
				max-height: 360px;
				overflow: auto;
				li {
					padding: 2rem 3rem;
					color: #666;
				}
			}
		}
		.submit {
			color: #fff;
			background: #ef3c4d;
			margin: 4rem 6%;
			padding: 2rem 0;
			text-align: center;
			border-radius: 20px;
			font-size: 2.5rem;
		}
	}
</style>