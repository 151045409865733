<style>
	.paybtn {
		background: #ef3c4d;
		padding: 1rem 2rem;
		color: #fff;
		border-radius: 5px;
		font-size: 2.8rem;
		width: 72px;
	}
</style>

<template>
	<div class="configOrder">
		<ul class="orderInfo" :disabled="changestu">
			<li>
				<span>订单号：</span>
				<span>{{xuorderSN}}</span>
			</li>
			<li>
				<span>收件人：</span>
        <input :class="isedit?'' : 'addBaggroud'" type="" v-model="recipients" :disabled="isedit">
			</li>
			<li>
				<span>联系电话：</span>
        <input  :class="isedit?'' : 'addBaggroud'"  type="" :disabled="isedit" v-model="telphone">
			</li>
			<li>
				<span>邮编：</span>
        <input  :class="isedit?'' : 'addBaggroud'"  type="" v-model="postCode" :disabled="isedit">
			</li>
			<li>
				<span>国家：</span>
				<span>{{country}}</span>
			</li>
      <li>
				<span>省：</span>
				<input   :class="isedit?'' : 'addBaggroud'"  type="" v-model="province" :disabled="isedit">
			</li>
      <li>
				<span>市：</span>
        <input  :class="isedit?'' : 'addBaggroud'"  type="" v-model="city" :disabled="isedit">
			</li>
			<li>
				<span>详细地址：</span>
        <input  :class="isedit?'' : 'addBaggroud'"  type="" v-model="addressStr" :disabled="isedit">
			</li>
      <li>
				<!-- <span>选择出货渠道：</span> -->
        <selectChennalShow  :orderSn="orderSn" :country="country" :packageWeight="packageWeight" @selectAddressId="getSelectAddressId(arguments)"></selectChennalShow>
			</li>
			<li>
				<successInfo v-show="promitConfirm" content="提交申请成功！" @close-modal="closePromit"></successInfo>
				<failInfo v-show="promitFailConfirm" content="提交申请失败！" @close-modal="closeFailPromit"></failInfo>
				<span>增值服务：</span>
				<span>
					<el-radio-group v-model="addService" @change="changeAddService">
						<el-radio label="1">需要</el-radio>
						<el-radio label="2">不需要</el-radio>
					</el-radio-group>
				</span>
			</li>
			<li v-if="addService=='1'?true:false" class="myzengzhi">
				<el-checkbox-group v-model="vaService" @change="toChangeVaServiceList">
          <el-checkbox style="white-space: normal !important;color: #424242;font-size: 2.5rem"
            v-for="item in vaServiceList" :label="item.vaServiceId" :key="item.vaServiceId">
            {{item.vaServiceContent}}<span class="zzffright"
              style="color: #e60012!important;font-size: 2.5rem">¥{{item.vaServicePrice/100}}</span>
          </el-checkbox>
				</el-checkbox-group>
			</li>
      <div class = 'zengzhifuwu' >*若需要增值服务，请下单前单询专属客服</div>

			<div v-show="ShowChannelOne">
				<li style="list-style:none">
					<selectChennalShow :orderSn="orderSn" :country="country" :minWeight="weight" @selectAddressId="getSelectAddressId(arguments)"></selectChennalShow>
				</li>
				<li>
					<span>总计费重量(kg)：</span>
					<span>{{(sumHeavy/1000).toFixed(2)}}</span>
				</li>
				<li>
					<span>计算公式：</span>
					<span>{{formula}}</span>
				</li>
				<li style="display: inline-block;width:100%;">
					<div class="detaileTxt">费用清单：</div>
					<div class="detaileBox">
						<div class="detaileList">
							<span>包裹运费</span>
							<span>{{(sumPrice/100).toFixed(2)}}元</span>
						</div>
						<div class="detaileList">
							<span>仓储费</span>
							<span>{{(storeOneMoney/100).toFixed(2)}}元</span>
						</div>
						<div class="detaileList">
							<span>增值服务费</span>
							<span>{{(serviceMoney/100).toFixed(2)}}元</span>
						</div>
						<div class="detaileList">
							<span>保险费</span>
							<span>{{(insuredPremium/100).toFixed(2)}}元</span>
						</div>
					</div>
				</li>
			</div>

		</ul>

    <div class="fuwu">
              <span @click="protoalStatus">
                <img src="@/assets/images/pay-chose.png" alt="" v-show="iconSelect">
              </span>
      <p @click="openProtoalModal($event)" style="color: #007AFF;">欧轩物流集运转运契约条款及免责声明</p>
    </div>

    <div class="protoalModal" v-show="protoalModal" @click="closeModal">
      <div class="modalView">
        <span v-html="agreementDesc">{{agreementDesc}}</span><!-- 渲染协议 -->
      </div>
    </div>

		<div class="protoalModal" v-show="protoalModal" @click="closeModal">
			<div class="modalView">
				<div v-html="agreementDesc"></div>
			</div>
		</div>
		<messageDialog :content='messageContent' v-show="messageDialog"></messageDialog>

		<div class="footPart" v-show="!ShowChannelOne">
			<p>
				<span></span><br>
				<span></span>
			</p>
			<div class="btns">
				<mt-button size="small" @click="modifyInfo" style="background: #FCA824;border-radius: 4rem;">{{iseditText}}</mt-button>
				<mt-button v-loading.fullscreen.lock="fullscreenLoading" size="small" style="background: #0A7DDE;border-radius: 4rem;" :disabled="adressInfo == '' " @click="applayMergePack">确认合包</mt-button>
			</div>
		</div>

	</div>
</template>

<script>
	import messageDialog from '@/components/messageDialog.vue';
	import successInfo from "@/components/successInfo";
	import failInfo from "@/components/failInfo";
	import selectChennalShow from '@/pages/selectChennal.vue';
  import { Loading } from 'element-ui';
	export default {
		name: "addressEditOrder",
		data () {
			return {
        fullscreenLoading:false,
				orderSn: '',
				// orderSnNew:'',
				weight: '',
				recipients: '',
				telphone: '',
				postCode: '',
				detailInfo: '',
				orderCount: '',
				modal: false,
				ShowChannelOne: true,
				pageNo: 1,
				pageSize: 10,
				totalPage: 0,
				addressList: [],
				addressId: '',
				orderSns: '',
				adressInfo: '',
				messageDialog: false,
				messageContent: '',
				iconSelect: false,
				country: '',
				province: '',
				city: '',
				channel: 0,
				addressStr: '',
				addressStrtidai: '',
				protoalModal: false,
				agreementDesc: '',
				addService: '1',
				vaServiceList: [''],//增值服务多选
				vaService: [],//增值服务
				insuredAmount: '',//保额
				insuredPremium: 0,//保费
				expressList: [],
				// expressCodeShow:'',
				expressCode: '',
				expressName: '',
				value: '',
        packageWeight:0,
				safeCost: '1',//保险费
				formula: '',//计算公式
				sumHeavy: 0,//重量
				serviceIds: '',
				sumPrice: 0,
				storeOneMoney: 0,
				serviceMoney: 0,
				promitConfirm: false,
				promitFailConfirm: false,
				goPayStatus: false,
				disabled: false,
        xuorderSN:"",
        changestu:false,
        isedit:true,
        iseditText:"修改信息",
        userId:localStorage.getItem('userId')||'',
			}
		},
		components: {
			successInfo,
			failInfo,
			messageDialog,
			selectChennalShow
		},
		created () {
		  console.log(this.$route.query)
			this.orderSns = this.$route.query.orderSns;
			this.addressId = this.$route.query.addressId;
			this.channel = this.$route.query.channel;
			this.contury = this.$route.query.contury;
			this.packageWeight = this.$route.query.packageWeight;

      this.toVaServiceList();
      this.selectSystemParams();
			this.configSelect();
			if (this.channel == 1) {
				this.ShowChannelOne = false;
			}
		},
		mounted () {
			window.addEventListener('scroll', this.onScroll);
		},
		methods: {
			getSelectAddressId: function (param) {
				// childValue就是子组件传过来的值
				this.expressCode = param[0];
				this.expressName = param[1];
				if (this.expressCode == '') {
					return
				}
			},
			onScroll () {
				//可滚动容器的高度
				let innerHeight = document.querySelector('#app').clientHeight;
				//屏幕尺寸高度
				let outerHeight = document.documentElement.clientHeight;
				//可滚动容器超出当前窗口显示范围的高度
				let scrollTop = document.documentElement.scrollTop || document.body.scrollTop || window.pageYOffset;
				//scrollTop在页面为滚动时为0，开始滚动后，慢慢增加，滚动到页面底部时，出现innerHeight < (outerHeight + scrollTop)的情况，严格来讲，是接近底部。
				if (innerHeight <= (outerHeight + scrollTop)) {
					//加载更多操作
					if (this.pageNo <= this.totalPage && this.isEnd == true) {
						this.isEnd = false;
						this.getAddressList();
					}
				}
			},
			changeAddService: function (value) {
				if (value == 2) {
					this.serviceIds = '';//收费服务ids
				}
			},
			changeSafeCost (value) {
				if (value == 2) {
					this.insuredAmount = '';//保额
					this.insuredPremium = '';//保费
				}
			},
			protoalStatus () {
				if (this.iconSelect == false) {
					this.iconSelect = true;
				} else {
					this.iconSelect = false;
				}
			},
			getInsuredPremium (event) {
				this.insuredPremium = (this.premiumRate * event.target.value);
				this.expressCode = '';
				//this.expressCodeShow = '';
			},
			openProtoalModal () {
				this.protoalModal = true;//点击选中免责声明时
			},
			selectAddress (addressId) {
				this.addressId = addressId;
			},
			configSelect () {
				if (this.addressId != '') {
					var that = this;
					var url = '/api/h5/virtualPackOrderNew';//创建虚拟订单
					var data = {
						channel: 0,
						orderSns: this.orderSns,
						orderSn: '',
						addressId: this.addressId
					}
					this.$post(url, data).then(res => {

						if (res.resultCode == 10000) {
							let model = res.result.model;
							this.modal = false;
							this.adressInfo = model.address;
							this.xuorderSN = model.orderSn;
							this.weight = model.weight;
							this.recipients = model.recipients;
							this.telphone = model.telphone;
							this.postCode = model.postCode;
							this.country = model.country;
							this.province = model.province;
							this.city = model.city;
							this.addressStr = model.address;
							this.addressStrtidai = model.address;
							if (this.addressStr.length > 40) {
								this.addressStr = this.addressStr.substring(0, 40) + '...'
							}
						}
						if (res.resultCode == 70009) {
							this.messageContent = '订单已结算';
							this.messageDialog = true;
							var that = this;
							setTimeout(function () {
								that.messageDialog = false;
							}, 1500)
						}
						if (res.resultCode == 70020) {
							this.messageContent = res.reason;
							this.messageDialog = true;
							var that1 = this;
							setTimeout(function () {
								that1.messageDialog = false;
								that1.$router.go(-1);
							}, 1500)
						}
					})
				}
			},

			applayMergePack () {
        /* 表单非空验证*/
        var that = this;
        if (this.recipients == "") {
        	this.messageContent = "收件人不能为空";
        	this.messageDialog = true;
        	setTimeout(function () {
        		that.messageDialog = false;
        	}, 1500);
        	return false;
        }
        if (this.telphone == "") {
        	this.messageContent = "手机号不能为空";
        	this.messageDialog = true;
        	setTimeout(function () {
        		that.messageDialog = false;
        	}, 1500);
        	return false;
        }
        if (this.postCode == "") {
        	this.messageContent = "邮编不能为空";
        	this.messageDialog = true;
        	setTimeout(function () {
        		that.messageDialog = false;
        	}, 1500);
        	return false;
        }
        if (this.province == "") {
        	this.messageContent = "省份不能为空";
        	this.messageDialog = true;
        	setTimeout(function () {
        		that.messageDialog = false;
        	}, 1500);
        	return false;
        }
        if (this.city == "") {
        	this.messageContent = "市不能为空";
        	this.messageDialog = true;
        	setTimeout(function () {
        		that.messageDialog = false;
        	}, 1500);
        	return false;
        }

        if (this.addressStr == "") {
        	this.messageContent = "详细地址不能为空";
        	this.messageDialog = true;
        	setTimeout(function () {
        		that.messageDialog = false;
        	}, 1500);
        	return false;
        }


        if(!this.iconSelect){
          this.messageContent = "请先勾选和阅读免责条款";
          this.messageDialog = true;
          setTimeout(function () {
            that.messageDialog = false;
          }, 1500);
          return false
        }

       if(this.expressCode == '' || this.expressName == ''){
          this.messageContentShow('请选择出货渠道');
          return false;
        }
       this.fullscreenLoading = true;//合包时加载效果打开


				var url = '/api/h5/applayMergePack';//合并订单
				if (this.serviceIds instanceof Array) {
					this.serviceIds = this.serviceIds.join(',');
				}

				if (this.insuredAmount == '') {
					this.insuredAmount = 0;
					this.insuredPremium = 0;
				}

				var data = {
					channel: this.channel,
					orderSns: this.orderSns,
					orderSn: this.xuorderSN,
					addressId: this.addressId,
					insuredAmount: (this.insuredAmount * 100).toFixed(0),
					insuredPremium: (this.insuredPremium).toFixed(0),
					serviceIds: this.serviceIds,
          address: this.addressStrtidai,
          recipients: this.recipients,
          telphone: this.telphone,
          postCode: this.postCode,
          country: this.country,
          province: this.province,
          city: this.city,
          userId: this.userId,
          weight:this.weight,
          expressCode:this.expressCode,
          expressName:this.expressName,
				}
				this.$post(url, data).then(res => {
          this.fullscreenLoading = false;
					if (res.resultCode == 10000) {
						this.promitConfirm = true;
					} else {
						this.promitFailConfirm = true;
					}
				})
			},


			goAddAdress () {
				this.$router.push({
					path: '/addressedit/0'
				})
			},
			goEditAdress (addressId) {
				this.$router.push({
					path: '/addressedit/' + addressId
				})
			},
			closeModal () {
				if (this.modal == true) {
					this.modal = false;
					this.addressId = '';
				}
				if (this.protoalModal == true) {
					this.protoalModal = false;
				}
			},
			messageContentShow (msg) {
				this.messageContent = msg;
				this.messageDialog = true;
				var that = this;
				setTimeout(function () {
					that.messageDialog = false;
				}, 1500)
			},//提示框

			toVaServiceList () {//查询增值服务列表
				var url = '/api/h5/vaServiceList';
				var data = {
					pageNo: 1,
					pageSize: 1000
				}
				this.$post(url, data).then(res => {
					this.vaServiceList = res.result.vaServiceList;
				})
			},
			selectAllowExpressList (minWeight) {//根据国家和限重获取可使用的渠道列表
				var url = '/api/platExpress/selectAllowExpressList';
				var data = {
					country: this.contury,
					minWeight: minWeight,
					orderSn: this.orderSn
				}
				let that = this;
				this.$post(url, data).then(res => {
					that.expressList = res.result.expressList;
				})
			},

			selectSystemParams () {//查询查询参数
				var url = '/api/h5/selectSystemParams';
				var data = {}
				this.$post(url, data).then(res => {
					this.agreementDesc = res.result.systemParams.agreementDesc;
					this.premiumRate = res.result.systemParams.premiumRate;

				})
			},


			toChangeVaServiceList (e) {
				this.serviceIds = e;
				// this.expressCode = '';
				//this.expressCodeShow = '';
			},
			closePromit () {
				this.$router.push({
					name: 'waitpacked'
				})
			},
			closeFailPromit () {
				this.promitFailConfirm = false;
			},
      //点击修改信息时在此处修改信息
			modifyInfo () {
				//this.$router.back(-1)
        var url = '/api/h5/virtualPackOrdeAddressUpdate';//修改订单信息

        this.isedit =  !this.isedit;
        if(this.isedit){
          this.iseditText = "修改信息"
        }else {
          this.iseditText = "确定"
        }

			}
		}
	}
</script>

<style lang="scss" scoped>
.fuwu {
  font-size: 16px;
  height: 40px;
  padding: 0 15px;
  margin-top: 3rem;

  span {
    width: 4rem;
    height: 4rem;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 50%;
    float: left;
    margin: 0 7px 0 0;
  }

  img {
    width: 100%;
  }

  p {
    display: inline-block;
  }
}
  .el-checkbox {
      color: #606266;
      font-weight: 500;
      font-size: 14px;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      margin-top: 1rem;
      margin-bottom: 1rem;
      font-size: 2.1rem !important;
          line-height: 16px !important;
      //margin-right: 30px;

  }
  .configOrder .orderInfo li[data-v-ed837358] {
      text-align: left;
      padding: 1rem 1rem 1rem 0;
      font-size: 2.5rem;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      border-bottom: 2px solid #eeeeee;
      color: #333;
      z-index: 1;
      height: auto!important;
      line-height: 25px;
  }
	.configOrder {
		width: 100%;
		min-height: 100%;
		background: #f6f6f6;
		padding-bottom: 10rem;
		.adress {
			padding-left: 3rem;
			background: #fff;
			p {
				padding: 2.5rem 0;
				text-align: left;
			}
			p:nth-child(1) {
				border-bottom: 2px solid #eee;
				color: #333;
				font-size: 3rem;
			}
			p:nth-child(2) {
				color: #999;
				font-size: 2.5rem;
				position: relative;
				padding-right: 6rem;
				.arrow {
					position: absolute;
					top: 50%;
					left: calc(100% - 4.5rem);
					transform: translate(0, -50%);
					width: 1.5rem;
				}
			}
		}
		.orderInfo {
      /*  增值服务提示*/
      .zengzhifuwu{
        font-size: 2.5rem;
        color: #F59A23;
        text-align: left;
        padding-bottom: 8px;
        padding-top: 8px;
      }
      /*  增值服务样式不换行*/
      .myzengzhi{
        .el-checkbox-group {
              width: 100%;

              .el-checkbox {
                margin-right: 2px !important;
                position: relative;

                .el-checkbox__label {
                  font-size: 2.5rem !important;
                  line-height: 20px !important;

                  .zyzzff {
                    position: absolute;
                    right: 20px;
                  }
                }
              }
            }
        .el-button, .el-checkbox {
            white-space:normal!important;
            margin-right: 2px;
            position: relative;
          .el-checkbox__input{
          }
          .el-checkbox__label{
            .zzffright{
              position: absolute;
              right: -66px;
            }
          }
        }
      }
			background: #fff;
			margin-top: 1rem;
			// padding-left:3rem;
			margin-left: 1rem;
			// margin-right: 1rem;
			li {
				text-align: left;
				// padding:2.5rem 3rem 2.5rem 0;
				padding: 1rem 1rem 1rem 0;
				font-size: 2.5rem;
				//display: flow-root!important;
				display: flex;
				justify-content: space-between;
				border-bottom: 2px solid #eeeeee;
				color: #333;
        height: auto;
        line-height: 30px;
        span:first-child{
          width: 100px;
          line-height: 30px;
        }
        input:last-child{
          text-align: right;
          background: none;
          font-size: 2.1rem !important;
              line-height: 16px !important;
        }
        input.addBaggroud:last-child{
          border: 1px solid #ccc;
          border-radius: 3px;
          padding: 0 5px 0 0;
        }
				.noInfo {
					color: #999;
				}
			}
		}
		.protoal {
			display: flex;
			align-items: center;
			padding: 3rem;
			span {
				width: 3rem;
				height: 3rem;
				border: 1px solid #b5b5b5;
				border-radius: 50%;
				display: inline-block;
				margin-right: 2rem;
				img {
					width: 100%;
				}
			}
			p {
				font-size: 2.5rem;
				color: #057eed;
			}
		}
		.footPart {
			font-size: 3rem;
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 0rem 3rem;
			background: #fff;
			position: fixed;
			bottom: 0;
      z-index: 2;
			width: 100%;
			p:nth-child(1) {
				padding: 3rem 0;
				span:nth-child(1) {
					color: #333;
				}
				span:nth-child(2) {
					color: #ef3c4d;
				}
			}
			p:nth-child(2) {
				padding: 1rem 2rem;
				background: #ef3c4d;
				line-height: 1;
				color: #fff;
				border-radius: 10px;
			}

			.btns {
				button + button {
					margin-left: 10px;
				}

				button:not([disabled]) {
					background-color: #ef3c4d;
					color: white;
				}
			}
		}
		.modal {
			width: 100%;
			height: 100%;
			background: rgba(0, 0, 0, 0.6);
			position: fixed;
			top: 0;
			left: 0;
			.modalView {
				background: #fff;
				//height: 60%;
				width: 100%;
				position: fixed;
				bottom: 0;
				overflow: auto;
				padding-bottom: 120px;
				padding-top: 145px;
				.title {
					position: relative;
					padding: 3rem 0;
					border-bottom: 2px solid #eee;
					position: fixed;
					width: 100%;
					top: 40%;
					left: 0;
					background: #fff;
					z-index: 100;
					span:nth-child(1) {
						color: #333;
						font-size: 3rem;
					}
					span:nth-child(2) {
						color: #ef3c4d;
						font-size: 3rem;
						position: absolute;
						top: 50%;
						left: 1rem;
						transform: translate(0, -50%);
						padding: 2rem;
					}
					span:nth-child(3) {
						color: #ef3c4d;
						font-size: 3rem;
						position: absolute;
						top: 50%;
						left: calc(100% - 10rem);
						transform: translate(0, -50%);
						padding: 2rem;
					}
				}
				ul {
					padding-left: 8rem;
					li {
						padding: 2rem 0;
						text-align: left;
						font-size: 3rem;
						color: #333;
						border-bottom: 2px solid #eee;
						position: relative;
						p {
							height: 3.5rem;
							line-height: 3.5rem;
						}
						p:nth-child(2) {
							margin-top: 2rem;
						}
						.selectImg {
							position: absolute;
							width: 4rem;
							height: 4rem;
							top: 50%;
							left: -6rem;
							transform: translate(0, -50%);
						}
						.startBorder {
							position: absolute;
							width: 4rem;
							height: 4rem;
							top: 50%;
							left: -6rem;
							transform: translate(0, -50%);
							border: 2px solid #eee;
							border-radius: 50%;
						}
						.editImg {
							position: absolute;
							width: 4rem;
							height: 4rem;
							top: 50%;
							left: calc(100% - 8rem);
							transform: translate(0, -50%);
						}
					}
				}
				.footBtn {
					position: relative;
					padding: 2rem 0;
					border-top: 2px solid #eee;
					color: #333;
					font-size: 3rem;
					position: fixed;
					bottom: 0;
					width: 100%;
					display: flex;
					justify-content: center;
					align-items: center;
					background: #fff;
					img {
						width: 3rem;
						height: 3rem;
						margin-right: 1rem;
					}
				}
			}
		}
		.protoalModal {
			width: 100%;
			height: 100%;
			background: rgba(0, 0, 0, 0.6);
			position: fixed;
			top: 0;
			left: 0;
      z-index: 99;
			.modalView {
				background: #fff;
				height: 80%;
				width: 90%;
				overflow: auto;
				position: absolute;
				top: 10%;
				left: 50%;
				transform: translate(-50%, 0);
				border-radius: 10px;
				text-align: left;
				padding: 3rem;
				font-size: 2rem;
			}
		}
		.insured {
			display: inline-block !important;
			width: 100%;
			.insuredSpan {
				display: flex;
				width: 100%;
				span {
					width: 20rem !important;
					line-height: 4.5rem;
				}
			}
		}
		.detaileTxt {
			text-align: left;
			padding: 0 3rem 1rem 0;
		}
		.detaileBox {
			border: 2px solid #eeeeee;
			padding: 1rem 1.5rem 1rem 1.5rem;
		}
		.detaileList {
			line-height: 4.5rem;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
		}
	}
</style>
