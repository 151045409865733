<template>
    <div class="wrap">
        <headerTitle title='我的优惠券'> <span class="loseEfficacy" @click="goLoseEfficacy">失效券</span> </headerTitle>
        <div class="inputBox">
          <div class="inputItem">
            <input type="text" class="myInput" v-model="redeemCode" placeholder="请输入兑换码"/>
          </div>
          <div class="btnBox" @click="redeemCouponsBycodeBtn">兑换</div>
        </div>
      <van-overlay :show="show" @click="show = false">
        <div class="wrapper" @click.stop>
          <div class="overBox">
            <img
              @click="show = false"
              class="imgDel"
              src="/../assets/images/invit-tan-x.png"
              alt=""
            />
            <div class="successBox">兑换成功</div>
            <div class="successBtn" @click="goTransport('transport')">
              立即使用
            </div>
          </div>
        </div>
      </van-overlay>
      <van-overlay :show="show1" @click="show1 = false">
        <div class="wrapper" @click.stop>
          <div class="overBox">
            <img
              @click="show1 = false"
              class="imgDel"
              src="/../assets/images/invit-tan-x.png"
              alt=""
            />
            <div class="successBox1">此码已失效</div>
            <div class="successBox1 f3">请核对</div>
            <div class="successBtn1" @click="show1 = false">
              我已知晓
            </div>
          </div>
        </div>
      </van-overlay>
        <div class="couponListBox">
            <template v-if="baseList.length">
                <div :class="[item.type == '打折券' ? 'active' : 'couponList']" v-for="(item,index) of baseList" :key="index">
                    <div class="couponItem">
                        <div class="f3 colW">
                            <span class="f4">{{item.couamount}}</span>{{item.type == '优惠券' ? '元' : '折'}}
                            <span>{{item.userlimittype}}优惠券</span>
                        </div>
                        <div class="f3 colW">有效期至：{{(new Date(item.periodTime)).Format("yyyy-MM-dd")}}</div>
                    </div>
                    <div class="useBtn" :class="[item.type == '打折券'?'activeCol':'']" @click="goTransport('transport')">去使用</div>
                </div>
            </template>
            <emptyInfo class="emptyInfodiv" v-else content='暂无优惠券'></emptyInfo>
        </div>
        <div>
            <exchangeCom></exchangeCom>
        </div>
    </div>
</template>

<script>
    import  headerTitle  from '../components/headerTitle'
    import exchangeCom from './exchangeCom/index'
    import emptyInfo from '@/components/emptyInfo.vue';

    export default {
        name: "couponList",
        components:{
            headerTitle,
            exchangeCom,
            emptyInfo
        },
        data(){
            return {
                baseList: [],
                redeemCode: "",
                show: false,
                show1: false,
                userId:localStorage.getItem('userId'),
            }
        },
        mounted() {
            this.baseFun()
        },
        methods:{

          redeemCouponsBycodeBtn() {
            this.$post("/api/h5/redeemCouponsBycode", {
              userId: Number(this.userId),
              redeemCode: this.redeemCode,
            }).then((res) => {
              if (res.resultCode == 10000) {
                this.show = true;
                this.redeemCode = "";
                this.baseFun();
              } else {
                this.show1 = true;
                // alert(res.reason)
              }
            });
          },

            goLoseEfficacy(){
                this.$router.push({
                    path:'loseEfficacyCoupon'
                })
            },
            baseFun(){
                 this.$post('/api/h5/querryPersonsCouponsInfo', { userId: this.userId }).then(res=>{
                    if(res.resultCode == 10000){
                        this.baseList = res.result.filter(item=>{
                            return item.status == '0' && item
                        })
                    }
                })
            },
            goTransport(path){
                this.$router.push({
                    path:path,
                    query:{
                        channel:1
                    }
                })
            },
        }
    }
</script>

<style scoped lang="scss">

    .wrap{
        background: #F1EFF2;
        height: 100%;
      padding-bottom: 8px;
    }
    .inputBox {
      margin: 3rem;
      display: flex;
      align-items: center;
      .inputItem {
        input {
          width: 45rem;
          height: 7rem;
          font-size: 3rem;
          padding-left: 1rem;
        }
      }
      .btnBox {
        width: 15rem;
        height: 30px;
        line-height: 30px;
        color: #fff;
        font-size: 14px;
        margin-left: 3rem;
        background: #0c7edd;
        text-align: center;
        border-radius: 7px;
      }
    }
.couponListBox{
    margin: 3rem 2rem;
    .couponList{
        background: url("../assets/images/quan-bg-1.png");
        width: 100%;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        /*height: 15rem;*/
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-sizing: border-box;
        padding: 3rem;
        margin-bottom: 2rem;
    }
    .active{
        background: url("../assets/images/quan-bg-2.png") !important;
        width: 100%;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        /*height: 15rem;*/
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-sizing: border-box;
        padding: 3rem;
        margin-bottom: 2rem;
    }

}
.useBtn{
    font-size: 2.5rem;
    background: #fff;
    width: 14rem;
    height: 4rem;
    line-height: 4rem;
    border-radius: 2rem;
    color: #2884D6;
}
.activeCol{
    color: #FF8F21;
}
    .colW{
        color: #fff;
    }
    .f2{
        font-size: 2rem;
    }
    .f3{
        font-size: 3rem;
    }
    .f4{
        font-size: 4rem;
    }
    .loseEfficacy{
        color: #fff;
        font-size: 2rem;
    }
    .emptyInfodiv{
      height: 40rem;
      position: relative;
    }
    .wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }
    .overBox {
      width: 35rem;
      height: 22rem;
      background: #fff;
      border-radius: 1rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
    }
    .successBox {
      color: #2d88d9;
      font-size: 4rem;
      font-weight: 600;
      margin-top: 2rem;
    }
    .successBox1 {
      font-size: 4rem;
      margin-top: 1rem;
    }
    .f3 {
      font-size: 3rem;
    }
    .successBtn {
      background: #0c8fe3;
      font-size: 2rem;
      width: 20rem;
      height: 5rem;
      line-height: 5rem;
      border-radius: 3rem;
      color: white;
      margin-top: 3.5rem;
    }
    .successBtn1 {
      border: 1px solid #0c8fe3;
      font-size: 2rem;
      width: 20rem;
      height: 5rem;
      line-height: 5rem;
      border-radius: 3rem;
      color: #0c8fe3;
      margin-top: 2rem;
    }
    .imgDel {
      position: absolute;
      right: -3rem;
      top: -3rem;
    }
</style>
