<template>
  <div class="waitPacked">
    <p class="title">
      <el-row>
        <el-button value=5 style="width: 45%;" @click="daidabao($event)" v-loading.fullscreen.lock="fullscreenLoading"
          :class="[mycolordaidabao == true ? 'beijingse-one':'']">待打包</el-button>
        <el-button value=13 style="width: 45%;margin-left: 10%;" v-loading.fullscreen.lock="fullscreenLoading"
          :class="[mycolor == true ? 'beijingse-one':'']" @click="yidabao($event)">已打包</el-button>
      </el-row>
    </p>
    <emptyInfoDFK v-show="empty"></emptyInfoDFK>
    <div v-show="!empty">
      <div class="list">
        <div class="item" v-for="(item,index) in orderList" :id="index" ref="selorder">
          <div class="head">
            <p>
              <span>订单号：</span>
              <span class="orderNo" @click="seeChildOrder(item.orderSn)">{{item.orderSn}}</span>
              <img src="https://jcex-file-service.oss-cn-hangzhou.aliyuncs.com/jc-wms/copy.png"
                @click="copyContent(item.orderSn)" class="copyImg">
            </p>
            <span style="float:right;" @click="getEventShowOrHidden($event)">隐藏</span>
          </div>
          <div>
            <div class="head">
              <p class="logisticsNumber">
                <span>国际快递单号：{{item.wmsLogisticsNumbers == null?'暂无' : item.wmsLogisticsNumbers != null && item.wmsLogisticsNumbers.length > 20?item.wmsLogisticsNumbers.substring(0,21) + '...' : item.wmsLogisticsNumbers}}</span>
                <img src="https://jcex-file-service.oss-cn-hangzhou.aliyuncs.com/jc-wms/copy.png"
                  @click="copyContent(item.wmsLogisticsNumbers)" class="copyImg"
                  v-show="item.wmsLogisticsNumbers != null">
              </p>
              <p>{{item.showOrderStatus}}</p>
            </div>
            <!-- 重新打包后的图片-->
            <!-- <div class="line"></div>
            <div class="content" style="padding: 1rem 0rem;">
              <p class="package">
                <img src="https://jcex-file-service.oss-cn-hangzhou.aliyuncs.com/jc-wms/pic.jpg" v-show="item.parcelImg == null">
                <img :src="item.parcelImg" v-show="item.parcelImg != null">
              </p>

            </div> -->

            <div class="item" v-for="(item1,index1) in item.childOrderReq" :key="index1" style="padding-left: 4rem;">
              <div class="head" style="padding: 1rem 0rem;">
                <p class="logisticsNumber">
                  <span>国内快递单号：{{item1.logisticsNumbers == null?'暂无' : item1.logisticsNumbers != null && item1.logisticsNumbers.length > 20?item1.logisticsNumbers.substring(0,21) + '...' : item1.logisticsNumbers}}</span>
                  <img src="https://jcex-file-service.oss-cn-hangzhou.aliyuncs.com/jc-wms/copy.png"
                    @click="copyContent(item1.logisticsNumbers)" class="copyImg"
                    v-show="item1.logisticsNumbers != null">
                </p>
              </div>
              <div class="line"></div>
              <div class="content" style="padding: 1rem 0rem;">
                <p class="package">
                  <img src="https://jcex-file-service.oss-cn-hangzhou.aliyuncs.com/jc-wms/pic.jpg"
                    v-show="item1.parcelImg == null">
                  <img :src="item1.parcelImg" v-show="item1.parcelImg != null">
                </p>

              </div>
            </div>

            <div class="line"></div>
            <div class="time" v-show="item.orderStatus == '5' ">
              <span>提交合包时间:</span>
              <span>{{item.createTime}}</span>
            </div>
            <!-- <div class="time" >
              <span>{{item.createTime}}</span>
              <span @click="goOrderDetail(item.orderSn)">查看详情</span>
            </div> -->
            <div class="item" v-show="item.orderStatus == '13' ">
              <div class="head">
                <span>出货渠道:</span>
                <span>{{item.expressName}}</span>
                <!-- <selectChennalShow :orderSn="item.orderSn"  :country="item.country" :minWeight="item.weight"  @selectAddressId="getSelectAddressId(arguments,item.orderSn)"></selectChennalShow> -->
              </div>
              <div class="head" v-if="item.orderSn.indexOf('DTM') == -1 ? true:false" v-show="!isZY" >
                <span>打包前重量（kg）:</span>
                <span>{{((item.weight)/1000).toFixed(2)}}</span>
              </div>
              <div class="head" v-show="!isZY" v-if="item.orderSn.indexOf('DTM') == -1 ? true:false">
                <span>打包后重量（kg）:</span>
                <span>{{((item.billWeight)/1000).toFixed(2)}}</span>
              </div>
              <!-- <div class="head">
                <span>打包后重量（kg）:</span>
                <span v-for="(item2,index2) in calculation" v-if="item.orderSn == item2.myOrderSns?true:false">{{((item2.packedSumHeavy)/1000).toFixed(2)}}</span>
              </div> -->
              <div class="head">
                <span>计费总重量（kg）:</span>
                <span v-for="(item2,index2) in calculation"
                  v-if="item.orderSn == item2.myOrderSns?true:false">{{((item2.sumHeavy)/1000).toFixed(2)}}</span>
              </div>
              <div class="head" v-for="(item2,index2) in calculation"
                v-show="item.orderSn == item2.myOrderSns?true:false" v-if="item2.mytishi?true:false">
                <span style="color: #ff0000;font-size: 14px">{{item2.mytishi}}</span>
              </div>
              <div class="head">
                <span>计费公式:</span>
                <span v-for="(item2,index2) in calculation"
                  v-if="item.orderSn == item2.myOrderSns?true:false">{{item2.formula}}</span>
              </div>
              <div class="head" v-show="!isZY" v-if="item.orderSn.indexOf('DTM') == -1 ? true:false">
                <span>节省重量(kg):</span>
                <span v-for="(item2,index2) in calculation"
                  v-if="item.orderSn == item2.myOrderSns?true:false">{{((item2.savedHeavy)/1000).toFixed(2)}}</span>
              </div>
              <!-- <div class="head" v-for="(item2,index2) in calculation"
                v-if="item.orderSn == item2.myOrderSns?item2.showInsurance:false">
                <span>保险费(元)：</span>
                <span>
                  <el-radio-group v-model="safeCost"
                    @change="changeSafeCost($event,item.orderSn,calculation.myOrderSns)">
                    <el-radio label="1">需要</el-radio>
                    <el-radio label="2">不需要</el-radio>
                  </el-radio-group>
                </span>
              </div>
              <div v-for="(item2,index2) in calculation" v-if="item.orderSn == item2.myOrderSns?item2.showInsurance:false">
                <div class="head" v-if="safeCost=='1'?true:false">
                  <span style="width: 30%;">保额(元)：</span>
                  <input style="border: #6b6b6b 1px solid;" type="Number"
                    :value="baoe.myOrderSns == item.orderSn?baoe.gaib:baoe.yuans"
                    @blur="getInsuredPremium($event,item.orderSn)" />
                </div>
                <div class="head" v-if="safeCost=='1'?true:false">
                  <span v-if="item.orderSn == insuredPremium.myOrderSns?true:false"
                    style="display: inline-block;width: 100%;line-height: 4.5rem;">保费(元)：&nbsp;{{(insuredPremium.premiumRate/100).toFixed(2)}}</span>
                  <span v-if="item.orderSn != insuredPremium.myOrderSns?true:false"
                    style="display: inline-block;width: 100%;line-height: 4.5rem;">保费(元)：&nbsp;0.00</span>

                </div>
              </div> -->
              <div class="head">
                <span>费用清单:</span>
              </div>
            </div>
            <div class="line" v-show="item.orderStatus == '13' "></div>
            <div class="item" v-show="item.orderStatus == '13' ">
              <div class="head">
                <span>包裹运费:</span>
                <span v-for="(item2,index2) in calculation"
                  v-if="item.orderSn == item2.myOrderSns?true:false">{{(item2.sumPrice/100).toFixed(2)}}元</span>

              </div>
              <div class="head" v-show="!isZY" v-if="item.orderSn.indexOf('DTM') == -1 ? true:false">
                <span>仓储费:</span>
                <span v-for="(item2,index2) in calculation"
                  v-if="item.orderSn == item2.myOrderSns?true:false">{{((item2.storeOneMoney+item2.storeTwoMoney)/100).toFixed(2)}}元</span>
              </div>
              <div class="head">
                <span>增值服务费:</span>
                <span v-for="(item2,index2) in calculation"
                  v-if="item.orderSn == item2.myOrderSns?true:false">{{(item2.serviceMoney/100).toFixed(2)}}元</span>
              </div>
              <div class="head">
                <span style="color: red;">提示:（如需要购买保险，请联系客服！）</span>
              </div>
              <!-- <div class="head" v-for="(item2,index2) in calculation"
                v-if="item.orderSn == item2.myOrderSns?item2.showInsurance:false">
                <span>保险费:</span>
                <span
                  v-if="item.orderSn == insuredPremium.myOrderSns?true:false">{{(insuredPremium.premiumRate/100).toFixed(2)}}元</span>
                <span v-if="item.orderSn != insuredPremium.myOrderSns?true:false">0.00元</span>
              </div> -->

            </div>
            <div class="line" v-show="item.orderStatus == '13' "></div>
            <div class="fuwu" v-show="orderStatus == 13 && zhanshi ">
              <span @click="protoalStatus">
                <img src="@/assets/images/pay-chose.png" alt="" v-show="iconSelect">
              </span>
              <p @click="openProtoalModal($event)" style="color: #007AFF;">欧轩物流集运转运契约条款及免责声明</p>
            </div>

            <div v-show="orderStatus == 13 && zhanshi ">
              <div class="footPart">
                <p>
                  <span>合计(￥)：</span>
                  <span v-for="(item2,index2) in calculation" v-if="item.orderSn == item2.myOrderSns?true:false">
                    {{insuredPremium.myOrderSns == item2.myOrderSns?((item2.sumPrice+item2.storeOneMoney+item2.storeTwoMoney+item2.serviceMoney+insuredPremium.premiumRate)/100).toFixed(2) : (item2.orderCount).toFixed(2)}}
                    元</span>
                </p>
                <input @click="goPay(item2)" v-for="(item2,index2) in calculation"
                  v-if="item.orderSn == item2.myOrderSns && item2.orderCount != 0.00?true:false" type="button"
                  value="去支付" class="paybtn" />
              </div>
            </div>

          </div>
        </div>
        <div class="protoalModal" v-show="protoalModal" @click="closeModal">
          <div class="modalView">
            <span v-html="agreementDesc">{{agreementDesc}}</span><!-- 渲染协议 -->
          </div>
        </div>
        <messageDialog :content='messageContent' v-show="messageDialog"></messageDialog>
      </div>
    </div>
    <div class="childOrder" v-show="childOrder" @click="closeModal">
      <div class="modalView">
        <p class="title">主订单号：{{orderSn}}</p>
        <ul>
          <li v-for="(item,index) in childOrderList" :key="index">
            <p>子订单号：{{item.orderSn}}</p>
            <p>国内快递单号：{{item.logisticsNumbers}}</p>
          </li>
        </ul>
      </div>
    </div>

    <messageDialog content='复制成功！' v-show="promit"></messageDialog>
  </div>
</template>

<script>
  import emptyInfoDFK from '@/components/emptyInfoDFK.vue';
  import {
    handleClipboard
  } from '@/assets/js/clipboard';
  import messageDialog from '@/components/messageDialog.vue';
  import selectChennalShow from '@/pages/selectChennal.vue';
  export default {
    name: "waitPacked",
    data() {
      return {
        isZY:false, // 默认合包包裹状态
        fullscreenLoading: false,
        zhanshi: true, //状态为4时展示
        empty: false,
        isEnd: true,
        childOrder: false,
        orderList: [],
        childOrderList: [],
        orderSn: '',
        pageNo: 1,
        pageSize: 900,
        orderStatus: 5,
        totalPage: 0,
        promit: false,
        centerStatus: '',
        iconSelect: false,
        protoalModal: false,
        agreementDesc: '',
        messageContent: '',
        country: '',
        minWeight: '',
        safeCost: '1', //保险费
        formula: '', //计算公式
        expressList: [],
        addressId: '',
        insuredAmount: '', //保额
        insuredPremium: {
          premiumRate: 0
        },
        sumPrice: 0,
        storeOneMoney: 0,
        storeTwoMoney: 0,
        serviceMoney: 0,
        serviceIds: '',
        orderCount: 0,
        baoxian: true,
        MyValue: true,
        sumHeavy: 0, //重量
        savedMoney: 0, //省运费
        expressCode: '', //支付渠道
        goPayStatus: false,
        disabled: false,
        centerOrderSns: '',
        messageDialog: false,
        centerMyOrderSns: "",
        mycolor: true,
        mycolordaidabao: false,
        calculation: [], //计费结果
        baoe: {
          yuans: 0,
        }, //计费结果
      }
    },
    components: {
      messageDialog,
      emptyInfoDFK,
      selectChennalShow
    },
    watch: {

    },
    created() {
      /* this.getOrderList(13); */
      this.daidabao();
      this.selectSystemParams(); //渲染协议


    },
    mounted() {
      window.addEventListener('scroll', this.onScroll);
    },
    methods: {
      daidabao(e) {
        this.fullscreenLoading = true;
        this.mycolor = false
        this.mycolordaidabao = true
        this.orderStatus = 5
        this.getOrderList(this.orderStatus);
      },
      yidabao(e) {
        this.fullscreenLoading = true;
        this.mycolordaidabao = false
        this.mycolor = true
        this.orderStatus = 13
        this.getOrderList(this.orderStatus);
      },


      changeMyStu(e) {
        this.MyValue = e
      },
      messageContentShow(msg) {
        this.messageContent = msg;
        this.messageDialog = true;
        var that = this;
        setTimeout(function() {
          that.messageDialog = false;
        }, 1500)
      },
      goPay(payItem) {


        if (this.iconSelect == false) {
          this.messageContentShow('请仔细阅读条款和声明，并同意后才可支付');
          return false;
        }
        var url = '/api/h5/changeOrderStatus';
        var data = {
          orderSn: payItem.myOrderSns,
          orderFixedPrice: payItem.sumPrice,
          insuredAmount: this.insuredPremium.premiumRate / this.premiumRate * 100,
          insuredPremium: this.insuredPremium.premiumRate,
          serviceMoney: payItem.serviceMoney
        }

        this.$post(url, data).then(res => {
          if (res.resultCode == 10000) {

            this.$router.push({
              name: 'payway',
              params: {
                id: payItem.myOrderSns,
                jine: res.result.msg
              }
            })

          } else {
            this.messageContent = "去支付失败，请重试";
            this.messageDialog = true;
            var that = this;
            setTimeout(function() {
              that.messageDialog = false;
            }, 1500)
          }
        })


      },
      selectSystemParams() { //查询查询参数
        var url = '/api/h5/selectSystemParams';
        var data = {}
        this.$post(url, data).then(res => {
          this.agreementDesc = res.result.systemParams.agreementDesc;
          this.premiumRate = res.result.systemParams.premiumRate;

        })
      },
      /* //保额保费交互
      getInsuredPremium(event, myOrderSns1) {

        this.insuredPremium = {
          myOrderSns: myOrderSns1,
          premiumRate: this.premiumRate * event.target.value
        };

        this.baoe = {
          myOrderSns: myOrderSns1,
          yuans: 0,
          gaib: event.target.value
        };


      }, */
      //保额保费交互
      /* changeSafeCost(value, aa, bb) {
        if (value == 2) {
          this.insuredAmount = ''; //保额
          this.insuredPremium.premiumRate = 0; //保费
        } else if (value == 1) {
          this.insuredPremium.premiumRate = this.baoe.gaib * this.premiumRate; //保费
        }
      }, */
      //点击选择出货渠道
      getSelectAddressId: function(param, myOrderSns) {
        this.centerOrderSns = myOrderSns //点击渠道之后拿到当条orderSNS
        this.baoxian = false;
        // childValue就是子组件传过来的值
        this.expressCode = param[0];
        this.expressName = param[1];
        if (this.expressCode == '') {
          return
        }
        this.applayMergePackAndselectExpress(myOrderSns); //计算总费用
        //this.selectExpressToCalculation(param[0]);
      },


      openProtoalModal(e) {
        this.protoalModal = true; //点击选中免责声明时
      },
      protoalStatus() {
        if (this.iconSelect == false) {
          this.iconSelect = true;
        } else {
          this.iconSelect = false;
        }
      },

      getEventShowOrHidden(e) {
        let v = e.currentTarget.innerHTML;
        if (v == "显示") {
          e.currentTarget.innerHTML = "隐藏";
          e.currentTarget.parentElement.nextElementSibling.style.display = "block"
        }
        if (v == "隐藏") {
          e.currentTarget.innerHTML = "显示";
          e.currentTarget.parentElement.nextElementSibling.style.display = "none"
        }

      },
      onScroll() {
        //可滚动容器的高度
        let innerHeight = document.querySelector('#app').clientHeight;
        //屏幕尺寸高度
        let outerHeight = document.documentElement.clientHeight;
        //可滚动容器超出当前窗口显示范围的高度
        let scrollTop = document.documentElement.scrollTop || document.body.scrollTop || window.pageYOffset;
        //scrollTop在页面为滚动时为0，开始滚动后，慢慢增加，滚动到页面底部时，出现innerHeight < (outerHeight + scrollTop)的情况，严格来讲，是接近底部。
        if (innerHeight <= (outerHeight + scrollTop)) {
          //加载更多操作
          if (this.pageNo <= this.totalPage && this.isEnd == true) {
            this.isEnd = false;
            this.getOrderList(this.orderStatus);
          }
        }
      },
      getOrderList(orderStau) {
        var that = this;
        var url = '/api/h5/orderListByType';
        var result2 = {
          pageNo: this.pageNo,
          pageSize: this.pageSize,
          orderStatus: orderStau,
          orderType: 1
        }
        this.$post(url, result2).then(res => {
          that.isEnd = true;
          this.fullscreenLoading = false;
          if (res.success) {
            /* this.fullscreenLoading = false; */
            /* 接口获取到不为空的数据列表时*/
            if (res.result.orderList != undefined && res.result.orderList.length > 0) {
              var orderList = res.result.orderList;
              /*for (let mm = 0; mm < orderList.length; mm++){
                /!* 此处区别是合包的数据还是直邮的数据==-----合包的数据添加isZY字段为false----直邮的数据添加isZY字段为true*!/
              }*/
              this.calculation = []
              for (let i = 0; i < orderList.length; i++) {
                if (orderList[i].createTime == '') {
                  orderList[i].createTime = '--';
                } else {
                  orderList[i].createTime = this.$getDate(orderList[i].createTime);
                }
                if (orderStau == 13) {
                  var url = '/api/h5/countFeeToPackOrder';
                  if (this.serviceIds instanceof Array) {
                    this.serviceIds = this.serviceIds.join(',');
                  }

                  var result1 = {
                    //addressId: this.addressId,
                    channel: 1,
                    expressCode: orderList[i].expressCode,
                    insuredAmount: Number((this.insuredAmount * 100).toFixed(0)),
                    insuredPremium: Number(this.insuredPremium),
                    orderSn: orderList[i].orderSn,
                    serviceIds: orderList[i].serviceIds

                  }
                  this.$post(url, result1).then(res1 => {
                    if (res1.success == true) {
                      this.calculation.splice(1, 0, {
                        // showInsurance: res1.result.sumHeavy > 4000 ? true : false, ///* 判断重量小于等于5kg时，不要展示保险*/
                        mytishi: false, //重量符合渠道时
                        mytishiqudao: false, //渠道不存在时
                        formula: res1.result.formula, //计算公式
                        sumHeavy: res1.result.sumHeavy,
                        sumPrice: res1.result.sumPrice,
                        savedMoney: res1.result.savedMoney, //省运费
                        storeOneMoney: res1.result.storeOneMoney, //一段费用--仓储费
                        storeTwoMoney: res1.result.storeTwoMoney, //二段费用--仓储费
                        serviceMoney: res1.result.serviceMoney,
                        packedSumHeavy: res1.result.packedSumHeavy,
                        savedHeavy: res1.result.savedHeavy,
                        myOrderSns: orderList[i].orderSn,
                        orderCount: (Number(res1.result.sumPrice) + Number(res1.result.storeOneMoney) +
                          Number(res1.result.storeTwoMoney) + Number(res1.result.serviceMoney)) / 100
                      })
                      this.expressCode = this.expressCode;
                      this.expressName = this.expressName;
                    } else {
                      //console.log("5555555")
                      this.calculation.splice(1, 0, {
                        // showInsurance: true,
                        mytishi:res1.error, //重量不符合渠道时
                        mytishiqudao: res1.error, //渠道信息不存在时
                        formula: "", //计算公式
                        sumHeavy: "",
                        sumPrice: "",
                        savedMoney: "", //省运费
                        storeOneMoney: "", //一段费用--仓储费
                        storeTwoMoney: "", //二段费用--仓储费
                        serviceMoney: "",
                        packedSumHeavy: "",
                        savedHeavy: "",
                        myOrderSns: orderList[i].orderSn,
                        orderCount: 0,
                      })
                    }
                  })
                } //合包状态为13已打包时，计算费用结束
              }

              //console.log(this.calculation)
            }
            this.orderList = orderList;
            this.empty = false;
            this.zhanshi = true;
          } else {
            this.empty = true;
            this.orderList = [];
            this.zhanshi = false;
          }
        })
      },
      seeChildOrder(orderSn) {
        this.orderSn = orderSn;
        var that = this;
        var url = '/api/h5/subOrderList';
        var data = {
          pageNo: 1,
          pageSize: 100,
          orderSn: orderSn
        }
        this.$post(url, data).then(res => {
          that.isEnd = true;
          if (res.resultCode == 10000) {
            this.childOrderList = res.result.orderList;
            if (this.childOrderList.length > 0) {
              this.childOrder = true;
            }
          }
        })
      },
      closeModal() {
        if (this.modal == true) {
          this.modal = false;
          this.addressId = '';
        }
        if (this.protoalModal == true) {
          this.protoalModal = false;
        }
      },
      copyContent(content) {
        var that = this;
        handleClipboard(content, event, () => {
          that.promit = true
          setTimeout(function() {
            that.promit = false;
          }, 1500)
        })
      },
      goOrderDetail(orderSn) {
        this.$router.push({
          path: '/orderdetail/' + orderSn
        })
      }
    },
    destroyed() {
      window.removeEventListener('scroll', this.onScroll)
    }
  }
</script>

<style lang="scss" scoped>
  .beijingse-one {
    background: #ECF5FF;
    color: #409EFF
  }

  .configOrderChennal .adress .div-0[data-v-1dbace9b] {
    font-size: 2.5rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    float: right;
    width: 70% !important;
  }

  .paybtn {
    background: #ef3c4d;
    padding: 1rem 2rem;
    color: #fff;
    border-radius: 5px;
    font-size: 2.8rem;
    width: 72px;
  }

  .footPart {
    font-size: 3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0rem 3rem;
    background: #fff;
    bottom: 0;
    width: 100%;

    p:nth-child(1) {
      padding: 3rem 0;

      span:nth-child(1) {
        color: #333;
      }

      span:nth-child(2) {
        color: #ef3c4d;
      }
    }

    p:nth-child(2) {
      padding: 1rem 2rem;
      background: #ef3c4d;
      line-height: 1;
      color: #fff;
      border-radius: 10px;
    }

    .btns {
      button+button {
        margin-left: 10px;
      }

      button:not([disabled]) {
        background-color: #ef3c4d;
        color: white;
      }
    }
  }

  .configOrderChennal .adress .div-0[data-v-1dbace9b] {
    font-size: 2.5rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    float: right;
    width: 50% !important;
  }

  .protoalModal {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    top: 0;
    left: 0;

    .modalView {
      background: #fff;
      height: 80%;
      width: 90%;
      overflow: auto;
      position: absolute;
      top: 10%;
      left: 50%;
      transform: translate(-50%, 0);
      border-radius: 10px;
      text-align: left;
      padding: 3rem;
      font-size: 2rem;
    }
  }

  .waitPacked {
    text-align: left;
    width: 100%;
    min-height: 100%;
    background: #F6F6F6;

    .fuwu {
      font-size: 16px;
      height: 40px;
      padding: 0 15px;
      margin-top: 3rem;

      span {
        width: 4rem;
        height: 4rem;
        display: inline-block;
        border: 1px solid #ccc;
        border-radius: 50%;
        float: left;
        margin: 0 7px 0 0;
      }

      img {
        width: 100%;
      }

      p {
        display: inline-block;
      }
    }

    .title {
      background: #fff;
      padding: 3rem 6%;
      color: #333333;
      font-size: 3rem;
    }

    .list {
      padding: 3rem 4%;

      .item {
        background: #fff;
        border-radius: 1rem;
        margin-bottom: 1rem;

        .head {
          display: -webkit-box;
          display: -moz-box;
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          -webkit-justify-content: space-between;
          justify-content: space-between;
          -moz-box-pack: space-between;
          -webkit--moz-box-pack: space-between;
          box-pack: space-between;
          padding: 2rem 2rem;
          color: #333333;
          font-size: 2.5rem;

          p {
            display: flex;
            align-items: center;
          }

          .logisticsNumber {
            color: #999;
            font-size: 2.3rem;
          }

          .copyImg {
            width: 2.5rem;
            display: inline-block;
            margin-left: 2rem;
          }
        }

        .orderNo {
          color: #0080CB;
        }

        .line {
          width: 100%;
          height: 0;
          border-bottom: 2px solid #EEEEEE;
        }

        .content {
          padding: 3rem;
          display: -webkit-box;
          display: -moz-box;
          display: -ms-flexbox;
          display: -webkit-flex;
          display: flex;
          -webkit-justify-content: space-between;
          justify-content: space-between;
          -moz-box-pack: space-between;
          -webkit--moz-box-pack: space-between;
          box-pack: space-between;
          align-items: flex-end;
          -webkit-align-items: flex-end;
          box-align: flex-end;
          -moz-box-align: flex-end;
          -webkit-box-align: flex-end;

          .package {
            width: 20rem;
            height: 18rem;
            background: #eee;
            border-radius: 1rem;

            img {
              width: 100%;
              height: 100%;
              border-radius: 1rem;
            }
          }

          .btn {
            p:nth-child(1) {
              color: #ef3c4d;
              font-size: 3rem;
              text-align: right;
              margin-right: 2rem;

            }

            p:nth-child(2) {
              padding: 1.5rem 5rem;
              background: #ef3c4d;
              color: #fff;
              font-size: 2.4rem;
              border-radius: 1rem;
              margin-top: 2rem;
            }

          }
        }

        .time {
          font-size: 2.5rem;
          padding: 1rem 2rem;
          display: flex;
          justify-content: space-between;

          span:nth-child(2) {
            color: #ef3c4d;
          }
        }
      }
    }

    .childOrder {
      width: 100%;
      height: 100%;
      position: fixed;
      background: rgba(0, 0, 0, .8);
      top: 0;
      left: 0;

      .modalView {
        background: #fff;
        width: 80%;
        max-height: 500px;
        overflow: auto;
        border-radius: 10px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        .title {
          color: #333;
          font-size: 2.5rem;
          text-align: center;
          height: 120px;
          line-height: 120px;
          border-bottom: 1px solid #EEEEEE;
          padding: 0 3rem;
          text-align: left;
        }

        ul {
          padding: 1rem 3rem;

          li {
            padding: 1rem 0;

            p {
              width: 100%;
              overflow: hidden;
              font-size: 2.2rem;
              padding: .5rem 0;
            }

            border-bottom:1px solid #EEEEEE;
          }
        }
      }
    }
  }
</style>
