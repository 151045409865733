import Vue from 'vue';
import Router from 'vue-router';
import router from './router/router';
import '@/assets/css/base.css';
import '@/assets/css/form.css'
import App from './App.vue';
import { get, post } from './http/http';
import getDate from './utils/getDate';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import Vant from 'vant';
import 'vant/lib/index.css';
import toast from './utils/toast'
import Mint from 'mint-ui';
import 'mint-ui/lib/style.css'

Vue.use(Mint);
Vue.use(Vant);
Vue.use(ElementUI);
Vue.config.productionTip = false
Vue.use(Router);
Vue.prototype.$get = get;
Vue.prototype.$post = post;
Vue.prototype.$getDate = getDate;
Vue.prototype.$toast = toast

Date.prototype.Format = function (fmt) {
  var o = {
    "M+": this.getMonth() + 1,                 //月份
    "d+": this.getDate(),                    //日
    "H+": this.getHours(),                   //小时
    "m+": this.getMinutes(),                 //分
    "s+": this.getSeconds(),                 //秒
    "q+": Math.floor((this.getMonth() + 3) / 3), //季度
    "S": this.getMilliseconds()             //毫秒
  };
  if (/(y+)/.test(fmt))
    fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
  for (var k in o)
    if (new RegExp("(" + k + ")").test(fmt))
      fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
  return fmt;
};

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
