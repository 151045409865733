<!--
 * @Author: kangfei
 * @Date: 2021-02-05 13:43:14
 * @LastEditTime: 2021-02-05 13:44:06
 * @FilePath: \src\pages\integralDetail.vue
 * @Description:
-->
<template>
  <div class="home">
    <headerTitle title='积分明细'> </headerTitle>
    <div>
      <div class="backBox"></div>
      <div class="listBox" v-show="list.length>0">
        <div v-for="(item,index) of list" class="listItem" :key="index">
            <div>{{(new Date(item.createdTime)).Format("yyyy-MM-dd HH:mm")}}</div>
            <div> <span class="spanCol">{{item.pointsValueStr}}</span> <span>{{item.pointsTypeName}}</span> </div>
        </div>
      </div>
      <div class="empty" v-show="list.length==0">
        暂无积分
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import  headerTitle  from '../components/headerTitle'

export default {
  name: 'Home',
  components:{
    headerTitle
  },
  data(){
    return {
      list:[]
    }
  },
  mounted(){
      this.getTotalPoints()
  },
  methods:{
    getTotalPoints(){
      this.$get("/api/h5/user/points/list",{
        user_id:localStorage.getItem('userId'),
        state:0
      }).then(res=>{
        console.log(res)
        if(res.resultCode == 10000){
          this.list = res.result
        }
      })
    },
  }
}
</script>
<style scoped>
  .home{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top:0;
    background: #F1EFF2;
  }
  .backBox{
    background: url("../assets/images/integ-bg.png");
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 24rem;
  }
  .listBox{
    background: #fff;
    border-radius: 1.5rem;
    box-sizing: border-box;
    padding: 2rem 0;
    margin: 0 4rem;
    position: relative;
    top:-15rem;
  }
  .listItem{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #F0EDF1;
    box-sizing: border-box;
    padding: 2rem;
    font-size: 3rem;
  }
  .spanCol{
    color: #3389D8;
    padding-right: .5rem;
  }
  .empty{
    margin-top: 3rem;
    font-size: 3rem;
  }
</style>
