<template>
	<div class="contraband">
        <emptyInfo v-show="empty"></emptyInfo>
        <div class="mainContent" v-show="!empty">
            <p class="promit">通过欧轩物流邮寄商品必须遵守邮寄国及包裹接收国当地法律法规。在中国购买商品，必须是符合中华人民共和国法律法规。</p>
            <ul class="rule">
                <li v-for="(item,index) in list" :key="index">
                    <p @click="openChild(item)">
                        <span>{{item.forbiddenTitle}}</span>
                        <img src="@/assets/images/right_arrow_gray.png" class="rightArrow" v-show="!item.select">
                        <img src="@/assets/images/bottom_arrow_gray.png" class="bottomArrow" v-show="item.select">
                    </p>
                    <ul v-show="item.select">
                        <li v-html="item.forbiddenValue"></li>
                    </ul>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import emptyInfo from '@/components/emptyInfo.vue';
export default {
  name: 'contraband',
  data(){
    return{
        empty:false,
        isEnd:true,
        pageNo:1,
		pageSize:15,
		totalPage:0,
        list:[]
    }
  },
  components:{
	emptyInfo
  },
  created(){
    this.getContraband();
  },
  mounted(){
    window.addEventListener('scroll', this.onScroll);
  },
  methods:{
    onScroll() {
      //可滚动容器的高度
      let innerHeight = document.querySelector('#app').clientHeight;
      //屏幕尺寸高度
      let outerHeight = document.documentElement.clientHeight;
      //可滚动容器超出当前窗口显示范围的高度
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop || window.pageYOffset;
      //scrollTop在页面为滚动时为0，开始滚动后，慢慢增加，滚动到页面底部时，出现innerHeight < (outerHeight + scrollTop)的情况，严格来讲，是接近底部。
      if (innerHeight <= (outerHeight + scrollTop)) {
        //加载更多操作
        if(this.pageNo <= this.totalPage && this.isEnd == true){
          this.isEnd = false;
          this.getContraband();
        }
      }
    },
    getContraband(){
        var that = this;
		var url = '/api/h5/forbiddenList';
		var data = {
			pageNo:this.pageNo,
			pageSize:this.pageSize
		}
		this.$post(url,data).then(res=>{
            that.isEnd = true;
			if(res.resultCode == 10000){
                that.pageNo += 1;
				that.totalPage = res.result.page.pageCount;
                var list = res.result.forbiddenList;
                for(let i=0;i<list.length;i++){
                    list[i].select = false;
                    list[i].forbiddenValue = list[i].forbiddenValue.replace(/(\r\n|\n|\r)/gm, "<br />");
                }
                if(list != undefined &&　list.length > 0){
                    this.list = this.list.concat(list);
                }
                if(this.list.length == 0){
                    this.empty = true;
                }
			}else{
                this.empty = true;
            }
		})
    },
    openChild(item,index){
        if(item.select == true){
            item.select = false;
        }else{
            item.select = true;
        }
    }
  },
  destroyed(){
    window.removeEventListener('scroll', this.onScroll)
  }
}
</script>

<style lang="scss">
.contraband{
    width:100%;
	height:100%;
    background:#fff;
    overflow: scroll;
    .mainContent{
        padding:2rem;
        .promit{
            padding:3rem;
            background:#F9F9F9;
            color:#999;
            font-size:2.4rem;
            text-align: left;
            line-height: 3.5rem;
        }
        .rule{
            padding-left:3rem;
            li{
                p{
                    padding:3rem 3rem 3rem 0;
                    border-bottom:2px solid #EEEEEE;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    span{
                        font-size:3rem;
                        color:#333;
                    }
                    .rightArrow{
                        width:1.5rem;
                        height:3rem;
                    }
                    .bottomArrow{
                        width:3rem;
                        height:1.5rem;
                    }
                }
                ul{
                    padding-bottom:2rem;
                    li{
                        text-align: left;
                        padding-top:2.5rem;
                        font-size:2.4rem;
                        line-height: 5rem;
                    }
                }
            }
        }
    }
}
</style>
