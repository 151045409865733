<template>
	<div class="wrap">
		<div
			class="f3 col"
			style="padding-top: 3rem"
			@click="goPush('myIntegral')"
		>
			当前可用积分{{ pointObj.totalPoints }}
		</div>
		<div class="lotCouponBox">
			<div>
				<img src="../../assets/images/quan-more.png" alt="" />
			</div>
			<div class="couponTextBox">
				<div class="lotCoupon">领取更多优惠券</div>
				<div class="coupon">获取更多优惠权利</div>
			</div>
		</div>

	</div>
</template>

<script>
	import { Overlay } from "vant";

	export default {
		name: "index",
		data() {
			return {
				userId: localStorage.getItem("userId") || "",
				pointObj: "",
				redeemCode: "",
				show: false,
				show1: false,
			};
		},
		mounted() {
			this.getMyPoint();
		},
		methods: {

			getMyPoint() {
				this.$get("/api/h5/user/points/home", {
					uid: this.userId,
				}).then((res) => {
					console.log(res);
					this.pointObj = res.result || {};
				});
			},

			goPush(path) {
				this.$router.push({
					path: path,
				});
			},
		},
	};
</script>

<style scoped lang="scss">
	.f2 {
		font-size: 2rem;
	}
	.f3 {
		font-size: 3rem;
	}
	.col {
		color: #0b7ddf;
	}
	.lotCouponBox {
		margin: 3rem;
		background: #fff;
		box-sizing: border-box;
		border-radius: 10px;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 3rem 0;
	}
	.couponTextBox {
		margin-left: 1rem;
	}
	.lotCoupon {
		font-size: 3rem;
		font-weight: 600;
		color: #0b7ddf;
	}
	.coupon {
		font-size: 2rem;
		color: #999;
	}
	.inputBox {
		margin: 3rem;
		display: flex;
		align-items: center;
		.inputItem {
			input {
				width: 45rem;
				height: 7rem;
				font-size: 3rem;
			}
		}
		.btnBox {
			width: 15rem;
			height: 30px;
			line-height: 30px;
			color: #fff;
			font-size: 14px;
			margin-left: 3rem;
			background: #0c7edd;
			text-align: center;
			border-radius: 7px;
		}
	}

</style>
