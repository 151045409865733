/*
 * @Author: kangfei
 * @Date: 2021-02-03 17:37:15
 * @LastEditTime: 2021-02-05 16:54:27
 * @FilePath: \src\http\http.js
 * @Description:
 */
import config from './config';
import axios from 'axios';
import qs from 'qs';
// axios 配置
axios.defaults.timeout = 50000;
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8';
axios.defaults.withCredentials = true;
var authorization = getCookie('authorization');
axios.defaults.headers.common['authorization'] = authorization;
//Axios实现请求重试
axios.defaults.retry = 1; //重试次数
axios.defaults.retryDelay = 1000; //重试延时
axios.defaults.shouldRetry = (error) => true; //重试条件，默认只要是错误都需要重试
export function getCookie(c_name){
	//判断document.cookie对象里面是否存有cookie
	if (document.cookie.length>0){
	var c_start=document.cookie.indexOf(c_name + "=")
		//如果document.cookie对象里面有cookie则查找是否有指定的cookie，如果有则返回指定的cookie值，如果没有则返回空字符串
	if (c_start!=-1){
		c_start=c_start + c_name.length+1
		var c_end=document.cookie.indexOf(";",c_start)
		if (c_end==-1) c_end=document.cookie.length
		return unescape(document.cookie.substring(c_start,c_end))
		}
	}
	return ""
}
/* 封装get方法*/
export function get(url,data){
	var newUrl = config.baseURL + url;
	return new Promise((resolve,reject)=>{
		axios.get(newUrl,{params:data})
		.then(res=>{
			resolve(res.data);
		})
		.catch(err=>{
			reject(err.data);
		})
	})
}


/* 封装post方法 */
export function post(url,data){
	var newUrl = config.baseURL + url;
	return new Promise((resolve,reject)=>{
		axios.post(newUrl,data)
		.then(res=>{
			resolve(res.data);
			if(res.data.resultCode == 21005){
				// return
				window.location.href = res.data.result;
			}
		})
		.catch(err=>{
			reject(err.data);
		})
	})
}
