<template>
  <!--  <div style="margin-top: 40%;font-size: 14px">此功能仍在开发中...</div>-->
  <div class="directMail">
    <div style="padding: 10px">
      <!-- 收货地址-->
      <selectAddressShow @selectAddressId="getSelectAddressId(arguments)" :addressId="addressId"></selectAddressShow>
      <!--    填写内容-->
      <div class="dir-content">
        <div class="dir-concenter">
          <div class="my-panel no-padding field"
            style="padding: 2rem 2rem 2rem 2rem;border-radius: 5px;font-size: 2.5rem;color: #424242">
            <mt-field label="快递单号" placeholder="请输入快递单号" type="text" v-model="logisticsNumbers" class="required"
              style="border: none;"></mt-field>
            <hr color="#f0f0f0" />
            <mt-field label="联系电话" placeholder="请输入联系电话" type="number" v-model="contactPhone" class="required"
              style="border: none;"></mt-field>
            <hr color="#f0f0f0" />
            <mt-field label="货物名称" placeholder="请输入货物名称" type="text" v-model="goodsName"
              style="font-size: 14px!important;border: none;"></mt-field>
            <hr color="#f0f0f0" />
            <div style="padding: 10px;border-radius: 5px;background-color: #fff8ea;margin-top: 10px">
              <p style="color: #F59A23;font-size: 2rem"><span
                  style="font-weight: 500;font-size: 2rem;color: #ed961b">温馨提示：</span><br />需要单包直邮服务，仅在包裹入库前预报有效。包裹入库后提交预报直邮，不予处理。
              </p>
            </div>
            <p style="line-height: 50px;font-size: 2.5rem!important;padding-left: 1rem;color: #424242;font-weight: 500">
              打包要求：</p>
            <textarea placeholder="如有其他需要打包小哥哥特别注意的事项，请写下您的需求；仓库验收打包标准，默认去掉快递外包装，保留商品原包装" v-model="remark"
              class="errStyle" style="padding: 10px;margin-bottom: 10px;font-size: 2.33rem"></textarea>
          </div>
          <!--          渠道信息-->
          <div style="margin-top:1.2rem;background: #ffffff;width: 100%;border-radius: 5px">
            <div class="qudaostyle">
              <div class="qudaoinfo">
                <selectChennalShow :orderSn="orderSn" :country="country" :packageWeight="packageWeight"
                  ref='childselectChennalShow' @selectAddressId1="getSelectAddressIdQD(arguments)"></selectChennalShow>
              </div>
              <div style="line-height: 40px">
                <span style="color: #424242;font-weight: 500;font-size: 2.5rem">增值服务：</span>
                <span style="float: right;padding-right: 2rem;">
                  <el-radio-group v-model="addService" @change="changeAddService">
                    <el-radio style="color: #424242;font-size: 2.5rem" label="1">需要</el-radio>
                    <el-radio style="color: #424242;font-size: 2.5rem" label="2">不需要</el-radio>
                  </el-radio-group>
                </span>
              </div>
              <p v-if="addService == '1' ? true : false" class="myzengzhi">
                <el-checkbox-group v-model="vaService" @change="toChangeVaServiceList">
                  <el-checkbox style="white-space: normal !important;color: #424242;font-size: 2.5rem"
                    v-for="item in vaServiceList" :label="item.vaServiceId" :key="item.vaServiceId">
                    {{ item.vaServiceContent }}<span class="zyzzff"
                      style="color: #e60012!important;font-size: 2.5rem">¥{{ item.vaServicePrice / 100 }}</span>
                  </el-checkbox>
                </el-checkbox-group>
              </p>
              <div class='zengzhifuwu'>*若需要增值服务，请下单前单询专属客服</div>
            </div>
          </div>

          <!--          提示信息-->
          <div class="infoDetail" style="border-radius: 5px;padding: 15px 10px 15px 10px;">
            <textarea readonly v-model="tipsdata" class="errStyle1"
              style="font-size: 2.33rem;font-weight: 400"></textarea>
          </div>
          <!--          协议弹框，样式-->
          <div class="fuwu">
            <span @click="protoalStatus" style="position: relative">
              <img src="@/assets/images/pay-chose.png" alt="" style="position: absolute;top: 0px;left: 0px"
                v-show="iconSelect">
            </span>
            <p @click="openProtoalModal($event)" style="color: #0a7dde;font-size: 2.2rem!important;">欧轩物流集运转运契约条款及免责声明
            </p>
          </div>
          <div class="protoalModal" v-show="protoalModal" @click="closeModal">
            <div class="modalView">
              <span v-html="agreementDesc"></span><!-- 渲染协议 -->
            </div>
          </div>
          <!--          弹框提示信息-->
          <messageDialog :content='messageContent' v-show="messageDialogshow"></messageDialog>
          <!--          底部信息-->
          <div style="height: 61px"> </div>


        </div>
      </div>
    </div>
    <div class="footPart">
      <p>
        <span></span><br>
        <span></span>
      </p>
      <div class="btns">
        <mt-button size="small" style="background: #0A7DDE;border-radius: 4rem;width: 100px" @click="applayMergePack">
          提交打包</mt-button>
      </div>
    </div>
  </div>
</template>

<script>
import messageDialog from '@/components/messageDialog.vue';
import selectAddressShow from '@/pages/selectAddress.vue'
import selectChennalShow from '@/pages/selectChennal1.vue';

export default {
  name: 'directMail',
  data() {
    return {
      expressCode: '', // 渠道编码
      expressName: '', // 渠道名字
      expressList: [], // 渠道数组
      serviceIds: '', // 选中的增值服务数据---数组ids
      checkedZZ: true, // 默认选中增值服务
      messageContent: '', // 提示消息内容
      messageDialogshow: false, // 消息提示框，默认不显示
      vaServiceList: [''],//增值服务多选
      vaService: [],//增值服务
      addService: '1',
      premiumRate: '',
      agreementDesc: '', // 协议内容
      logisticsNumbers: '', //快递单号
      contactPhone: '', //电话号码
      goodsName: '', //货物名称
      remark: '', //打包要求
      orderSn: '',
      iconSelect: false, // 选中图标按钮
      protoalModal: false,
      addressId: '', //收货地址id
      country: '', // 国家
      packageWeight: '', // 重量
      tipsdata: '什么是单个包裹？\n' +
        '你只有一个国内包裹要寄送到某个收货地址；如果是多个包裹要一起合包寄送，请切换到合并转运页面提交打包。\n' +
        '\n' +
        '单个包裹出库有什么好处?\n' +
        '1、包裹入库前，就可以提交打包不用等到包裹入库了才提交打包。节省了等待国内\n' +
        '快递寄送时间。(多个包裹一起寄送是需要等包裹入库了才可以提交打包的)\n' +
        '2、入库即操作，就可当天打包完成，支付运费后立马安排出库。\n' +
        '\n' +
        '单个包裹使用流程?\n' +
        '在首页点击【单包直邮】，输入国内快递单号和收货地址，点击提交打包。等待包裹打包完成，支付运费即可。(一定要在包裹到达欧轩物流仓库前提交哦!)\n' +
        '\n' +
        '单个包裹计费规则?\n' +
        '同其他出货通道一样，根据不同的线路首续重不同。不收任何额外手续费',
    }
  },
  components: {
    selectAddressShow,
    selectChennalShow,
    messageDialog, // 提示信息弹框
  },
  created() {
    // console.log(localStorage.getItem('userId')); // 用户ID
    // console.log(localStorage.getItem('uid')); // 微信唯一标识
    this.selectSystemParams(); // 查询协议
    this.toVaServiceList(); // 查询增值服务
  },
  mounted() {

  },
  methods: {
    /* 提交打包按钮事件----判断非空验证---然后提交打包*/
    applayMergePack() {
      var that = this
      /* 收货地址为非空*/
      if (this.packageWeight == '' || this.country == '' || this.addressId == '') {
        this.messageContent = '收货地址不能为空，请选择正确的收货地址'
        this.messageDialogshow = true
        setTimeout(function () {
          that.messageDialogshow = false;
        }, 1500)
        return false
      }
      /* 判断验证必填项为非空*/
      /* 快递单号非空验证*/
      if (this.logisticsNumbers == '') {
        this.messageContent = '快递单号不能为空'
        this.messageDialogshow = true
        setTimeout(function () {
          that.messageDialogshow = false;
        }, 1500)
        return false
      }
      /* 联系电话非空验证*/
      if (this.contactPhone == '') {
        this.messageContent = '联系电话不能为空'
        this.messageDialogshow = true
        setTimeout(function () {
          that.messageDialogshow = false;
        }, 1500)
        return false
      }
      /* 服务渠道非空验证*/
      if (this.expressCode == '' || this.expressName == '') {
        this.messageContent = '服务渠道不能为空'
        this.messageDialogshow = true
        setTimeout(function () {
          that.messageDialogshow = false;
        }, 1500)
        return false
      }
      /* 判断验证选中协议*/
      if (this.iconSelect == false) {
        this.messageContent = '同意选中欧轩物流转运协议声明之后才能提交打包'
        this.messageDialogshow = true
        setTimeout(function () {
          that.messageDialogshow = false;
        }, 1500)
        return false
      }
      /* 调用提交打包接口*/
      var url = '/api/h5/directMail/directMailOrderToWms';
      var data = {
        uid: localStorage.getItem('uid'), // 微信唯一标识
        userId: localStorage.getItem('userId'), // 用户id
        logisticsNumbers: this.logisticsNumbers, // 快递单号
        contactPhone: this.contactPhone, // 联系人电话
        goodsName: this.goodsName, // 货物名称
        remark: this.remark, // 备注信息---打包要求
        country: this.country, // 国家
        addressId: this.addressId, // 收货地址id
        expressCode: this.expressCode, // 渠道编码
        expressName: this.expressName, // 渠道名称
        serviceIds: this.checkedZZ == true ? this.serviceIds : '', // 增值服务数组
      }
      this.$post(url, data).then(res => {
        // console.log(res);
        if (res.success == true) {
          this.messageContent = '打包成功'
          this.messageDialogshow = true
          setTimeout(function () {
            that.messageDialogshow = false;
            /*跳转到待打包页面*/
            that.$router.push({
              path: '/center'
            })
          }, 1500)
          return false
        } else {
          this.messageContent = '打包失败'
          this.messageDialogshow = true
          setTimeout(function () {
            that.messageDialogshow = false;
          }, 1500)
          return false
        }
      })
      // console.log('提交打包')
    },
    /* 增值服务选中切换*/
    changeAddService: function (value) {
      if (value == 2) { // 选中不需要时
        this.checkedZZ = false
        // this.serviceIds = [];//增值服务--收费服务ids
      } else {
        this.checkedZZ = true
      }
    },
    /* 选中的增值服务数据数组*/
    toChangeVaServiceList(e) {
      this.serviceIds = e.toString();
    },
    /* 收货地址-----收货地址确认按钮触发*/
    getSelectAddressId: function (param) {
      // console.log(param);
      // childValue就是子组件传过来的值
      // console.log('-----addressId-----'+param)
      this.addressId = param[0] // 地址id
      this.country = param[1] // 国家
      this.packageWeight = param[2] // 重量
      /* 切换地址时，更改渠道信息*/
      this.$refs.childselectChennalShow.expressCode = '' // 收货地址切换时，服务渠道清空
      this.expressCode = '' // 渠道代码清空
      this.expressName = '' // 渠道名称清空
    },
    /* 获取渠道方法=------点击选择渠道获取渠道*/
    getSelectAddressIdQD: function (param) { // 根据国家获取渠道列表
      console.log(param)
      /*console.log(param[0]) // 渠道code
      console.log(param[1]) // 渠道name
      console.log('获取渠道')*/
      this.expressCode = param[0] // 渠道编码赋值
      this.expressName = param[1] // 渠道名称赋值
    },
    /* 协议选中------切换按钮*/
    protoalStatus() {
      if (this.iconSelect == false) {
        this.iconSelect = true;
      } else {
        this.iconSelect = false;
      }
    },
    openProtoalModal() { // 打开免责声明按钮
      this.protoalModal = true;//点击选中免责声明时----声明弹框展示
    },
    closeModal() { // 关闭免责声明按钮
      if (this.protoalModal == true) {
        this.protoalModal = false;
      }
    },
    /*获取协议的方法-----页面初始化后调用*/
    selectSystemParams() {//查询查询参数
      var url = '/api/h5/selectSystemParams';
      var data = {}
      this.$post(url, data).then(res => {
        this.agreementDesc = res.result.systemParams.agreementDesc;
        this.premiumRate = res.result.systemParams.premiumRate;

      })
    },
    /* 页面初始化后-----查询增值服务*/
    toVaServiceList() {//查询增值服务列表
      var url = '/api/h5/vaServiceList';
      var data = {
        pageNo: 1,
        pageSize: 1000
      }
      this.$post(url, data).then(res => {
        this.vaServiceList = res.result.vaServiceList;
      })
    },
  }
}
</script>


<style lang="scss" scoped>
.directMail {
  margin-top: 0;

  .footPart {
    font-size: 2.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0rem 3rem;
    background: #fff;
    position: fixed;
    bottom: 0;
    z-index: 2;
    width: 100%;
    height: 70px;

    p:nth-child(1) {
      padding: 2.4rem 0;

      span:nth-child(1) {
        color: #333;
      }

      span:nth-child(2) {
        color: #ef3c4d;
      }
    }

    p:nth-child(2) {
      padding: 1rem 2rem;
      background: #ef3c4d;
      line-height: 1;
      color: #fff;
      border-radius: 10px;
    }

    .btns {
      margin-right: 6px;

      button+button {
        margin-left: 10px;
      }

      button:not([disabled]) {
        background-color: #ef3c4d;
        color: white;
      }
    }
  }

  .dir-content {
    border-radius: 2rem;
    /*margin: 2px;
    padding: 2px;*/
    font-size: 14px;

    .my-panel {
      margin: 10px 0 10px 0;
      padding: 1.2rem 2rem 1.2rem 2rem !important;
      font-size: 2rem;

      textarea {
        font-size: 2rem;
        width: 100%;
        padding: 3px;
      }

      .mint-cell .mint-field .required {
        .mint-cell-wrapper {
          font-size: 15px !important;
        }
      }
    }

    .dir-concenter {

      .mint-cell {
        background-color: #fff;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        color: inherit;
        min-height: 36px;
        display: block;
        overflow: hidden;
        position: relative;
        text-decoration: none;
      }

      .infoDetail {
        background: #fff;
        padding: 5px;
        margin-top: 10px;
      }

      .fuwu {
        font-size: 2.2rem;
        line-height: 40px;
        padding: 10px;
        background-color: #ffffff;
        border-radius: 5px;
        margin: 10px 0 15px 0;

        span {
          width: 3.5rem;
          height: 3.5rem;
          display: inline-block;
          border: 1px solid #ccc;
          border-radius: 50%;
          float: left;
          margin: 10px 0px 0 0;
        }

        img {
          width: 100%;
        }

        p {
          display: inline-block;
        }
      }

      .protoalModal {
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.6);
        position: fixed;
        top: 0;
        left: 0;
        z-index: 99;
        padding-top: 50px;

        .modalView {
          background: #fff;
          height: 80%;
          width: 90%;
          overflow: auto;
          position: absolute;
          top: 10%;
          left: 50%;
          transform: translate(-50%, 0);
          border-radius: 10px;
          text-align: left;
          padding: 3rem;
          font-size: 2rem;
        }
      }

      .qudaostyle {
        text-align: left;
        width: 100%;
        padding: 0 0 1rem 2rem !important;

        .qudaoinfo {
          line-height: 40px;

          .myzengzhi {
            width: 100%;
            padding: 0 0 1rem 2rem !important;

            .el-checkbox-group {
              width: 100%;

              .el-checkbox {
                margin-right: 2px !important;
                position: relative;

                .el-checkbox__label {
                  font-size: 2.5rem !important;
                  line-height: 20px !important;

                  .zyzzff {
                    position: absolute;
                    right: 20px;
                  }
                }
              }
            }

            .el-button,
            .el-checkbox {
              white-space: normal !important
            }
          }
        }

        .zengzhifuwu {
          border-radius: 5px;
          padding: 10px;
          font-weight: 500;
          font-size: 2rem;
          color: #ed961b;
          text-align: left;
          background-color: #fff8ea;
          margin: 10px 10px 10px 0;
        }
      }
    }

    .errStyle1 {
      min-height: 30rem;
      background: #ffffff;
      padding: 6px;
    }

    .errStyle {
      border: 1px solid #f0f0f0 !important;
      min-height: 14rem;
      border-radius: 1rem;
      color: #b2b2b2;
      font-size: 2rem;
    }
  }
}
</style>
<style>
body {
  background: #F2F2F2;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Helvetica Neue', Helvetica, Segoe UI, Arial, Roboto, 'PingFang SC', miui, 'Hiragino Sans GB', 'Microsoft Yahei', sans-serif;
}

.directMail .configOrder .adress[data-v-710db384] {
  padding-left: 2rem;
  background: #fff;
}

.directMail .configOrder .adress .div-0 .div-1[data-v-710db384] {
  width: 92%;
  float: left;
  margin-left: 0rem !important;
  padding-left: 2rem;
  text-align: left;
}

.directMail .dir-content .dir-concenter .myzengzhi .el-checkbox-group .el-checkbox {
  margin-right: 2px;
  position: relative;
}

.directMail .dir-content .dir-concenter .myzengzhi .el-checkbox-group .el-checkbox .el-checkbox__label .zyzzff {
  position: absolute;
  right: 10px;
}

.directMail .dir-content .dir-concenter .qudaoinfo .configOrderChennal .adress .div-0 .div-2[data-v-ccaed2b4] {
  float: right;
  padding-right: 2rem;
  width: 1.5rem;
}

.directMail .dir-content .dir-concenter .qudaoinfo .configOrderChennal .adress[data-v-ccaed2b4] {
  background: #fff;
  padding: 1rem 0 1rem 0;
}

.directMail .dir-content .dir-concenter .fuwu[data-v-64a3d2c4] {
  font-size: 15px;
}

.directMail .dir-content .dir-concenter .my-panel .mint-cell.mint-field .mint-cell-wrapper {
  width: 100%;
}

.directMail .dir-content .dir-concenter .my-panel .mint-cell.mint-field .mint-cell-wrapper .mint-cell-title {
  width: 56%;
}

.directMail .dir-content .dir-concenter .my-panel .mint-cell.mint-field .mint-cell-wrapper .mint-cell-value {
  width: 42%;
}
</style>
