<!--
 * @Author: kangfei
 * @Date: 2021-02-03 10:09:35
 * @LastEditTime: 2021-02-05 13:38:35
 * @FilePath: \src\pages\withdrawDeposit.vue
 * @Description:
-->
<template>
  <div class="wrap">
      <headerTitle title='提现'>  </headerTitle>
      <div class="backBox">
          <div class="f3">可提现金额</div>
          <div class="f4">￥{{money}}</div>
      </div>
      <div class="rel">
          <div class="inputBox">
              <div class="moneyText">提现金额</div>
              <div class="inputItem">
                  <span class="labelBox">￥</span>
                  <input v-model="amount" type="text" placeholder="输入提现金额（100的整倍）">
              </div>
              <div class="inputItem">
                  <span class="labelBox">手机号</span>
                  <input v-model="mobileNumber" type="text" placeholder="输入微信实名制认证绑定的手机号">
              </div>
              <div class="inputItem">
                  <span class="labelBox">姓名</span>
                  <input v-model="userRealName" type="text" placeholder="验证姓名,请填写真实姓名">
              </div>
          </div>
          <div class="confrimBox">
              <div @click="confrimBtn" class="confrimBtn">确认</div>
          </div>
          <div class="tipsBox">
              <div>请在微信支付管理页面</div>
              <div>[允许通过手机号码向我转账]</div>
          </div>
          <div class="recordBox">
              <div class="recordText">
                  <el-divider>提现记录</el-divider>
              </div>
          </div>
          <div class="recordListBox" v-show="historyList.length>0">
              <div @click="goWithdrawDetail(item.id)" v-for="(item,index) of historyList" :key='index' class="recordList">
                  <div>
                     {{(new Date(item.createdTime)).Format("yyyy-MM-dd")}}
                  </div>
                  <div>
                      <span>-{{item.amount}}</span>
                      <span class="spanCol">{{item.stateName}}</span>
                      <span><i class="el-icon-arrow-right"></i></span>
                  </div>
              </div>
          </div>
          <div class="empty" v-show="historyList.length==0">
              暂无记录
          </div>
      </div>

  </div>
</template>

<script>
// @ is an alias to /src
import  headerTitle  from '../components/headerTitle'

export default {
  name: 'Home',
  components:{
    headerTitle
  },
  data(){
    return {
        money:0,
        amount:'',
        mobileNumber:'',
        userRealName:'',
        userId:localStorage.getItem('userId')||'',
        historyList:[]
    }
  },
  mounted(){
      this.getMyPoint()
      this.getHistoryList()
  },
  methods:{
      confrimBtn(){
        if(this.money==0){
          alert('可提现金额为0')
          return
        }
        if((!this.amount)||(!this.mobileNumber)||(!this.userRealName)){
          alert('信息填写有误')
          return
        }
        if(this.amount===0){
          alert('提现金额不能为0')
          return
        }
          var url = '/api/h5/user/points/deposit/submit';
          var data = {
              amount:this.amount,
              mobileNumber:this.mobileNumber,
              userRealName:this.userRealName,
              userId:this.userId
          }
          this.$post(url,data).then(res=>{
              console.log(res)
              if(res.resultCode == 10000){
                  alert('提现成功')
                  this.getHistoryList()
                  this.getMyPoint()
              } else {
                  alert(res.error)
              }
          })
      },
      getHistoryList(){
          this.$get("/api/h5/user/points/deposit/history",{
              uid:this.userId
          }).then(res=>{
              console.log(res)
              if(res.resultCode == 10000) {
                this.historyList= res.result
              }
          })
      },
      goWithdrawDetail(id){
          this.$router.push({
              path:`withdrawDetail?id=${id}`
          })
      },
      getMyPoint(){
          this.$get("/api/h5/user/points/home",{
              uid:this.userId
          }).then(res=>{
              this.money = res.result.withdrawalAmount||0
          })
      },
  }
}
</script>
<style scoped>
.wrap{
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top:0;
  background: #F1EFF2;
}
.backBox{
    background: #0B7DDF;
    width: 100%;
    height: 30rem;
    border-radius: 0 0 2rem 2rem;
    box-sizing: border-box;
    padding: 2rem 3rem;
    color: #fff;
    text-align: left;
}
.rel{
    position: relative;
    top:-15rem;
}
  .inputBox{
    background: #fff;
    box-sizing: border-box;
    border-radius: 1rem;
    padding: 1rem 2rem;
      margin: 0 3rem;
  }
  .inputItem{
    border-bottom:1px solid #F0EDF1;
    display: flex;
    align-items: center;
    height: 8rem;
    box-sizing: border-box;

  }
  .labelBox{
    display: inline-block;
    width: 14rem;
    font-size: 3rem;
  }
  input{
	background:none;
	outline:none;
	border:none;
      height: 6rem;
      line-height: 6rem;
      width: 60rem;
      box-sizing: border-box;
      padding: 0 2rem;
      font-size: 3rem;
}
.confrimBox{
  margin: 3rem 5rem;
}
.confrimBtn{
  width: 100%;
  height: 6rem;
  line-height: 6rem;
  text-align: center;
  color: #fff;
  border-radius: 3rem;
  background:#ef3c4d;
    font-size: 3rem;
}
.tipsBox{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 3rem 0;
    font-size: 3rem;
}
.recordBox{
  display: flex;
  justify-content: center;
}
.recordText{
  width: 30rem;
  font-size: 3rem;
  font-weight: 800;
}
.recordListBox{
  background: #fff;
  box-sizing: border-box;
  border-radius: 1rem;
  padding: 2rem;
    margin-top: 2rem;
}
.recordList{
  box-sizing: border-box;
  padding: 1.5rem 1rem;
  border-bottom: 1px solid #F0EDF1;
  display: flex;
  justify-content: space-between;
  align-items: center;
    font-size: 3rem;
}
.spanCol{
    color: #3389D8;
    padding: 0 1rem;
  }
.moneyText{
    text-align: left;
    font-size: 3rem;
}
input::placeholder{
    font-size: 3rem;
}
.f2{
    font-size: 2rem;
}
.f3{
    font-size: 3rem;
}
.f4{
    font-size: 5rem;
}
.empty{
    margin-top: 3rem;
    font-size: 3rem;
}
/deep/ .el-divider__text{
  background: #f1eff2;
  padding: 0 1rem;
  font-size: 3rem;
  font-weight: 500;
}
</style>
