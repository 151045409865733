<template>
    <div class="configOrderChennal">
        <div class="adress">
            <span>服务渠道：</span>
            <div @click="openAdressList" class="div-0">
                <div class="div-1">
                    <span>{{expressCode == ''?'请选择出货渠道' : adressTem[0]}}</span>
                </div>
                <img src="@/assets/images/right_arrow_gray.png" class="div-2"/>

            </div>
            <div style="color: #FCA824;">
              <p>温馨提示：</p>
              <p>1.一旦提交合包，不再提供加件服务，请认真核对合包数量确保无误；</p>
              <p>2.一旦提交合包，无法修改出货渠道，请先和客服确认渠道无误再行合包。</p>
            </div>
        </div>
        <div class="modal" v-show="modal">
            <div class="modalView">
                <p class="title">
                    <span>请选择出货渠道</span>
                    <span @click="closeModal">取消</span>
                    <span @click="configSelect">确认</span>
                </p>
                <ul>
                    <li v-for="(item,index) in expressList" :key="index" :style="expressList.length - 1 == index?'border:0' : ''" @click="selectAddress(item)">
                        <div style="width:50%;float:left">{{item.expressName}}</div>
                        <div style="width:50%;float:right">时效{{ item.limitation }}</div>
                        <!-- <p>{{item.remark}}</p> -->
                        <img src="@/assets/images/pay-chose.png" class="selectImg" v-show="expressCode == item.expressCode"/>
                        <span v-show="expressCode != item.expressCode" class="startBorder"></span>

                    </li>
                </ul>
            </div>
        </div>
        <messageDialog :content='messageContent'
                       v-show="promit"></messageDialog>
    </div>
</template>

<script>
  import messageDialog from '@/components/messageDialog.vue'
    export default {
        name: "question",
        props:['orderSn','country','packageWeight'],
        data(){
            return {
                modal:false,

                addressList:[],
                expressList: [],
                expressCode:'',
                expressName:'',
                adressInfo:{
                    showAdress:[]
                },
                messageDialog:false,
                promit:false,
                messageContent: '',

                adressTem:[]
            }
        },
        components:{
          messageDialog,
        },
        created(){
        },
        mounted(){
        },
        methods:{

            openAdressList(){
                this.modal = true;
                var that = this;

                var url = '/api/platExpress/selectAllowExpressList';
                var data = {
                    country:this.country,
                    minWeight:this.packageWeight,
                    serviceType:1, // 1合包渠道
                   /* orderSn:this.orderSn */
                }

               this.$post(url,data).then(res=>{
                    if(res.resultCode == 10000){

                        if(res.result.expressList != undefined &&　res.result.expressList.length > 0){
                            this.expressList = res.result.expressList;
                        }else{
this.messageContent = '重量超过所有渠道限重';
							this.promit = true;
                        }
                    }
                })
            },

            selectAddress(item){
                this.expressCode = item.expressCode;
                this.expressName = item.expressName;
                this.adressTem[0] = item.expressName;

            },
            configSelect(){
                if(this.expressCode != ''){
                    this.modal = false;
                    this.$emit('selectAddressId', this.expressCode,this.expressName);
                }
            },

            closeModal(){
                if(this.modal == true){
                    this.modal = false;
                    this.expressCode = '';
                    this.expressName = '';
                  this.$emit('selectAddressId', this.expressCode,this.expressName);
                }

            }
        }
    }
</script>

<style lang="scss" scoped>

  .configOrderChennal .adress .div-0[data-v-1dbace9b][data-v-1dbace9b] {
      font-size: 2.5rem;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      float: right;
      width: 42% !important;
  }
  /* .configOrderChennal .adress .div-0[data-v-1dbace9b] {
      font-size: 2.5rem;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      float: right;
      width: 70%!important;
  } */
    .configOrderChennal{
        width:100%;
        min-height:100%;
        background:#F6F6F6;
        /*padding-bottom:180px;*/
        .adress{
            background:#fff;
            p{
                text-align: left;
            }
            p:nth-child(1){
                border-bottom:0px solid #eee;
                font-size:2.5rem;
            }
            p:nth-child(2){
                font-size:2.5rem;
                position:relative;
                .arrow{
                    position:absolute;
                    top:50%;
                    left:calc(100% - 4.5rem);
                    transform: translate(0,-50%);
                    width:1.5rem;
                }
            }
            .div-0{
                font-size:2.5rem;
                display: flex;
                align-items: center;
                float: right;
                width: 42%;
                .div-1{
                    width: 92%;
                    float: left;
                }
                .div-2{
                    float: right;
                    width: 1.5rem;
                }
            }
        }
        .tips{
            /*padding:2rem 4% 0 4%;*/
            text-align: left;
            color:#999;
            font-size:2.5rem;
            border-radius: 10px;
            width:100%;
            background: #F6F6F6;
            padding-left: 3rem;
            padding-top:1rem;
        }
        .orderInfo{
            background:#fff;
            margin-top:2rem;
            padding-left:3rem;
            li{
                text-align: left;
                padding:2.5rem 3rem 2.5rem 0;
                font-size:2.5rem;
                justify-content: space-between;
                border-bottom:2px solid #EEEEEE;
                color:#333;
                .noInfo{
                    color:#999;
                }
            }
        }
        .protoal{
            display: flex;
            align-items: center;
            padding:3rem;
            span{
                width:3rem;
                height:3rem;
                border:1px solid  #B5B5B5;
                border-radius: 50%;
                display: inline-block;
                margin-right:2rem;
                img{
                    width:100%;
                }
            }
            p{
                font-size:2.5rem;
                color:#057EED;
            }
        }
        .footPart{
            font-size:3rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding:1.5rem 3rem;
            background:#fff;
            position:fixed;
            bottom:0;
            width:100%;
            p:nth-child(1){
                padding:3rem 0;
                span:nth-child(1){
                    color:#333;
                }
                span:nth-child(2){
                    color:#ef3c4d;
                }
            }
            p:nth-child(2){
                padding:2.5rem 5rem;
                background:#ef3c4d;
                line-height: 1;
                color:#fff;
                border-radius: 10px;
            }
        }
        .modal{
            width:100%;
            height:100%;
            background:rgba(0,0,0,0.6);
            position:fixed;
            top:0;
            left:0;
            z-index:9999;
            .modalView{
                background:#fff;
                height:60%;
                width:100%;
                position:fixed;
                bottom:0;
                overflow: auto;
                padding-bottom:120px;
                padding-top:145px;
                z-index:9999;
                .title{
                    position:relative;
                    padding:3rem 0;
                    border-bottom:2px solid #eee;
                    position:fixed;
                    width:100%;
                    top:40%;
                    left:0;
                    background:#fff;
                    z-index: 100;
                    text-align: center;
                    span:nth-child(1){
                        color:#333;
                        font-size:3rem;
                    }
                    span:nth-child(2){
                        color:#ef3c4d;
                        font-size:3rem;
                        position:absolute;
                        top:50%;
                        left:1rem;
                        transform: translate(0,-50%);
                        padding:2rem;
                    }
                    span:nth-child(3){
                        color:#ef3c4d;
                        font-size:3rem;
                        position:absolute;
                        top:50%;
                        left:calc(100% - 10rem);
                        transform: translate(0,-50%);
                        padding:2rem;
                    }
                }
                ul{
                    padding-left:8rem;
                    li{
                        padding:2rem 0;
                        text-align: left;
                        font-size:3rem;
                        color:#333;
                        border-bottom:2px solid #eee;
                        position:relative;
                        height: 12rem;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        p{
                            height:3.5rem;
                            line-height: 3.5rem;
                        }
                        p:nth-child(2){
                            margin-top:2rem;
                        }
                        .selectImg{
                            position:absolute;
                            width:4rem;
                            height:4rem;
                            top:50%;
                            left:-6rem;
                            transform: translate(0,-50%);
                        }
                        .startBorder{
                            position:absolute;
                            width:4rem;
                            height:4rem;
                            top:50%;
                            left:-6rem;
                            transform: translate(0,-50%);
                            border:2px solid #eee;
                            border-radius: 50%;
                        }
                        .editImg{
                            position:absolute;
                            width:4rem;
                            height:4rem;
                            top:50%;
                            left:calc(100% - 8rem);
                            transform: translate(0,-50%);
                        }
                    }
                }
                .footBtn{
                    position:relative;
                    padding:2rem 0;
                    border-top:2px solid #eee;
                    color:#333;
                    font-size:3rem;
                    position:fixed;
                    bottom:0;
                    width:100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background:#fff;
                    img{
                        width:3rem;
                        height:3rem;
                        margin-right:1rem;
                    }
                }
            }
        }
    //     .protoalModal{
    //         width:100%;
    //         height:100%;
    //         background:rgba(0,0,0,0.6);
    //         position:fixed;
    //         top:0;
    //         left:0;
    //         .modalView{
    //             background:#fff;
    //             height:80%;
    //             width:90%;
    //             overflow: auto;
    //             position:absolute;
    //             top:10%;
    //             left:50%;
    //             transform: translate(-50%,0);
    //             border-radius: 10px;
    //             text-align: left;
    //             padding:3rem;
    //             font-size:2rem;
    //         }
    //     }
     }
</style>
