<template>
    <div class="evaluate">
        <div class="orderSn">
            <span>订单号:</span>
            <span>{{orderSn}}</span>
        </div>
        <textarea placeholder="请添写评价内容" v-model="content" maxlength="100"></textarea>

        <div style="text-align: left;margin-bottom: 4rem;">
            <el-upload
                accept="image/*"
                :action="uploadPath"
                list-type="picture-card"
                :before-upload="beforeUpload"
                :on-preview="handlePreview"
                :on-success="editSuccess"
                :on-remove="handleRemove"
                :on-exceed="handleOnExceed"
                :multiple = true
                :limit="5">
                <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog :visible.sync="dialogVisible">
                <img width="100%" :src="dialogImageUrl" alt="">
            </el-dialog>
        </div>

        <p @click="submitComment" class="submitButton" style="background:#0A7DDE ;border-radius: 4rem;width: 38%;float: right;margin-bottom: 2rem;">发布评价</p>
        <successInfo v-show="promit" content="评论提交成功！" @close-modal="closePromit"></successInfo>
        <messageDialog content = '请添写评价内容' v-show="messageDialog"></messageDialog>
        <messageDialog content = '最多可上传5张图片' v-show="messageDialogExceed"></messageDialog>
    </div>
</template>

<script>
import successInfo from "@/components/successInfo";
import messageDialog from '@/components/messageDialog.vue';
import config from '../http/config';
// 引入image-conversion
import imageConversion from 'image-conversion'

export default {
    name: "evaluate",
    data(){
        return {
            shu:4,
            uploadPath:config.baseURL+'/api/h5/fileupload',
            messageDialog:false,
            messageDialogExceed:false,
            orderSn:'',
            content:'',
            promit:false,
            fileList:[],
            picUrl:[],
            dialogImageUrl: '',
            dialogVisible: false,
            imgQuality: 0.3,
            imageUrl: ''
        }
    },
    components:{
        successInfo,
        messageDialog
    },
    created(){
        this.orderSn = this.$route.params.id;
    },
    methods:{
        fileDelete(fileName){
            var url = '/api/h5/fileDelete';
            var data = {fileName:fileName}
            this.$post(url,data).then(res=>{

            })
        },
        dataURItoBlob(dataURI, type) {
            var binary = atob(dataURI.split(',')[1]);
            var array = [];
            for(var i = 0; i < binary.length; i++) {
            array.push(binary.charCodeAt(i));
            }
            return new Blob([new Uint8Array(array)], {type: type});
        },
        beforeUpload (file) {
            let isLt2M = file.size / 1024 / 1024 > 1 // 判定图片大小是否小于4MB
            if (isLt2M) {
                const _this = this
                return new Promise(resolve => {
                const reader = new FileReader()
                const image = new Image()
                image.onload = (imageEvent) => {
                    const canvas = document.createElement('canvas');
                    const context = canvas.getContext('2d');
                    const width = image.width * _this.imgQuality
                    const height = image.height * _this.imgQuality
                    canvas.width = width;
                    canvas.height = height;
                    context.clearRect(0, 0, width, height);
                    context.drawImage(image, 0, 0, width, height);
                    const dataUrl = canvas.toDataURL(file.type);
                    const blobData = _this.dataURItoBlob(dataUrl, file.type);
                    resolve(blobData)
                }
                reader.onload = (e => { image.src = e.target.result; });
                reader.readAsDataURL(file);
                })
            }
        },
        handleRemove(file, fileList) {//点击删除照片钩子
            let fileName = file.name;
            let ind = this.picUrl.indexOf(fileName);
            this.picUrl.splice(ind,1);
            if(ind>-1)this.fileDelete(fileName);
            console.log(this.picUrl)
        },
        handleOnExceed(files, fileList){//超出图片数量钩子
            this.messageDialogExceed = true;
            var that = this;
            setTimeout(function(){
                that.messageDialogExceed = false;
            },1500)
        },
        handlePreview(file) {
            console.log(file);
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        },
        editSuccess(res, file, fileList){//上传成功钩子
            let fullFilePath = res.result;
            this.picUrl.push(fullFilePath);
            file.name = fullFilePath //fullFilePath.substring(fullFilePath.lastIndexOf('/')+1);
            console.log(file)
            console.log(fileList)
        },
        submitComment(){
            if(this.content == ''){
                this.messageDialog = true;
                var that = this;
                setTimeout(function(){
                    that.messageDialog = false;
                },1500)
            }
            var that1 = this;
            var url = '/api/h5/evaluate';
            var data = {
                content:this.content,
                orderSn:this.orderSn,
                picUrl:this.picUrl.join(',')
            }
            this.$post(url,data).then(res=>{
                if(res.resultCode == 10000){
                    this.promit = true;
                }
            })
        },
        closePromit(){
            this.$router.push({
                name:'waitsigned'
            })
        }
    }
}
</script>

<style lang="scss">
.el-icon-zoom-in:before{
    font-size: 40px;
}
.el-upload-list__item-delete{
    font-size: 40px  !important;
}
.el-icon-close:before{
    font-size: 50px !important;
}
.el-upload-list__item {
    width:  250px !important;
    height: 250px !important;
}
.el-upload--picture-card{
    vertical-align: bottom !important;
}
.el-dialog {
    width: 90% !important;
    margin-top: 10vh !important;
}
.el-dialog__headerbtn .el-dialog__close {
    line-height: 0.5;
}
.el-icon-close{
    font-size:1.7rem;
}
.el-upload-list__item-name{
    font-size:1.7rem;
}

.evaluate{
    width:100%;
    height:100%;
    background: #fff;
    padding:0 6%;
    .upload-demo{
        padding:2.5rem 0;
        font-size:2.5rem;
    }
    .el-upload--picture-card {
        border: 3px dashed #c0ccda;
    }
    .orderSn{
        padding:2.5rem 0;
        font-size:2.5rem;
        text-align: left;
        span:nth-child(1){
            margin-right:1rem;
        }
    }
    textarea{
        margin:0 0 4rem 0;
        width:100%;
        height:360px;
        border: 2px solid #DCDCDC;
        padding:2rem;
        color:#999999;
        font-size: 2.3rem;
        outline: none;
        border-radius: 1rem;
    }
    .submitButton{
        width:100%;
        padding:2rem 0;
        background: #ef3c4d;
        color: #fff;
        font-size:3rem ;
        border-radius: 1rem;
    }
}
</style>
