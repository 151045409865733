<template>
  <div class="center">
    <emptyInfo v-show="empty"></emptyInfo>
    <div class="mainContent">
      <div class="banner">
        <img src="@/assets/images/center_banner.png" class="bannerImg" />
        <div class="bannerContent">
          <img
            :src="userInfo.userHeadImg"
            class="userImg"
            v-show="userInfo != ''"
          />
          <img
            src="@/assets/images/logo.png"
            class="logo"
            v-show="userInfo == ''"
          />
          <span v-show="userInfo != ''">
            <div class="divUserCode">{{ userInfo.userName }}</div>
            <br />
            <div class="divUserCode">ID: {{ userInfo.userCode }}</div>
          </span>
          <span v-show="userInfo == ''">欧轩物流</span>
        </div>
      </div>
      <div class="integralBox" @click="goMyIntegral">
        <span>积分{{ totalPoints }}</span>
        <img src="../assets/images/invit-t2-4.png" alt="" />
      </div>
      <div class="order">
        <p class="title">
          <span>我的订单</span>
          <span @click="goMyOrder">查看全部订单</span>
          <img src="@/assets/images/right_arrow_gray.png" @click="goMyOrder" />
        </p>
        <ul class="serveList">
          <li
            v-for="(item, index) in serveList"
            :key="index"
            @click="routeChange(item.route)"
          >
            <div class="orderStatusDiv" v-if="item.orderStatus !='0' ">
              <div>{{ item.orderStatus }}</div>
            </div>
            <img :src="item.imgUrl" />
            <p>{{ item.title }}</p>
          </li>
        </ul>
      </div>
      <ul class="listPart">
        <li
          v-for="(item, index) in routeList"
          :key="index"
          @click="routeChange(item.route)"
        >
          <p class="listContent">
            <img :src="item.imgUrl" />
            <span>{{ item.title }}</span>
          </p>
          <img src="@/assets/images/right_arrow_gray.png" class="arrow" />
        </li>
      </ul>
    </div>
    <foot-bar :pageIndex="3"></foot-bar>
  </div>
</template>

<script>
import emptyInfo from "@/components/emptyInfo.vue";
import FootBar from "../components/footBar";
export default {
  name: "center",
  data() {
    return {
      empty: false,
      userInfo: "",
      userOrderStatus: "",
      totalPoints: 0,
      serveList: [],
      routeList: [
        {
          imgUrl: require("@/assets/images/adress_gray.png"),
          title: "收货地址",
          route: "addresslist",
        },
        {
          imgUrl: require("@/assets/images/coupon.png"),
          title: "优惠券",
          route: "couponList",
        },
        {
          imgUrl: require("@/assets/images/invite.png"),
          title: "邀请好友",
          route: "inviteFriends",
        },
        {
          imgUrl: require("@/assets/images/fingerpost_gray.png"),
          title: "下单指南",
          route: "orderguide",
        },
        {
          imgUrl: require("@/assets/images/serve_gray.png"),
          title: "客服",
          route: "customer",
        },
        {
          imgUrl: require("@/assets/images/member_gray.png"),
          title: "增值服务",
          route: "addservice",
        },
      ],
    };
  },
  components: {
    emptyInfo,
    FootBar,
  },
  created() {
    this.getUserInfo();
  },
  methods: {
    getTotalPoints(uid) {
      this.$get("/api/h5/user/total_points", {
        uid: uid,
      }).then((res) => {
        console.log(res);
        this.totalPoints = res.result.points;
      });
    },
    goMyIntegral() {
      this.$router.push({
        path: "myIntegral",
      });
    },
    getUserInfo() {
      var that = this;
      var url = "/api/h5/userInfo";
      var data = {};
      this.$post(url, data).then((res) => {
        console.log(res)
        if (res.resultCode == 10000) {
          this.userInfo = res.result.userModel;
          let userOrderStatus = res.result.userOrderStatus;
          this.getTotalPoints(this.userInfo.userId);

          this.serveList = [
            {
              orderStatus: userOrderStatus.orderStatus2,
              imgUrl: require("@/assets/images/warehouse.png"),
              title: "待入库",
              route: "storage",
            },
            {
              orderStatus: userOrderStatus.orderStatus1,
              imgUrl: require("@/assets/images/package2.png"),
              title: "异常包裹",
              route: "packagecn",
            },

            {
              orderStatus: userOrderStatus.orderStatus5 + userOrderStatus.orderStatus13,
              imgUrl: require("@/assets/images/pendPacking.png"),
              title: "待打包",
              route: "waitpacked",
            },
            {
                          orderStatus: userOrderStatus.orderStatus4,
                          imgUrl: require("@/assets/images/obligation.png"),
                          title: "待付款",
                          route: "waitpayment",
                        },
            {
              orderStatus: userOrderStatus.orderStatus11,
              imgUrl: require("@/assets/images/retrieval.png"),
              title: "待签收",
              route: "outstock",
            },
            {
              orderStatus:
                userOrderStatus.orderStatus7,
              imgUrl: require("@/assets/images/comment.png"),
              title: "待评价",
              route: "waitsigned",
            },
          ];
          localStorage.setItem("uid", res.result.userModel.uid);
          localStorage.setItem("userId", res.result.userModel.userId);
        }
      });
    },

    routeChange(route) {
      this.$router.push({
        name: route,
      });
    },
    goMyOrder() {
      this.$router.push({
        name: "myorder",
      });
    },
  },
};
</script>

<style lang="scss">
.center {
  width: 100%;
  min-height: 100%;
  background: #f6f6f6;
  .footbar {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
  }
  .mainContent {
    padding-bottom: 135px;
    .banner {
      background: #fff;
      width: 100%;
      position: relative;
      .bannerImg {
        width: 100%;
      }
      .bannerContent {
        position: absolute;
        top: 50%;
        left: 5%;
        transform: translate(0, -50%);
        display: flex;
        align-items: flex-end;
        .userImg {
          width: 12rem;
          height: 12rem;
          margin-right: 2rem;
          border-radius: 50%;
        }
        .logo {
          width: 12rem;
          height: 12rem;
          margin-right: 2rem;
        }
        span {
          color: #fff;
          font-size: 3rem;
        }
        .divUserCode {
          float: left;
          margin: 5px;
        }
      }
      .setImg {
        position: absolute;
        width: 3rem;
        height: 3.2rem;
        top: 3rem;
        left: calc(100% - 6rem);
      }
    }
    .order {
      background: #fff;
      padding-left: 4rem;
      .title {
        padding: 3.5rem 0;
        border-bottom: 2px solid #e5e5e5;
        text-align: left;
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-right: 6rem;
        span:nth-child(1) {
          font-size: 16px;
          color: #333;
        }
        span:nth-child(2) {
          font-size: 16px;
          color: #999999;
        }
        img {
          position: absolute;
          top: 52%;
          left: calc(100% - 5rem);
          width: 8px;
          -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
        }
      }
      .serveList {
        display: flex;
        justify-content: space-between;
        padding: 1rem 4rem 1rem 0rem;
        flex-wrap: wrap;
        li {
          width: 33.3%;
          padding: 2.5rem 0;
          position: relative;
          img {
            width: 6rem;
            height: 6rem;
          }
          p {
            padding-top: 1.5rem;
            color: #333;
            font-size: 14px;
          }
          .orderStatusDiv {
            div {
              position: absolute;
              top: -5px;
              right: 10px;
              // float: right;
              width: 3rem;
              height: 3rem;
              line-height: 3rem;
              background: #fe9712;
              z-index: -1;
              text-align: center;
              border-radius: 50%;
            }
            position: relative;
            width: 100px;
            float: initial;
            text-align: right;
            font-size: 2em;
            z-index: 100;
            margin-right: 150px;
            color: white;
            z-index: 100;
          }
        }

        li:nth-child(1),
        li:nth-child(2),
        li:nth-child(3) {
          border-bottom: 2px solid #eeeeee;
        }
        li:nth-child(1):after,
        li:nth-child(2):after {
          content: "";
          border-right: 2px solid #eeeeee;
          position: absolute;
          left: 100%;
          top: 0;
          height: 100%;
        }
        li:nth-child(4):after,
        li:nth-child(5):after {
          content: "";
          border-right: 2px solid #eeeeee;
          position: absolute;
          left: 100%;
          top: 0;
          height: 100%;
        }
      }
    }
    .listPart {
      margin-top: 1.5rem;
      background: #fff;
      padding-left: 4rem;
      li {
        display: flex;
        justify-content: space-between;
        padding: 3rem 4rem 3rem 0;
        border-bottom: 1px solid #eeeeee;
        align-items: center;
        .listContent {
          display: flex;
          align-items: center;
          img {
            width: 4rem;
            height: 4rem;
            margin-right: 2rem;
          }
          span {
            color: #333;
            font-size: 14px;
          }
        }
        .arrow {
          width: 1.6rem;
          height: 2.8rem;
        }
      }
    }
  }
}
.integralBox {
  display: flex;
  align-items: center;
  height: 8rem;
  width: 100%;
  background: #ef3c4d;
  font-size: 14px;
  color: #fff;
  justify-content: center;
  position: relative;
  top: -5px;
  img {
    width: 4rem;
    margin-left: 5px;
  }
}
</style>
