<template>
	<div class="wrap">
		<div>
			<img class="integBg" src="../assets/images/invit-ban.jpg" alt="" />
		</div>
		<div class="friendsListBox">
			<div class="friendsNumBox">
				<div
					class="friendItem"
					@click="switchIndex(index)"
					:class="[
						index == 1 ? 'friendItemCenter' : '',
						myIndex == index ? 'back' : '',
					]"
					v-for="(item, index) of inviteList"
					:key="index"
				>
					{{ item }}
				</div>
			</div>
			<div class="inviteMoneyBox inviteRel">
				<div class="inviteText">每邀请一位好友</div>
				<div class="flexBoxInvite">
					<div
						class="imgRel"
						v-if="
							baseData.pointsDisposeList &&
							baseData.pointsDisposeList.length > 0
						"
					>
						<img
							class="invit-y5"
							src="../assets/images/invit-y50.png"
							alt=""
						/>
						<span
							class="money"
							v-if="
								['1', '2'].includes(
									baseData.pointsDisposeList[myIndex].level
								)
							"
							>{{
								baseData.pointsDisposeList[myIndex][
									"inviteAcoupons"
								]
							}}</span
						>
						<span class="money" v-else>{{
							Math.round(
								Number(
									baseData.pointsDisposeList[myIndex][
										"inviteAcoupons"
									]
								) +
									Number(
										baseData.pointsDisposeList[myIndex][
											"inviteBcoupons"
										]
									)
							)
						}}</span>
					</div>
					<div class="imgRel">
						<img
							class="invit-y5"
							src="../assets/images/invit-y5.png"
							alt=""
						/>
						<span class="money" style="left: 13.5rem">{{
							baseData &&
							baseData.pointsDisposeList &&
							baseData.pointsDisposeList[myIndex].inviteeCoupons
						}}</span>
					</div>
					<img
						v-if="
							baseData.rewardMap &&
							baseData.rewardMap.userLevel <= myIndex
						"
						class="unLock"
						src="@/assets/images/share/invit-lock.png"
					/>
				</div>
				<div>
					<img
						class="imgT"
						@click="
							shareFrined(baseData.rewardMap.userLevel, myIndex)
						"
						src="../assets/images/invit-btn1.png"
						alt=""
					/>
				</div>
				<div class="frindImgBox">
					<img
						src="../assets/images/share/frindCopy.png"
						alt=""
						@click="goFrindList"
					/>
				</div>
			</div>
			<div class="inviteMoneyBox marT3">
				<div>
					<img
						class="invit-t2"
						src="../assets/images/invit-t2.png"
						alt=""
					/>
				</div>
				<div class="flexBox">
					<div class="inviteBox col1">
						<div class="flexImg">

							<span class="f2">已邀请</span>
						</div>
						<div class="f3">
							{{
								baseData &&
								baseData.rewardMap &&
								baseData.rewardMap.inviteAmount
							}}人
						</div>
					</div>
					<div class="inviteBox col2">
						<div class="flexImg">

							<span class="f2">已获得优惠</span>
						</div>
						<div class="f3">
							{{
								(baseData &&
									baseData.rewardMap &&
									baseData.rewardMap.coupons) ||
								0
							}}元
						</div>
					</div>
					<div class="inviteBox col3">
						<div class="flexImg">

							<span class="f2">已下单好友</span>
						</div>
						<div class="f3">
							{{
								(baseData &&
									baseData.rewardMap &&
									baseData.rewardMap.orders) ||
								0
							}}人
						</div>
					</div>
					<div class="inviteBox col4">
						<div class="flexImg">

							<span class="f2">已获得积分</span>
						</div>
						<div class="f3">
							{{
								(baseData &&
									baseData.rewardMap &&
									baseData.rewardMap.points) ||
								0
							}}
						</div>
					</div>
					<div class="invit-btn2">
						<img
							class="imgT"
							@click="goMyIntegral"
							src="../assets/images/invit-btn2.png"
							alt=""
						/>
					</div>
				</div>
			</div>

			<div class="marT3 inviteMoneyBox white">
				<div>
					<img
						class="imgT ImgB ImgB"
						src="../assets/images/invit-t3.png"
						alt=""
					/>
				</div>
				<div class="hotListBox">
					<div class="hotBox">
						<div
							:class="[
								switchFlag == 'currentFlag'
									? 'current'
									: 'last',
							]"
							@click="shwitchHot('currentFlag')"
						>
							本月榜单
						</div>
						<div
							:class="[switchFlag == 'last' ? 'current' : 'last']"
							@click="shwitchHot('last')"
						>
							上月榜单
						</div>
					</div>
				</div>
				<div class="tips">本月结束后，榜单的前三名才会获得额外奖励</div>
				<div
					class="flexBox peizeBox"
					v-if="switchFlag == 'currentFlag'"
				>
					<div class="prize two">
						<img
							class="imgT rel"
							src="../assets/images/invit-t3-2.png"
							alt=""
						/>
						<div
							class="people twoPeople"
							v-if="
								baseData.rankList &&
								baseData.rankList.length > 1
							"
						>
							<img
								:src="baseData.rankList[1].userlogourl"
								alt=""
							/>
						</div>
					</div>
					<div class="prize one">
						<img
							class="imgT rel"
							src="../assets/images/invit-t3-1.png"
							alt=""
						/>
						<div class="people onePeople">
							<img
								v-if="
									baseData.rankList &&
									baseData.rankList.length > 0
								"
								:src="baseData.rankList[0].userlogourl"
								alt=""
							/>
						</div>
					</div>
					<div class="prize three">
						<img
							class="imgT rel"
							src="../assets/images/invit-t3-3.png"
							alt=""
						/>
						<div class="people threePeople">
							<img
								v-if="
									baseData.rankList &&
									baseData.rankList.length > 2
								"
								:src="baseData.rankList[2].userlogourl"
								alt=""
							/>
						</div>
					</div>
				</div>
				<div class="flexBox peizeBox" v-else>
					<div class="prize two">
						<img
							class="imgT rel"
							src="../assets/images/invit-t3-2.png"
							alt=""
						/>
						<div class="people twoPeople">
							<img
								v-if="
									baseData.lastrankList &&
									baseData.lastrankList.length > 1
								"
								:src="baseData.lastrankList[1].userlogourl"
								alt=""
							/>
						</div>
					</div>
					<div class="prize one">
						<img
							class="imgT rel"
							src="../assets/images/invit-t3-1.png"
							alt=""
						/>
						<div class="people onePeople">
							<img
								v-if="
									baseData.lastrankList &&
									baseData.lastrankList.length > 0
								"
								:src="baseData.lastrankList[0].userlogourl"
								alt=""
							/>
						</div>
					</div>
					<div class="prize three">
						<img
							class="imgT rel"
							src="../assets/images/invit-t3-3.png"
							alt=""
						/>
						<div class="people threePeople">
							<img
								v-if="
									baseData.lastrankList &&
									baseData.lastrankList.length > 2
								"
								:src="baseData.lastrankList[2].userlogourl"
								alt=""
							/>
						</div>
					</div>
				</div>
				<div class="lookBox" @click="lookBtn">查看本期奖品>></div>
			</div>

			<div class="marT3 inviteMoneyBox white">
				<div>
					<img
						class="imgT ImgB"
						src="../assets/images/invit-t4.png"
						alt=""
					/>
				</div>
				<div class="marT3">
					<img
						class="imgT imgT50"
						src="../assets/images/invit-t4-1.png"
						alt=""
					/>
				</div>
			</div>

			<div class="contactBtn" @click="contactPush">联系客服</div>

			<div class="marT3 inviteMoneyBox white">
				<div>
					<img
						class="imgT ImgB"
						src="../assets/images/invit-t5.png"
						alt=""
					/>
				</div>
				<div class="ruleBack">
					<div class="rule">
						<div class="ruleItem">
							<div>一、邀请好友赚礼包</div>
							分享给你的好友，你和好友都可获得礼包福利。好友通过邀请注册成为欧轩物流集运会员后，该好友可获得一份专享礼包，且邀请人也可获得优惠券；邀请成功后，好友每次下单，邀请人都可获得规定比例的积分返利。
						</div>
						<div class="ruleItem">
							<div>二、如何邀请好友</div>
							打开欧轩物流集运邀请好友页面的“邀请好友赚红包”按钮，生成你的专属分享海报或链接，将该海报或链接分享到好友或朋友圈；好友通过该海报二维码或链接进入系统注册成为会员，即成为你的“已邀请好友”。
						</div>
						<div class="ruleItem">
							<div>三、邀请福利详情</div>
							1.邀请人福利
							①每1个好友通过你分享二维码或链接进入欧轩物流公众号系统并注册成为成功会员时，你即刻获得对应等级的优惠券，以此类推。邀请好友不限名额，专属链接长久有效。（若发现恶意注册，欧轩物流国际有权撤销奖励。）
							具体规则如下：
							邀请好友1-3名，每邀请一名好友，获得优惠券10元；
							邀请好友4-18名，每邀请一名好友，获得优惠券20元；
							邀请好友19名及以上，每邀请一名好友，获得优惠券50元礼包。
							②每当你邀请的好友在欧轩物流公众号系统成功下单并完成订单后，你都会获得此单金额规定比例的积分返利。（详见积分中心-积分规则手册）
							注:
							*“注册成功会员”，是指关注公众号【欧轩物流国际快递集运】并成功注册的新用户。
							*积分返佣比例，是指被邀请好友下单成功后，获得的比例积分返现。此积分实时结算，可用于支付运费时抵现，也可用于提现使用。
							2.被邀请人福利
							当好友通过你分享的专属链接进入欧轩物流集运系统并注册成为会员时，该好友即获得一份5元好友专享新人礼包。礼包在支付运费时，可抵现金使用。
							3.邀请人排行
							本活动每月会有邀请人排行榜产生，排行榜前三名将获得对应的奖品，奖品类目每月更新。
						</div>
						<div class="ruleItem">
							<div>四、积分提现</div>
							100积分可提现1元现金，最低提现金额为100元，其他提现细则参见（积分中心-积分规则手册）。
							  注:
							*使用相同手机号、电子邮箱或移动设备的用户，均视为同一用户。
							*如发现违规套取奖励行为，欧轩物流将追回其所获奖励，并依法追究其法律责任。
							*邀请记录长期保留。
							*如有其他疑问请咨询欧轩物流集运客服。
							*此活动最终解释权归浙江欧轩物流通跨境供应链管理有限公司所有。
						</div>
					</div>
				</div>
			</div>
		</div>
		<share :visibel="visibel" :qcode="shareCode"></share>
	</div>
</template>
<script>
	import share from "./activeDialog/share";
	export default {
		name: "inviteFriends",
		data() {
			return {
				userId: localStorage.getItem("userId"),
				inviteList: ["邀请1-3位好友", "邀请4-18位好友", "邀请19位以上好友"],
				myIndex: 0,
				switchFlag: "currentFlag",
				visibel: {
					show: false,
				},
				qcode: "",
				baseData: {},
				shareCode: "",
			};
		},
		components: {
			share,
		},
		mounted() {
			this.baseFun();
			// this.qCodeMsg()
			this.shareCode =
				location.origin +
				"/#/friendShare?userId=" +
				localStorage.getItem("userId");
		},
		methods: {
			integralPush() {
				this.$router.push({
					path: "/myIntegral",
				});
			},
			lookBtn() {
				this.$router.push({
					path: "/prize",
				});
			},
			contactPush() {
				this.$router.push({
					path: "/customer",
				});
			},
			goMyIntegral() {
				this.$router.push({
					path: "myIntegral",
				});
			},
			goFrindList() {
				this.$router.push({
					path: "friendList",
				});
			},
			shwitchHot(val) {
				this.switchFlag = val;
			},
			//基础信息
			baseFun() {
				this.$post("/api/h5/getAllRankInfo", { userId: this.userId }).then(
					(res) => {
						if (res.resultCode == 10000) {
							this.baseData = res.result;
						}
					}
				);
			},
			// 获取公众号二维码
			// qCodeMsg(){
			//     this.$post('/api/h5/getPersonQrcode', { userId: this.userId }).then(res=>{
			//         if(res.resultCode == 10000){
			//             console.log(res)
			//             this.qcode = res.result
			//         }
			//     })
			// },
			switchIndex(index) {
				this.myIndex = index;
			},
			// 分享好友
			shareFrined(userLevel, nowLevel) {
				if (userLevel >= nowLevel) {
					this.visibel.show = true;
				}
			},
		},
	};
</script>

<style scoped lang="scss">
	.wrap {
		background: #fe8b31;
		box-sizing: border-box;
		padding-bottom: 2rem;
	}
	.unLock {
		width: 10rem;
		height: 10rem;
		position: absolute;
		bottom: 0;
		right: 0;
	}
	.integBg {
		width: 100%;
		height: 35rem;
	}
	.friendsListBox {
		margin: 0 3rem;
	}
	.friendsNumBox {
		display: flex;
	}
	.friendItem {
		width: 33.3%;
		font-size: 12px;
		color: #fff;
		background: #ffb10b;
		border-radius: 1rem 1rem 0 0;
		height: 8rem;
		line-height: 8rem;
	}
	.friendItemCenter {
		margin: 0 0.5rem;
	}
	.inviteMoneyBox {
		box-sizing: border-box;
		padding: 0 0 3rem 0;
		background: #fffaeb;
		position: relative;
		border-radius: 6px;
	}
	.back {
		background: #fffaeb;
		color: #4c4c4c;
	}
	.inviteText {
		color: #fff;
		font-size: 3rem;
	}
	.invit-y5 {
		width: 18rem;
		margin: 3rem 4rem;
	}
	.invit-btn1 {
		width: 50%;
		margin-top: 2rem;
	}
	.marT3 {
		margin-top: 3rem;
	}
	.invit-t2 {
		width: 100%;
	}
	.flexBox {
		display: flex;
		flex-wrap: wrap;
	}
	.inviteBox {
		width: 45%;
		box-sizing: border-box;
		padding: 2rem 3rem;
		background: white;
		border-radius: 15px;
		border: 1px solid #1b8eed;
		margin-top: 2rem;
	}
	.imgT {
		width: 50%;
		margin-right: 10px;
	}
	.ImgB {
    width: 100%;
	}
	.col1 {
		color: #1b8eed;
		margin-left: 2rem;
	}
	.f2 {
		font-size: 2rem;
	}
	.f3 {
		font-size: 3rem;
	}
	.f4 {
		font-size: 4rem;
	}
	.flexImg {
		display: flex;
		align-items: center;
		justify-content: center;

		img {
			width: auto;
		}
	}
	.col2 {
		color: #2cbbd9;
		margin-left: 2rem;
		border-color: #2cbbd9;
	}
	.col3 {
		color: #67b583;
		border-color: #67b583;
		margin-left: 2rem;
	}
	.col4 {
		color: #fe991f;
		border-color: #fe991f;
		margin-left: 2rem;
	}
	.invit-btn2 {
		width: 100%;
		text-align: center;
		margin: 4rem 0 0 0;
	}
	.white {
		background: #fff;
	}
	.hotListBox {
		width: 100%;
		margin: 2rem auto;
		display: flex;
		justify-content: center;
	}
	.hotBox {
		width: 36rem;
		height: 5rem;
		background: #f7e9ce;
		display: flex;
		align-items: center;
		border-radius: 3rem;
	}
	.current {
		color: white;
		font-size: 3rem;
		width: 18rem;
		border-radius: 3rem;
		background: #fba923;
		height: 5rem;
		line-height: 5rem;
	}
	.last {
		color: #f8ca7d;
		font-size: 3rem;
		width: 18rem;
		border-radius: 3rem;
		height: 5rem;
		line-height: 5rem;
		background: #f7e9ce;
	}
	.tips {
		font-size: 2rem;
		margin-top: 3rem;
	}
	.peizeBox {
		margin-top: 3rem;
		box-sizing: border-box;
		padding: 0 2rem;
		padding-top: 8rem;
	}
	.prize {
		width: 33%;
		text-align: center;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.people {
		width: 14rem;
		height: 14rem;
		border-radius: 7rem;
		border: 0.3rem solid #999;
		position: relative;
		top: -2.5rem;
		z-index: 1;
		img {
			width: 100%;
			height: 100%;
			border-radius: 50%;
		}
	}
	.rel {
		position: relative;
		z-index: 5;
	}
	.one {
		position: relative;
		top: -8rem;
	}
	.onePeople {
		border-color: #fd9b19;
	}
	.twoPeople {
		border-color: #cacbcc;
	}
	.threePeople {
		border-color: #f88354;
	}
	.contactBtn {
		width: 100%;
		height: 8rem;
		line-height: 8rem;
		background: #fda821;
		font-size: 3rem;
		text-align: center;
		border-radius: 15px;
		margin-top: 3rem;
		color: #fff;
	}
	.ruleBack {
		margin: 3rem;
		background: #fff6ef;
		border-radius: 8px;
		border: 1.5px solid #ffe2cb;
	}
	.rule {
		box-sizing: border-box;
		padding: 3rem 2rem;
		text-align: left;
	}
	.ruleItem {
		font-size: 2rem;
		margin-bottom: 1em;
		div {
			font-weight: bold;
		}
	}
	.flexBoxInvite {
		display: flex;
		justify-content: center;
		position: relative;
		padding: 3rem;
	}
	.imgRel {
		position: relative;
	}
	.money {
		font-size: 5rem;
		color: #f9664b;
		position: absolute;
		left: 12rem;
		top: 5.5rem;
	}
	.imgT50 {
		width: 50rem;
	}
	.lookBox {
		color: #2973c9;
		font-size: 3rem;
	}
	.inviteRel {
		position: relative;
		border-radius: 0 0 6px 6px;
		.frindImgBox {
			position: absolute;
			top: 5rem;
			right: -3rem;
			img {
				width: 5rem;
				//   height: 28rem;
			}
		}
	}
</style>
