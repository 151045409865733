<!--
 * @Author: kangfei
 * @Date: 2021-02-05 13:40:28
 * @LastEditTime: 2021-02-05 13:40:58
 * @FilePath: \src\pages\withdrawDetail.vue
 * @Description:
-->
<template>
  <div class="home">
      <headerTitle title='提现详情'></headerTitle>
      <div class="flexBox">
        <div class="titleTetx">
          提现日期：
        </div>
        <div>{{(new Date(detail.createdTime)).Format("yyyy-MM-dd")}}</div>
      </div>
      <div class="flexBox">
        <div class="titleTetx">
          提现金额：
        </div>
        <div>{{detail.amount}}</div>
      </div>
      <div class="flexBox">
        <div class="titleTetx">
         状态：
        </div>
        <div>{{detail.stateName}}</div>
      </div>
      <div class="flexBox">
        <div class="titleTetx">
         备注信息：
        </div>
        <div>{{detail.remark || '无'}}</div>
      </div>
      <div class="flexBox" v-if="detail.transferVoucherImgUrl">
        <div class="titleTetx">
         提现凭证：
        </div>
        <div>
          <img class="imgBox" :src="detail.transferVoucherImgUrl" alt="">
        </div>
      </div>
  </div>
</template>

<script>
// @ is an alias to /src
import  headerTitle  from '../components/headerTitle'


export default {
  name: 'Home',
  components: {
    headerTitle
  },
  data(){
    return {
      userId:localStorage.getItem('userId')||'',
      id:this.$route.query.id,
      detail: {

      }
    }
  },
  mounted(){
    this.getDepositInfo()
  },
  methods:{
      getDepositInfo(){
          this.$get("/api/h5/user/points/deposit/info",{
              deposit_id:this.id,
              uid:this.userId
          }).then(res=>{
              console.log(res)
              if(res.resultCode == 10000) {
                this.detail= res.result
              }
          })
      },
  }
}
</script>
<style scoped>
  .flexBox{
    display: flex;
    /* flex-wrap: wrap; */
    box-sizing: border-box;
    padding:  2rem;
    border-bottom: 1px solid #F0EDF1;
      font-size: 3rem;
  }
  .titleTetx{
    width: 15rem;
    flex-shrink: 0;
  }
  .imgBox{
    width: 15rem;
    height: 15rem;
  }
</style>
