<template>
	<div class="addservice">
		<emptyInfo v-show="empty"></emptyInfo>
		<div v-show="!empty">
			<p class="title">服务介绍</p>
			<div
				class="item"
				v-for="(item, index) in vaServiceList"
				:key="index"
			>
				<p>{{ index + 1 }}.{{ item.vaServiceTitle }}</p>
				<p>服务费：{{ item.vaServicePrice / 100 }}元</p>
				<p v-html="item.vaServiceContent"></p>
			</div>
		</div>
	</div>
</template>

<script>
	import emptyInfo from "@/components/emptyInfo.vue";
	export default {
		name: "addService",
		data() {
			return {
				empty: false,
				isEnd: true,
				alert: false,
				vaServiceList: [],
				pageNo: 1,
				pageSize: 7,
				totalPage: 0,
			};
		},
		components: {
			emptyInfo,
		},
		created() {
			this.getAddressList();
		},
		mounted() {
			window.addEventListener("scroll", this.onScroll);
		},
		methods: {
			onScroll() {
				//可滚动容器的高度
				let innerHeight = document.querySelector("#app").clientHeight;
				//屏幕尺寸高度
				let outerHeight = document.documentElement.clientHeight;
				//可滚动容器超出当前窗口显示范围的高度
				let scrollTop =
					document.documentElement.scrollTop ||
					document.body.scrollTop ||
					window.pageYOffset;
				//scrollTop在页面为滚动时为0，开始滚动后，慢慢增加，滚动到页面底部时，出现innerHeight < (outerHeight + scrollTop)的情况，严格来讲，是接近底部。
				if (innerHeight <= outerHeight + scrollTop) {
					//加载更多操作
					if (this.pageNo <= this.totalPage && this.isEnd == true) {
						this.isEnd = false;
						this.getAddressList();
					}
				}
			},
			getAddressList() {
				var that = this;
				var url = "/api/h5/vaServiceList";
				var data = {
					pageNo: this.pageNo,
					pageSize: this.pageSize,
				};
				this.$post(url, data).then((res) => {
					that.isEnd = true;
					if (res.resultCode == 10000) {
						that.pageNo += 1;
						that.totalPage = res.result.page.pageCount;
						var vaServiceList = res.result.vaServiceList;
						for (let i = 0; i < vaServiceList.length; i++) {
							vaServiceList[i].vaServiceContent = vaServiceList[
								i
							].vaServiceContent.replace(/(\r\n|\n|\r)/gm, "<br />");
						}
						if (
							vaServiceList != undefined &&
							vaServiceList.length > 0
						) {
							this.vaServiceList = this.vaServiceList.concat(
								vaServiceList
							);
						}
						if (this.vaServiceList.length == 0) {
							this.empty = true;
						}
					} else {
						this.empty = true;
					}
				});
			},
		},
		destroyed() {
			window.removeEventListener("scroll", this.onScroll);
		},
	};
</script>

<style lang="scss" scoped>
	.addservice {
		background: #fff;
		height: 100%;
		width: 100%;
		text-align: left;
		padding-left: 6%;
		overflow: scroll;
		.title {
			color: #333333;
			font-size: 3rem;
			padding: 3rem 0;
			border-bottom: 1px solid #e5e5e5;
			font-weight: 500;
		}
		.item {
			padding: 2rem 6% 2rem 0;
			p {
				color: #666666;
				font-size: 16px;
				line-height: 4.5rem;
			}
			p:nth-child(3) {
				margin-top: 3rem;
			}
		}
	}
</style>