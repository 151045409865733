<template>
	<div class="emptyInfo">
        <div class="contentView">
            <img src="https://jcex-file-service.oss-cn-hangzhou.aliyuncs.com/jc-wms/nopackage.png">
            <p v-show="content == '' || content == undefined">客官，您还没有此类包裹哦。</p>
            <p v-show="content != '' && content != undefined">{{content}}</p>
        </div>
    </div>
</template>

<script>
export default {
  name: 'emptyInfo',
  data(){
    return{

    }
  },
  props:['content']
}
</script>

<style scoped lang="scss">
.emptyInfo{
    .contentView{
        position:absolute;
        left:50%;
        top:15rem;
        transform: translate(-50%,0);
        text-align: center;
        img{
            width:13rem;
            height:13rem;
        }
        p{
            font-size:2.2rem;
            padding-top:5rem;
        }
    }
}
</style>